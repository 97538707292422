import { Card, CardMedia, CardActions, Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { t } from 'i18next'
import { ProductColor, ProductImage } from '../../../models/Product'

export const ProductCardView = ({
  row,
  setMainPhoto,
  deletePhoto
}: {
  row: ProductColor
  setMainPhoto: any
  deletePhoto: any
}) => {
  
  return (
    <Grid container direction={'row'} spacing={2}>
      {row.productImages?.map((imageModel: ProductImage) => (
        <Grid key={imageModel.id} xs={3}>
          <Card>
            <CardMedia
              component='img'
              height='auto'
              image={imageModel.url}
              alt={imageModel.fileName}
            />
            <CardActions style={{ justifyContent: 'space-evenly' }}>
              {!imageModel.isMain && (
                <Button
                  size='small'
                  color='info'
                  onClick={() => setMainPhoto(imageModel.id, row.id)}
                >
                  {t('buttons.mainPhoto')}
                </Button>
              )}
              <Button size='small' color='error' onClick={() => deletePhoto(imageModel.id, row.id)}>
                {t('buttons.remove')}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
