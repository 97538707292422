import { useParams } from 'react-router-dom'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { OrderItems } from './OrderItems'
import { Loader } from '../../../../common/components/Loader'
import { useAdminOrder } from '../useAdminOrder'
import { AdminOrderDetailed } from '../../../../common/models/Order'
import { OrderHeader } from './OrderHeader'
import { OrderStatusTracker } from './OrderStatusTracker'
import { OrderDetails } from './OrderDetails'
import { OrderActions } from './OrderActions'

export const OrderContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { order, orderLoader, changeOrderStatus } = useAdminOrder(id as string)
  if (orderLoader) return <Loader type='circles' isFullscreen />
  return (
    <Grid2 container spacing={2}>
      <Grid2 container xs={12} sm={8} spacing={2} alignContent={'start'}>
        <Grid2 xs={12}>{order && <OrderHeader order={order as AdminOrderDetailed} />}</Grid2>
        <Grid2 xs={12}>{order && <OrderActions changeOrderStatus={changeOrderStatus} order={order as AdminOrderDetailed} />}</Grid2>
        <Grid2 xs={12}> {order && <OrderStatusTracker status={order.orderStatus} />}</Grid2>
        <Grid2 xs={12}> {order && <OrderItems items={order?.cart.cartItems} />}</Grid2>
      </Grid2>
      <Grid2 xs={12} sm={4}>
        {order && <OrderDetails order={order as AdminOrderDetailed} />}
      </Grid2>
    </Grid2>
  )
}
