import { Control, Controller, FieldErrors } from 'react-hook-form'
import { Product } from '../../../models/Product'
import Grid from '@mui/material/Unstable_Grid2'
import { useTranslation } from 'react-i18next'
import { Stack, Switch, TextField, Typography } from '@mui/material'
import { RichEditor } from '../../../../common/components/RichEditor'
import { MiControlledSelect } from '../../../../common/components/MiControlledSelect'

export interface IProductDetailsProps {
  product?: Product
  errors: FieldErrors<Product>
  control: Control<Product, any>
  watch: any
}

export const ProductDetails = ({ product, errors, control, watch }: IProductDetailsProps) => {
  const { t } = useTranslation()

  return (
    <Grid container direction={'row'} spacing={2}>
      <Grid xs={6}>
        <Controller
          control={control}
          defaultValue={''}
          name='titleEn'
          render={({ field: { ref, ...field } }) => (
            <TextField
              type='text'
              inputRef={ref}
              margin='none'
              {...field}
              fullWidth
              size='small'
              error={!!errors.titleEn}
              label={t('labels.productTitleEn')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={''}
          name='titleGr'
          render={({ field: { ref, ...field } }) => (
            <TextField
              type='text'
              inputRef={ref}
              margin='normal'
              {...field}
              fullWidth
              size='small'
              error={!!errors.titleEn}
              label={t('labels.productTitleGr')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={''}
          name='descriptionGr'
          render={({ field: { ref, ...field } }) => (
            <TextField
              type='text'
              inputRef={ref}
              margin='normal'
              {...field}
              fullWidth
              size='small'
              error={!!errors.descriptionGr}
              label={t('labels.descriptionGr')}
            />
          )}
        />
         <Controller
          control={control}
          defaultValue={''}
          name='descriptionEn'
          render={({ field: { ref, ...field } }) => (
            <TextField
              type='text'
              inputRef={ref}
              margin='normal'
              {...field}
              fullWidth
              size='small'
              error={!!errors.descriptionEn}
              label={t('labels.descriptionEn')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={''}
          name='sku'
          render={({ field: { ref, ...field } }) => (
            <TextField
              type='text'
              inputRef={ref}
              margin='normal'
              {...field}
              fullWidth
              size='small'
              error={!!errors.titleEn}
              label={t('labels.sku')}
            />
          )}
        />
        <MiControlledSelect
          control={control}
          path={'lookup'}
          lookupUrl='productType'
          margin='normal'
          label={t('labels.productType')}
          name='productTypeId'
          errors={errors}
        />

        <RichEditor
          control={control}
          name='outOfStockMessageEn'
          placeholder={t('labels.productOutOfStockMessageEn')}
          rows={3}
        />
        <RichEditor
          control={control}
          name='outOfStockMessageGr'
          placeholder={t('labels.productOutOfStockMessageGr')}
          rows={3}
        />
      </Grid>
      <Grid xs={6}>
        <RichEditor
          control={control}
          name='specificationsEn'
          placeholder={t('labels.productSpecificationsEn')}
          rows={10}
        />
        <RichEditor
          control={control}
          name='specificationsGr'
          placeholder={t('labels.productSpecificationsGr')}
          rows={10}
        />
        <Controller
          control={control}
          defaultValue={false}
          name='visibleToEshop'
          render={({ field: { ref, onChange, value } }) => (
            <Stack flex={1} direction='row' spacing={1} alignItems='center'>
              <Typography>{t('labels.productVisibility')}</Typography>
              <Switch
                checked={value}
                onChange={onChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </Stack>
          )}
        />
      </Grid>
    </Grid>
  )
}
