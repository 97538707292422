import { Stack } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useEffect, useState } from 'react'
import { EProductColor, EProductColorImage } from '../../eshop/models/EProduct'

interface ICarouselProps {
  colors: EProductColor[]
  selectedColor: number
}

const useStyles = makeStyles()((theme) => {
  return {
    imageContainer: {
      userSelect: 'none',
      cursor: 'pointer',
      width: '130px'
    },
    imageMobileContainer: {
      userSelect: 'none',
      cursor: 'pointer'
    },
    slideImages: {
      overflow: 'auto',
      '::-webkit-scrollbar': {
        width: '2px'
      },
      '::-webkit-scrollbar-track': {
        '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
      }
    }
  }
})

export const Carousel = ({ colors, selectedColor = 0 }: ICarouselProps) => {
  const { classes } = useStyles()
  const images: EProductColorImage[] = colors[selectedColor]?.images as any
  const [selectedImage, setImage] = useState(images.find((x) => x.isMain) as EProductColorImage)

  useEffect(() => {
    setImage(images.find((x) => x.isMain) as EProductColorImage)
  }, [images, selectedColor])

  if (images == null || images.length === 0) return null
  return (
    <Stack>
      <Stack direction={{ xs: 'column', md: 'row' }} justifyContent='right'>
        <Stack
          className={classes.slideImages}
          sx={{ display: { xs: 'none', md: 'flex' } }}
          marginRight={'20px'}
        >
          {images.map((item: EProductColorImage, i: number) => (
            <div
              key={item.url}
              className={classes.imageContainer}
              onClick={() => setImage(images[i])}
            >
              <img src={`${item.url}`} width={'130px'} alt={'noimage'} />
            </div>
          ))}
        </Stack>
        <Stack width={'100%'}>
          <img src={`${selectedImage.url}`} width={'100%'} alt={'noimage'} />
        </Stack>
        <Stack
          gap={2}
          marginTop={2}
          direction='row'
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ display: { xs: 'flex', md: 'none' } }}
        >
          {images.map((item: EProductColorImage, i: number) => (
            <div
              key={item.url}
              style={{
                borderBottom: item.url === selectedImage.url ? '1px solid #dd9d5f' : 'none', // Blue border for selected image
                boxShadow:
                  item.url === selectedImage.url ? '0px 0px 8px rgba(179, 128, 95, 0.5)' : 'none', // Soft shadow effect
                transform: item.url === selectedImage.url ? 'scale(1.05)' : 'scale(1)', // Slightly scale up the selected image
                transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out' // Smooth transition for scale and shadow
              }}
              className={classes.imageMobileContainer}
              onClick={() => setImage(images[i])}
            >
              <img src={`${item.url}`} width={'40px'} alt={'noimage'} />
            </div>
          ))}
        </Stack>
      </Stack>
    </Stack>
  )
}
