import { Link, useLocation } from 'react-router-dom'
import { Breadcrumbs, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

const breadcrumbNameMap: { [key: string]: string } = {
  '/': 'home',
  '/about': 'about',
  '/returnpolicy':'returnpolicy',
  '/shippingdelivery':'shippingdelivery',
  '/products': 'products',
  '/products/cart': 'cart',
  '/products/cart/completed': 'orderCompleted',
  '/products/cart/failed': 'orderFailed'
}

const useStyles = makeStyles()(() => {
  return {
    link: {
      textDecoration: 'none'
    },
    current: {}
  }
})

const findBreadCrumbOrDefault = (key: string, defaultValue: string, t: any) => {
  var breadcrumb = breadcrumbNameMap[key?.toLocaleLowerCase()]
  return breadcrumb ? t(`breacrumb.${breadcrumb}`) : defaultValue
}

export const EShopBreadCrumb = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter((x) => x)

  return (
    <Stack
      height={'100%'}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      style={{ padding: '1em 0' }}
    >
      <Stack>
        <Breadcrumbs aria-label='breadcrumb'>
          <Link className={classes.link} color='inherit' to='/'>
            {t('breacrumb.home')}
          </Link>
          {pathnames.map((_, index) => {
            const last = index === pathnames.length - 1
            const to = `/${pathnames.slice(0, index + 1).join('/')}`
            const breadcrumb = findBreadCrumbOrDefault(to, pathnames[pathnames.length - 1], t)
            return last ? (
              <Typography className={classes.current} color='text.primary' key={to}>
                {breadcrumb}
              </Typography>
            ) : (
              <Link className={classes.link} color='inherit' to={to} key={to}>
                {breadcrumb}
              </Link>
            )
          })}
        </Breadcrumbs>
      </Stack>
    </Stack>
  )
}
