import { FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { commonColors } from '../../../utils/useManagerTheme'
import { useLookup } from '../hooks/useLookup'
import { useTranslation } from 'react-i18next'

export interface IMiControlledSelectProps {
  errors: FieldErrors<any>
  lookupUrl: string
  control: Control<any, any>
  label: string
  path: string
  name: string
  margin?: 'none' | 'dense' | 'normal' | undefined
  flex?: string
  required?: boolean
}

export const MiControlledSelect = ({
  control,
  errors,
  path,
  name,
  margin = 'none',
  label,
  lookupUrl,
  required = true,
  flex = 'unset'
}: IMiControlledSelectProps) => {
  const { loading, lookup } = useLookup(path, lookupUrl)
  const { i18n } = useTranslation()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      rules={{ required: required }}
      render={({ field }) => (
        <FormControl size='small' style={{ flex: flex }} fullWidth margin={margin}>
          <InputLabel id={label + '_id'}>{label}</InputLabel>
          {lookup && lookup.length > 0 && (
            <Select {...field} fullWidth error={!!errors[name]} label={label} disabled={loading}>
              {lookup.map((l, index) => (
                <MenuItem key={index} value={l.id}>
                  {i18n.language === 'en' ? l.valueEn : l.valueGr}
                </MenuItem>
              ))}
            </Select>
          )}
          {loading && (
            <CircularProgress
              size={24}
              sx={{
                color: commonColors.bluesPrimaryBlue,
                position: 'absolute',
                top: '50%',
                left: '50%',
                marginTop: '-12px',
                marginLeft: '-12px'
              }}
            />
          )}
        </FormControl>
      )}
    />
  )
}
