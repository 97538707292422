import { BorderColor } from '@mui/icons-material'
import { ButtonGroup, IconButton, Paper } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { t } from 'i18next'
import { useState } from 'react'
import { Color } from '../../../common/models/Color'
import moment from 'moment'
import { useSizes } from '../../../common/hooks/useSizes'
import { SizeModal } from './SizeModal'
import { SizeTable } from './SizesTable'
import { Size } from '../../../common/models/Sizes'

export const SizeContainer = () => {
  const [selectedEntity, setSelectedEntity] = useState<Size | null>(null)
  const { sizes, sizeLoader, updateSizes } = useSizes()

  const onConfirm = (result: Color) => {
    setSelectedEntity(null)
    updateSizes(result)
  }

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.lastUpdate') as string,
      field: 'lastUpdated',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.lastUpdated).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.sizeType') as string,
      field: 'sizeType',
      flex: 1
    },
    {
      headerName: t('labels.sizeValue') as string,
      field: 'sizeValue',
      flex: 1
    },
    {
      headerName: t('labels.unitSystem') as string,
      field: 'unitSystem',
      flex: 1
    },
    {
      headerName: t('labels.unit') as string,
      field: 'unit',
      flex: 1
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => setSelectedEntity(params.row)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]
  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      {selectedEntity && (
        <SizeModal
          entity={selectedEntity as Size}
          onConfirm={onConfirm}
          onCancel={() => setSelectedEntity(null)}
        />
      )}
      <SizeTable
        loader={sizeLoader}
        columns={columns}
        data={sizes}
        setSelectedEntity={setSelectedEntity}
      />
    </Paper>
  )
}
