import { IProductFilterCriteriaDto } from '../useEProducts'
import { useTranslation } from 'react-i18next'
import { EColorsPickerFilter } from './EColorsPickerFilter'
import { useState } from 'react'
import { Color } from '../../../../common/models/Color'
import { ESizesPickerFilter } from './ESizesPickerFilter'
import { Size } from '../../../../common/models/Sizes'
import {
  Button,
  Divider,
  Drawer,
  IconButton,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { ECategoryPickerFilter } from './ECategoryPickerFilter'
import { ISimpleLookup } from '../../../../common/models/SimpleLookup'
import { EPricePickerFilter } from './EPricePickerFilter'
import CloseIcon from '@mui/icons-material/Close'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import { useSearchParams } from 'react-router-dom'
interface IEProductsFiltersProps {
  onChangeFilters: any
  filters: IProductFilterCriteriaDto
  colors: Color[]
  sizes: Size[]
  categories: ISimpleLookup[]
}

export const EProductsFilters = ({
  onChangeFilters,
  sizes,
  colors,
  filters,
  categories
}: IEProductsFiltersProps) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const { t } = useTranslation()
  const [selectedColorId, setSelectedColorId] = useState<string | undefined>(filters?.colorIds[0])
  const [selectedSizeId, setSelectedSizeId] = useState<string | undefined>(filters.sizeId)
  const [mobileOpen, setMobileOpen] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [selectedCategoryId, setSelectedCategoryId] = useState<string | undefined>(
    filters.categoryId
  )
  const clearFilters = () => {
    removeQueryParams()
    onChangeFilters(null)
  }

  const removeQueryParams = () => {
    const param = searchParams.get('category')

    if (param) {
      searchParams.delete('category')
      setSearchParams(searchParams)
    }
  }

  const onColorChange = (id: string) => {
    setSelectedColorId(id)
    onChangeFilters({ ...filters, colorIds: [id] })
  }

  const onSizeChange = (id: string) => {
    setSelectedSizeId(id)
    onChangeFilters({ ...filters, sizeId: id })
  }

  const onCategoryChange = (id: string) => {
    setSelectedCategoryId(id)
    onChangeFilters({ ...filters, categoryId: id })
  }

  const filterOptions = (
    <Paper elevation={0} style={{ background: 'none' }}>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'start'}>
        <Typography marginBottom={2} fontWeight={600} variant='h5'>
          {t('labels.filters')}
          {(selectedColorId ||
            selectedSizeId ||
            selectedCategoryId ||
            filters.minPrice !== 0 ||
            filters.maxPrice !== 300) && (
            <Button
              size='small'
              variant='text'
              style={{ fontWeight: '600', marginLeft: '10px' }}
              color='info'
              onClick={clearFilters}
            >
              {t('buttons.clear')}
            </Button>
          )}
        </Typography>
        {isMobile && (
          <IconButton onClick={() => setMobileOpen(false)}>
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
      <Typography marginBottom={2} variant='h6'>
        {t('labels.categories')}
      </Typography>
      <ECategoryPickerFilter
        categories={categories}
        selectedCategoryId={selectedCategoryId}
        setSelectedCategoryId={onCategoryChange}
      />
      <Divider style={{ marginBottom: '1em' }} />
      <Typography marginBottom={2} variant='h6'>
        {t('labels.priceRange')}
      </Typography>
      <EPricePickerFilter filters={filters} onChangeFilters={onChangeFilters} />
      <Divider style={{ marginBottom: '1em' }} />
      <Typography marginBottom={2} variant='h6'>
        {t('labels.sizes')}
      </Typography>
      <ESizesPickerFilter
        selectedSizeId={selectedSizeId}
        sizes={sizes}
        setSelectedSizeId={onSizeChange}
      />
      <Divider style={{ marginBottom: '1em' }} />
      <Typography marginBottom={2} variant='h6'>
        {t('labels.colors')}
      </Typography>
      <EColorsPickerFilter
        colors={colors}
        selectedColorΙd={selectedColorId}
        setSelectedColorId={onColorChange}
      />
    </Paper>
  )
  return !isMobile ? (
    filterOptions
  ) : (
    <>
      <IconButton onClick={() => setMobileOpen(true)}>
        <FilterAltIcon />
      </IconButton>
      <Drawer
        variant='temporary'
        open={mobileOpen}
        onClose={() => setMobileOpen(!mobileOpen)}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '80%' }
        }}
      >
        <div style={{ padding: '10px' }}>{filterOptions}</div>
      </Drawer>
    </>
  )
}
