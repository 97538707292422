import axios from 'axios'
axios.interceptors.response.use(
    response => {
        try {
            if (!!response.data.data) {
               // console.debug('response', JSON.parse(response.data.data))
                response.data.data = JSON.parse(response.data.data)
            }
        } catch (e) { console.debug(e) }
        return response
    },
    failedResponse => {
        if (failedResponse.response.status === 401 || failedResponse.response.status === 400) {
            //router.push('/login')
            return Promise.resolve(failedResponse?.response)
        }
        return Promise.resolve(failedResponse)
    }

)