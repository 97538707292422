import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { CartStepper } from './CartStepper'
import { useEffect, useState } from 'react'
import { CartCompleted } from './CartCompleted'
import { OrderStorageName } from '../../context/CartProvider'
import { useNavigate } from 'react-router-dom'

export const CardCompletedContainer = () => {
  const navigate = useNavigate()
  const [order, setOrder] = useState<string | undefined>(undefined)

  useEffect(() => {
    const orderNo = localStorage.getItem(OrderStorageName)
    if (orderNo) {
      setOrder(orderNo)
      localStorage.removeItem(OrderStorageName)
    } else navigate('/products/cart')
  }, [navigate])

  if (!order) return null
  return (
    <Grid2 container columnSpacing={10}>
      <Grid2 xs={12} sm={12}>
        <CartStepper step={3} />
      </Grid2>
      <Grid2 xs={12} sm={8}>
        <CartCompleted orderCode={order as string} />
      </Grid2>
    </Grid2>
  )
}
