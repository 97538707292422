import { useEffect, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { User } from '../../../common/models/User'

export interface IUseUsersProps {
  loading: boolean
  users: User[]
}

export function useUsers(): IUseUsersProps {
  const [loading, setLoading] = useState(false)
  const [users, setUsers] = useState<User[]>([])
  const service = useBaseService('adminuser')

  useEffect(() => {
    async function getUsers() {
      setLoading(true)
      const response = await service.getAll()
      if (response) setUsers(response)
      setLoading(false)
    }
    getUsers()
  }, [service])

  return {
    loading,
    users
  }
}
