import { useTranslation } from 'react-i18next'
import { OrderStatus } from '../models/Order'
import { Chip } from '@mui/material'
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

export interface IOrderStatusChipProps {
  status: OrderStatus
}

export const OrderStatusChip = ({ status }: IOrderStatusChipProps) => {
  const { i18n } = useTranslation()
  let message = {
    0: i18n.language === 'en' ? 'Cancelled' : 'Ακυρώθηκε',
    1: i18n.language === 'en' ? 'Pending' : 'Σε εξέλιξη',
    2: i18n.language === 'en' ? 'On the way' : 'Σε Αποστολή',
    3: i18n.language === 'en' ? 'Delivered' : 'Παραδόθηκε'
  }

  let chip = (
    <Chip icon={<AccessTimeIcon />} size='small' label={`${message[status]}`} color='warning' />
  )

  switch (status) {
    case OrderStatus.CANCELED: {
      chip = (
        <Chip icon={<HighlightOffIcon />} size='small' label={`${message[status]}`} color='error' />
      )
      break
    }
    case OrderStatus.COMPLETED: {
      chip = (
        <Chip
          icon={<CheckCircleOutlineIcon />}
          size='small'
          label={`${message[status]}`}
          color='success'
        />
      )
      break
    }
    case OrderStatus.SEND: {
      chip = (
        <Chip
          icon={<DeliveryDiningIcon />}
          size='small'
          label={`${message[status]}`}
          color='primary'
        />
      )
      break
    }
    default: {
      break
    }
  }
  return chip
}
