import { Avatar, Button, ButtonGroup, Chip, IconButton } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { BorderColor, DeleteOutline } from '@mui/icons-material'
import { ProductColorModal } from './ProductColorModal'
import { useState } from 'react'
import { Product, ProductColor } from '../../../models/Product'
import { Control, useFieldArray } from 'react-hook-form'
import { useColors } from '../../../../common/hooks/useColors'
import { useSizes } from '../../../../common/hooks/useSizes'
import { Color } from '../../../../common/models/Color'
import { ProductSize } from '../../../../common/models/Sizes'
import { ProductImageUpload } from './ProductImageUpload'
import { IColumnProps, MiTable } from '../../../../common/components/MiTable'
import { ProductCardView } from './ProductCardView'

export interface IProductColorListProps {
  control: Control<Product, any>
  productId: string | undefined
  setMainPhoto: (imageId: string, colorId: string) => void
  deletePhoto: (imageId: string, colorId: string) => void
}

export const ProductColorList = ({
  control,
  productId,
  setMainPhoto,
  deletePhoto
}: IProductColorListProps) => {
  const { t } = useTranslation()
  const [productColor, setProductColor] = useState<ProductColor | null>(null)
  const { colors } = useColors()
  const { sizes } = useSizes()

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: 'colors',
    keyName: 'fieldId'
  })
  const addOrUpdateEntity = (entity: ProductColor) => {
    setProductColor(null)
    const _color = colors.find((x) => x.id === entity.colorId) as Color
    entity.color = _color
    entity.price = +entity.price
    entity.discount = +entity.discount
    if (!!entity.id) {
      const i = fields.findIndex((f) => f.id === entity.id)
      update(i, entity)
    } else {
      append(entity)
    }
  }

  const removeEntity = (id: string) => {
    const i = fields.findIndex((f) => f.id === id)
    remove(i)
  }

  const tableColumns: IColumnProps[] = [
    {
      headerName: t('labels.name') as string,
      field: 'colorId',
      renderCell: (row: ProductColor, i: number) => row.color.colorNameGr
    },
    {
      headerName: t('labels.color') as string,
      field: 'colorValue',
      renderCell: (row: ProductColor, i: number) => (
        <Chip
          style={{ background: row.color.colorValue, width: '60px', borderRadius: '2px' }}
          variant='outlined'
        />
      )
    },
    {
      headerName: t('labels.price') as string,
      field: 'price',
      renderCell: (row: ProductColor, i: number) => <div>{row.price} &euro;</div>
    },
    {
      headerName: t('labels.sizes') as string,
      field: 'sizes',
      flex: 1,
      renderCell: (row: ProductColor, i: number) => (
        <div>
          {row.sizes?.map((s: ProductSize) => (
            <Chip
              component={'span'}
              key={s.id}
              style={{ marginRight: '8px', borderRadius: '2px' }}
              variant='outlined'
              label={s.sizeValue}
              avatar={<Avatar component={'span'}>{s.stock}</Avatar>}
            />
          ))}
        </div>
      )
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      width: 160,
      renderCell: (row: ProductColor) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => setProductColor(row)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
          <IconButton color='error' onClick={() => removeEntity(row.id)}>
            <DeleteOutline fontSize={'small'} />
          </IconButton>
          <ProductImageUpload productId={productId} productColorId={row.id} />
        </ButtonGroup>
      )
    }
  ]
  const options = {
    expandableRow: true,
    renderExpandableRow: (row: ProductColor) => (
      <ProductCardView row={row} setMainPhoto={setMainPhoto} deletePhoto={deletePhoto} />
    )
  }

  return (
    <>
      {productColor && (
        <ProductColorModal
          onCancel={() => setProductColor(null)}
          onConfirm={addOrUpdateEntity}
          entity={productColor}
          colors={colors}
          sizes={sizes.map((x) => new ProductSize(x))}
        />
      )}

      <MiTable
        actions={
          <Button
            color={'primary'}
            size='small'
            variant='outlined'
            onClick={() => setProductColor(new ProductColor())}
          >
            {t('buttons.add')}
          </Button>
        }
        title={t('labels.productColorAvailability')}
        columns={tableColumns}
        data={fields}
        options={options}
      />
    </>
  )
}
