import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Color } from '../../../common/models/Color'
import { Loader } from '../../../common/components/Loader'
import { fieldProps } from '../../../../utils/FormExtensions'
import { useColor } from './useColor'
import { ColorPicker, useColor as useColorState } from 'react-color-palette'
import 'react-color-palette/css'

export interface IColorModalProps {
  entity: Color
  onConfirm: any
  onCancel: any
}
export const ColorModal = ({ onCancel, onConfirm, entity }: IColorModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'editRecord' : 'newRecord'
  const [color, setColor] = useColorState(entity.colorValue)
  const { create, loader, update } = useColor() as any
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Color>({
    defaultValues: entity
  })

  const sumbitForm = async (data: Color) => {
    let result = null
    data.colorValue = color.hex
    if (entity.id) {
      result = await update(data)
    } else {
      result = await create(data)
    }
    onConfirm(result)
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.' + title)}</DialogTitle>
      <DialogContent>
        {loader && <Loader type='circles' />}
        {!loader && (
          <>
            <Controller
              control={control}
              defaultValue=''
              name='colorNameEn'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.productColorNameEn'), ref, field)}
                  error={!!errors.colorNameEn}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=''
              name='colorNameGr'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.productColorNameGr'), ref, field)}
                  error={!!errors.colorNameGr}
                />
              )}
            />
            <div
              style={{ background: color.hex, height: '40px', borderRadius: 5, marginBottom: 10 }}
            ></div>
            <ColorPicker color={color} onChange={setColor} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        {!loader && (
          <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
            {t('buttons.insert')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
