import { BorderColor } from '@mui/icons-material'
import { ButtonGroup, Chip, IconButton, Paper } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { useState } from 'react'
import moment from 'moment'
import { CouponModal } from './CouponModal'
import { CouponTable } from './CouponsTable'
import { DiscountCouponDto } from '../../../common/models/DiscountCoupon'
import { useCoupons } from './useCoupons'
import { useTranslation } from 'react-i18next'

export const CouponContainer = () => {
  const [selectedEntity, setSelectedEntity] = useState<DiscountCouponDto | null>(null)
  const { couponLoader, coupons, updateCoupons } = useCoupons()
  const { t } = useTranslation()

  const onConfirm = (result: DiscountCouponDto) => {
    setSelectedEntity(null)
    if (!result) return
    updateCoupons(result)
  }

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.lastUpdate') as string,
      field: 'lastUpdated',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.lastUpdated).format('DD/MM/YYYY')
    },
    {
      headerName: t('labels.couponCode') as string,
      field: 'code',
      flex: 1
    },
    {
      headerName: t('labels.couponDiscountPercentage') as string,
      field: 'discountPercentage',
      flex: 1
    },
    {
      headerName: t('labels.timesUsed') as string,
      field: 'timesUsed',
      flex: 1
    },
    {
      headerName: t('labels.useLimit') as string,
      field: 'useLimit',
      flex: 1
    },
    {
      headerName: t('labels.expiryDate') as string,
      field: 'expiryDate',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.expiryDate).format('DD/MM/YYYY')
    },
    {
      headerName: t('labels.status') as string,
      field: 'isEnabled',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.isEnabled ? (
          <Chip label={`${t('labels.active')}`} color='success' />
        ) : (
          <Chip label={`${t('labels.disable')}`} color='error' />
        )
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => setSelectedEntity(params.row)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]
  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      {selectedEntity && (
        <CouponModal
          entity={selectedEntity as DiscountCouponDto}
          onConfirm={onConfirm}
          onCancel={() => setSelectedEntity(null)}
        />
      )}
      <CouponTable
        loader={couponLoader}
        columns={columns}
        data={coupons}
        setSelectedEntity={setSelectedEntity}
      />
    </Paper>
  )
}
