import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../../common/components/Loader'
import { fieldProps } from '../../../../utils/FormExtensions'
import { useSize } from './useSize'
import { Size } from '../../../common/models/Sizes'

export interface ISizeModalProps {
  entity: Size
  onConfirm: any
  onCancel: any
}
export const SizeModal = ({
  onCancel,
  onConfirm,
  entity
}: ISizeModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'editRecord' : 'newRecord'

  const { create, loader, update } = useSize() as any
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<Size>({
    defaultValues: entity
  })

  const sumbitForm = async (data: Size) => {
    let result = null
    if (entity.id) {
      result = await update(data)
    } else {
      result = await create(data)
    }
    onConfirm(result)
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle >{t('labels.' + title)}</DialogTitle>
      <DialogContent>
        {loader && <Loader type='circles' />}
        {!loader && (
          <>
            <Controller
              control={control}
              defaultValue=''
              name='sizeType'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.sizeType'), ref, field)}
                  error={!!errors.sizeType}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=''
              name='sizeValue'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.sizeValue'), ref, field)}
                  error={!!errors.sizeValue}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=''
              name='unitSystem'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.unitSystem'), ref, field)}
                  error={!!errors.sizeValue}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=''
              name='unit'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField {...fieldProps(t('labels.unit'), ref, field)} error={!!errors.unit} />
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        {!loader && (
          <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
            {t('buttons.insert')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
