import { Card, CardContent, Stack, Typography } from '@mui/material'
import { AdminOrderDetailed } from '../../../../common/models/Order'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

export interface IOrderHeaderProps {
  order: AdminOrderDetailed
}
export const OrderHeader = ({ order }: IOrderHeaderProps) => {
  const { t } = useTranslation()
  return (
    <Card>
      <CardContent>
        <Stack direction={'row'} flex={1} spacing={1} justifyContent={'space-between'}>
          <Typography color={(theme) => theme.palette.primary.light} variant={'h5'}>
            {`${t('labels.order')} #${order.orderCode}`}
          </Typography>
          <Stack>
            <Typography variant={'subtitle1'} color={(theme) => theme.palette.primary.dark}>
              {moment(order.created).format('DD/MM/YYYY , HH:mm A')}
            </Typography>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
