import { Card, CardContent, Typography, CardMedia, Stack, Chip, Button } from '@mui/material'
import { AddRemoveButton } from '../../../common/components/AddRemoveButton'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EPriceView } from '../products/EPriceView'
import { ICartItemDto } from '../../../common/models/Order'

export const CartItemDesktop = ({
  cartItem,
  updateQuantity,
  removeFromCart
}: {
  cartItem: ICartItemDto
  updateQuantity: any
  removeFromCart: any
}) => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const image = cartItem.color.images.find((x) => x.isMain)

  return (
    <Card sx={{ display: 'flex' }} elevation={0}>
      <CardMedia
        onClick={() => navigate(`/products/${cartItem.productId}`)}
        component='img'
        sx={{ height: 150, width: 80, objectFit: 'cover' }}
        image={image?.url}
        alt='Sorry no image available'
      />
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Stack
          direction='row'
          sx={{ display: { xs: 'block', sm: 'flex' } }}
          justifyContent='space-between'
          alignItems='stretch'
          height={'100%'}
        >
          <Stack flex={1} justifyContent={'space-between'}>
            <div>
              <Typography
                component='div'
                style={{ cursor: 'pointer' }}
                variant='h5'
                onClick={() => navigate(`/products/${cartItem.productId}`)}
              >
                {i18n.language === 'en' ? cartItem.product.titleEn : cartItem.product.titleGr}
              </Typography>
              <Chip
                label={
                  i18n.language === 'en' ? cartItem.color.colorNameEn : cartItem.color.colorNameGr
                }
                variant='outlined'
                style={{ marginRight: 5 }}
              />
              <Chip label={cartItem.size.sizeValue} variant='outlined' />
            </div>
          </Stack>
          <Stack
            textAlign={'right'}
            justifyContent={'space-between'}
            alignItems={'end'}
            alignSelf={'stretch'}
          >
            <EPriceView
              price={cartItem.price * cartItem.quantity}
              discount={cartItem.discount * cartItem.quantity}
            />
            <Stack direction={'row'} alignSelf={'flex-end'}>
              <Button
                color='error'
                style={{ marginRight: 20 }}
                onClick={() => removeFromCart(cartItem.sizeId)}
              >
                Remove
              </Button>
              <AddRemoveButton
                quantity={cartItem.quantity}
                maxQuantity={cartItem.size.stock}
                setNewQuantity={(quantity) => updateQuantity(cartItem.sizeId, quantity)}
              />
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
