import { makeStyles } from 'tss-react/mui'
import { Color } from '../../../../common/models/Color'

const useStyles = makeStyles()((theme) => {
  return {
    dot: {
      height: '25px',
      width: '25px',
      borderRadius: '2px',
      marginRight: '15px',
      cursor: 'pointer',
      display: 'inline-block',
      marginBottom: '15px',
      border: '1px solid #00000047'
    }
  }
})
interface IEColorsPickerFilterProps {
  selectedColorΙd: string | undefined
  setSelectedColorId: any
  colors: Color[]
}

export const EColorsPickerFilter = ({
  selectedColorΙd,
  setSelectedColorId,
  colors
}: IEColorsPickerFilterProps) => {
  const { classes } = useStyles()
  return (
    <div>
      {colors?.map((c: Color, index: number) => (
        <div
          key={index}
          className={classes.dot}
          onClick={() => setSelectedColorId(c.id)}
          style={{
            backgroundColor: c.colorValue,
            boxShadow: selectedColorΙd === c.id ? '0px 0px 6px 1px #474242' : ''
          }}
        />
      ))}
    </div>
  )
}
