import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

export interface IHomeCardProps {
  image: string
  alt: string
  action?: any
  title: string
}
const useStyles = makeStyles()(() => {
  return {
    overlay: {
      position: 'absolute',
      background: '#00000026',
      top: 0,
      left: 0,
      width: '100%',
      height: 'calc(100% - 5px)'
    },
    text: {
      position: 'absolute',
      color: 'white',
      fontSize: '36px',
      width: '100%',
      textAlign: 'center',
      bottom: '20px'
    },
    root: {
      position: 'relative',
      cursor: 'pointer'
    },
    image: {
      width: '100%',
      aspectRatio: '3/4'
    }
  }
})

export const HomeCard = ({ image, alt, action = null, title }: IHomeCardProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  return (
    <div className={classes.root} onClick={action}>
      <img className={classes.image} src={image} alt={alt} />
      <div className={classes.overlay}></div>
      <div className={classes.text}>{t(title)}</div>
    </div>
  )
}
