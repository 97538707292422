import { BaseEntity } from '../../manager/models/BaseEntity'

export class User extends BaseEntity {
  firstName: string
  lastName: string
  email: string
  address: string
  mobile: string
  postalCode: string
  city: string
  area: string
  isAdmin: boolean
}
