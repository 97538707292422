import { Typography } from '@mui/material'
import { calculateDiscountPercentage } from '../../../../../utils/calculations'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

const useStyles = (props: any) =>
  makeStyles()((theme) => {
    return {
      root: {
        height: props.size + 'px',
        width: props.size + 'px',
        zIndex: '100',
        borderRadius: '999px',
        backgroundColor: '#a52847',
        color: 'white'
      },
      position: {
        position: 'absolute',
        right: '0px',
        top: '-40px'
      },
      text: {
        whiteSpace: 'nowrap',
        lineHeight: props.size + 2 + 'px',
        textAlign: 'center',
        fontSize: props.fontSize
      }
    }
  })

interface IEPriceDiscountChipProps {
  price: number
  discount: number
  defaultPosition?: boolean
  margin?: string
  size?: number
  fontSize?: string
}
export const EPriceDiscountChip = ({
  price,
  discount,
  margin,
  defaultPosition,
  size = 42,
  fontSize = '14px'
}: IEPriceDiscountChipProps) => {
  const discountPercentage = calculateDiscountPercentage(price, discount)
  const { classes } = useStyles({ size, fontSize })()
  if (!discountPercentage) return null
  const combineRoot = defaultPosition ? classes.root : clsx(classes.root, classes.position)
  return (
    <div className={combineRoot} style={{ margin: margin }}>
      <Typography className={classes.text} variant='subtitle2'>
        -{discountPercentage} %
      </Typography>
    </div>
  )
}
