import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import notfound from '../../../../../images/emptySearch.png'
import { useTranslation } from 'react-i18next'

export const ETableEmptyState = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Stack direction={'column'} alignItems={'center'} textAlign={'center'}>
      <img width={isMobile ? '70%' : '40%'} src={notfound} alt='noimage' />
      <Typography variant='subtitle2'>{t('messages.notFoundProduct')}</Typography>
    </Stack>
  )
}
