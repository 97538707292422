import { useEffect, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { EProductTable } from '../../models/EProduct'

export interface IUseEProductProps {
  productLoader: boolean
  product: EProductTable | undefined
}

export function useEProduct(id: string): IUseEProductProps {
  const [productLoader, setLoading] = useState(false)
  const [product, setProduct] = useState<EProductTable>()
  const service = useBaseService('product')

  useEffect(() => {
    if (!id || id === 'new') {
      setProduct(new EProductTable())
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<EProductTable>(id)
      if (response) setProduct(response)
      setLoading(false)
    }
    getById(id)
  }, [id, service, setLoading])

  return {
    productLoader,
    product
  }
}
