import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { useNavigate } from 'react-router-dom'
import { useValidator } from '../../../../utils/useValidator'
import { Product } from '../../models/Product'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export interface IUseProductProps {
  productLoader: boolean
  product?: Product
  createProduct: any
  updateProduct: any
  setMainPhoto: (imageId: string, colorId: string, productId: string) => Promise<void>
  deletePhoto: (imageId: string, colorId: string, productId: string) => Promise<void>
  deleteProduct: (id: string) => Promise<boolean>
}

export function useProduct(id?: string): IUseProductProps {
  const { t } = useTranslation()
  const [productLoader, setLoading] = useState(false)
  const [product, setProduct] = useState<Product>()
  const service = useBaseService('adminproductmanagement')
  const { isGuid } = useValidator()
  const navigate = useNavigate()

  const updateProduct = useCallback(
    async (product: Product) => {
      setLoading(true)
      const response = await service.update<Product>(product, 'updateProduct')
      if (response) {
        setProduct(response)
        enqueueSnackbar(t('messages.successSave'), {
          variant: 'success'
        })
      }
      setLoading(false)
    },
    [service, t]
  )

  const deleteProduct = useCallback(
    async (id: string) => {
      setLoading(true)
      const response = await service.remove(id)
      setLoading(false)
      if (response === true) {
        enqueueSnackbar(t('messages.successDelete'), {
          variant: 'success'
        })
      }
      return response === true
    },
    [service, t]
  )

  useEffect(() => {
    if (id !== 'new' && !isGuid(id as string)) {
      navigate('/manager/products')
      return
    }
    if (!id || id === 'new') {
      setProduct(new Product())
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<Product>(id)
      if (response) setProduct(response)
      setLoading(false)
    }
    getById(id)
  }, [id, isGuid, navigate, service, setLoading])

  const createProduct = useCallback(
    async (product: Product) => {
      setLoading(true)
      const response = await service.create(product)
      if (response) {
        setProduct(response)
        enqueueSnackbar(t('messages.successSave'), {
          variant: 'success'
        })
        navigate(`/manager/products/${response.id}`)
      }
      setLoading(false)
    },
    [navigate, service, t]
  )

  const deletePhoto = useCallback(
    async (imageId: string, colorId: string, productId: string) => {
      setLoading(true)
      const response = await service.remove('', `adminphoto/${productId}/${imageId}`)
      if (response?.success === true) {
        setProduct((prevState) => {
          if (prevState == null) return
          enqueueSnackbar(t('messages.successDelete'), {
            variant: 'success'
          })
          const index = prevState.colors.findIndex((x) => x.id === colorId)
          if (index > -1)
            prevState.colors[index].productImages = prevState.colors[index].productImages.filter(
              (x) => x.id !== imageId
            )
          return { ...prevState }
        })
      }
      setLoading(false)
    },
    [service, t]
  )

  const setMainPhoto = useCallback(
    async (imageId: string, colorId: string, productId: string) => {
      setLoading(true)
      const response: any = await service.post({}, `adminphoto/${productId}/setMain/${imageId}`)
      if (response?.success === true) {
        setProduct((prevState) => {
          if (prevState == null) return
          const index = prevState.colors.findIndex((x) => x.id === colorId)
          if (index > -1) {
            enqueueSnackbar(t('messages.successSave'), {
              variant: 'success'
            })
            prevState.colors[index].productImages.forEach((img) => {
              img.isMain = img.id === imageId
            })
          }
          return { ...prevState }
        })
      }
      setLoading(false)
    },
    [service, t]
  )

  return {
    productLoader,
    product,
    updateProduct,
    createProduct,
    deleteProduct,
    setMainPhoto,
    deletePhoto
  }
}
