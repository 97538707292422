import { Card, Typography, CardMedia, Stack, Chip, Button } from '@mui/material'
import { AddRemoveButton } from '../../../common/components/AddRemoveButton'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { EPriceView } from '../products/EPriceView'
import { ICartItemDto } from '../../../common/models/Order'
import Grid2 from '@mui/material/Unstable_Grid2'

export const CartItemMobile = ({
  cartItem,
  updateQuantity,
  removeFromCart
}: {
  cartItem: ICartItemDto
  updateQuantity: any
  removeFromCart: any
}) => {
  const { i18n, t } = useTranslation()
  const navigate = useNavigate()
  const image = cartItem.color.images.find((x) => x.isMain)

  return (
    <Card elevation={0}>
      <Grid2 container>
        <Grid2>
          <CardMedia
            onClick={() => navigate(`/products/${cartItem.productId}`)}
            component='img'
            sx={{ height: 180, width: 100, objectFit: 'cover' }}
            image={image?.url}
            alt='Sorry no image available'
          />
        </Grid2>
        <Grid2 xs='auto' padding={'5px 10px 10px'}>
          <Stack
            direction='row'
            sx={{ display: { xs: 'block', sm: 'flex' } }}
            justifyContent='space-between'
            alignItems='stretch'
            height={'100%'}
          >
            <Stack flex={1} justifyContent={'space-between'} height={'100%'}>
              <div style={{ marginBottom: '.5rem' }}>
                <Typography
                  component='div'
                  style={{ cursor: 'pointer' }}
                  variant='h6'
                  onClick={() => navigate(`/products/${cartItem.productId}`)}
                >
                  {i18n.language === 'en' ? cartItem.product.titleEn : cartItem.product.titleGr}
                </Typography>
                <Chip
                  label={
                    i18n.language === 'en' ? cartItem.color.colorNameEn : cartItem.color.colorNameGr
                  }
                  variant='outlined'
                  size='small'
                  style={{ marginRight: 5 }}
                />
                <Chip size='small' label={cartItem.size.sizeValue} variant='outlined' />
              </div>
              <EPriceView
                showDiscountTag={false}
                variant='subtitle2'
                price={cartItem.price * cartItem.quantity}
                discount={cartItem.discount * cartItem.quantity}
              />
              <Stack direction={'row'} justifyContent={'space-between'}>
                <AddRemoveButton
                  size='small'
                  quantity={cartItem.quantity}
                  maxQuantity={cartItem.size.stock}
                  setNewQuantity={(quantity) => updateQuantity(cartItem.sizeId, quantity)}
                />
                <Button
                  size='small'
                  color='error'
                  style={{ marginRight: 20 }}
                  onClick={() => removeFromCart(cartItem.sizeId)}
                >
                  {t('buttons.remove')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Grid2>
      </Grid2>
    </Card>
  )
}
