import { Divider, Stack, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const ReturnPolicy = () => {
  const topRef = useRef<any>(null)
  const { i18n, t } = useTranslation()
  const isEnglish = i18n.language === 'en'
  useEffect(() => {
    const timeout = setTimeout(() => {
      topRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }, 0)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Stack ref={topRef} sx={{ margin: '3rem 0 12rem' }}>
      <Typography variant='h3' color={'primary'}>
        {t('labels.returnpolicy')}
      </Typography>
      <Divider style={{ margin: '1rem 0' }} />
      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        {isEnglish
          ? 'We truly want you to be happy with your '
          : 'Θέλουμε πραγματικά να είστε ευχαριστημένοι με την '}
        <strong>MadooBrand</strong>
        {isEnglish ? ' purchase.' : ' αγορά σας'}
      </Typography>
      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        {isEnglish
          ? `In case you are not, don’t worry, we ‘ve got you covered! In the extreme rare case there is
        any kind of fault on our behalf, such as a faulty product, we will change your product with
        our charge and we will give you a discount for your next order! That’s right everything is
        on us !`
          : `Σε περίπτωση που δεν είστε, μην ανησυχείτε, σας έχουμε καλυμένους! Στην εξαιρετικά σπάνια περίπτωση που υπάρχει
          οποιουδήποτε είδους σφάλμα εκ μέρους μας, όπως ένα ελαττωματικό προϊόν, θα σας αλλάξουμε το προϊόν σας με
          με δική μας χρέωση και θα σας δώσουμε έκπτωση για την επόμενη παραγγελία σας! Ναι σωστά, όλα είναι
          πάνω μας !`}
      </Typography>

      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        {isEnglish
          ? `Just make sure you send us back the item, in perfect condition, exactly as received and we
        will take care the rest. Please note that we make a big effort to give you the best service
        possible. If you want to return an order you received, you have 10 days (from the date
        delivered to you) to send back the product to us for proper inspection.`
          : `Απλά βεβαιωθείτε ότι μας στέλνετε πίσω το αντικείμενο, σε άριστη κατάσταση, ακριβώς όπως το παραλάβατε και εμείς
          θα φροντίσουμε για τα υπόλοιπα. Παρακαλούμε σημειώστε ότι καταβάλλουμε μεγάλη προσπάθεια για να σας προσφέρουμε την καλύτερη εξυπηρέτηση
          δυνατή. Εάν θέλετε να επιστρέψετε μια παραγγελία που παραλάβατε, έχετε 10 ημέρες (από την ημερομηνία
          που σας παραδόθηκε) για να μας στείλετε πίσω το προϊόν για τον κατάλληλο έλεγχο.`}
      </Typography>

      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        {isEnglish ? `To send something back you can request a return via Email or Instagram` : ``}
      </Typography>

      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        <strong>{isEnglish ? `Note I` : `Σημείωση 1`}:</strong>
        {isEnglish
          ? ` You’ll need to send us a message on Instagram or in our email as
        soon as possible.`
          : ` Θα πρέπει να μας στείλετε ένα μήνυμα στο Instagram ή στο email μας ως
          το συντομότερο δυνατό.`}
      </Typography>

      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        <strong>{isEnglish ? `Note II` : `Σημείωση 2`}:</strong>
        {isEnglish
          ? ` Shipping costs are non-refundable. All costs associated with
        returning products are your responsibility.`
          : ` Τα έξοδα αποστολής δεν επιστρέφονται. Όλα τα έξοδα που σχετίζονται με
          επιστροφή των προϊόντων είναι δική σας ευθύνη.`}
      </Typography>

      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        <strong>{isEnglish ? `Note III` : `Σημείωση 3`}:</strong>
        {isEnglish
          ? ` Our discounts that are over 50% off are not subject to changes.`
          : ` Οι εκπτώσεις μας που ξεπερνούν το 50% δεν υπόκεινται σε αλλαγές.`}
      </Typography>
    </Stack>
  )
}
