import Grid from '@mui/material/Unstable_Grid2'
import { ProductTable } from './ProductTable'
import { ProductSearch } from './ProductSearch'
import { useProducts } from '../useProducts'
import { useEffect, useState } from 'react'
import { IProductSearchOptions } from './ProductSearchFilter'

const defaultReq: IProductSearchOptions = {
  currentPage: 1,
  pageSize: 20,
  productTypeId: null,
  sku: '',
  title: ''
}

export const ProductTableContainer = () => {
  const { productState, searchProducts } = useProducts()

  const [filters, setFilters] = useState<IProductSearchOptions>(defaultReq)

  const handleEnter = (changedFilters: IProductSearchOptions) => {
    const _filters = {
      ...filters,
      productTypeId: changedFilters.productTypeId,
      sku: changedFilters.sku,
      title: changedFilters.title
    }
    setFilters(_filters)
  }

  const onPaginationChange = (e: any, t: any) => {
    const _filters = {
      ...filters,
      currentPage: e.page + 1
    }
    setFilters(_filters)
  }

  useEffect(() => {
    const getProducts = async () => {
      searchProducts(filters)
    }
    getProducts()
  }, [filters, searchProducts])
  return (
    <Grid container spacing={3}>
      <Grid xs={12}>
        <ProductSearch handleEnter={handleEnter} />
      </Grid>
      <Grid xs={12}>
        <ProductTable onPaginationModelChange={onPaginationChange} pageState={productState} />
      </Grid>
    </Grid>
  )
}
