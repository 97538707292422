import { useEffect, useState } from 'react'
import { useBaseService } from './useBaseService'
import { Country } from '../models/Country'

export interface IuseCountriesProps {
  countryLoader: boolean
  countries: Country[]
}

export function useCountries(): IuseCountriesProps {
  const [countryLoader, setLoading] = useState(true)
  const [countries, setContries] = useState<Country[]>([])
  const service = useBaseService('lookup')

  useEffect(() => {
    async function fetchLookup() {
      setLoading(true)

      const response = await service.getLookupView<Country[]>('countries')
      if (response) setContries(response)
      setLoading(false)
    }
    if (service) fetchLookup()
  }, [service])

  return {
    countryLoader,
    countries
  }
}
