import { Button, Paper, Stack, TextField, Typography } from '@mui/material'
import { t } from 'i18next'
import { Controller, useForm } from 'react-hook-form'
import { MiControlledSelect } from '../../../../common/components/MiControlledSelect'
import { IProductSearchOptions } from './ProductSearchFilter'

interface IProductSearchProps {
  handleEnter: any
}

export const ProductSearch = ({ handleEnter }: IProductSearchProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<IProductSearchOptions>({
    defaultValues: {} as IProductSearchOptions,
    reValidateMode: 'onChange'
  })

  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      <Typography padding={'8px'} variant='h5'>
        {t('labels.search')}
      </Typography>
      <Stack direction={'row'} justifyContent='start' alignItems='start' spacing={2}>
        <MiControlledSelect
          required={false}
          lookupUrl='productType'
          control={control}
          path={'lookup'}
          label={t('labels.productType')}
          name='productTypeId'
          errors={errors}
        />
        <Controller
          control={control}
          defaultValue={''}
          name='sku'
          render={({ field: { ref, ...field } }) => (
            <TextField
              type='text'
              inputRef={ref}
              margin='none'
              {...field}
              fullWidth
              size='small'
              error={!!errors.sku}
              label={t('labels.sku')}
            />
          )}
        />
        <Controller
          control={control}
          defaultValue={''}
          name='title'
          render={({ field: { ref, ...field } }) => (
            <TextField
              type='text'
              inputRef={ref}
              margin='none'
              {...field}
              fullWidth
              size='small'
              error={!!errors.title}
              label={t('labels.name')}
            />
          )}
        />
        <Button
          style={{ width: '300px' }}
          color={'primary'}
          variant='contained'
          onClick={handleSubmit(handleEnter)}
        >
          {t('buttons.search')}
        </Button>
      </Stack>
    </Paper>
  )
}
