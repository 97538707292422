import Grid from '@mui/material/Unstable_Grid2'
import { ProductDetails } from './ProductDetails'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Product } from '../../../models/Product'
import { useForm } from 'react-hook-form'
import { useEffect } from 'react'
import { useProduct } from '../useProduct'
import { PaperHeader } from '../../../components/PaperHeader'
import { Button, ButtonGroup, Paper } from '@mui/material'
import { ProductColorList } from './ProductColorList'
import PreviewIcon from '@mui/icons-material/Preview'
import { Loader } from '../../../../common/components/Loader'
export const ProductContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()

  const { product, updateProduct, createProduct, deletePhoto, productLoader, setMainPhoto } =
    useProduct(id)
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors }
  } = useForm<Product>({
    defaultValues: { ...product } as Product,
    reValidateMode: 'onChange'
  })

  const submit = async (pro: Product) => {
    pro.id ? await updateProduct(pro) : await createProduct(pro)
  }

  const setMainPhotoHandler = async (imageId: string, colorId: string) => {
    await setMainPhoto(imageId, colorId, id as string)
  }

  const deletePhotoHandler = async (imageId: string, colorId: string) => {
    await deletePhoto(imageId, colorId, id as string)
  }

  const openProductToView = () => {
    window.open(`/products/${id}`)
  }

  useEffect(() => {
    if (id === 'new') {
      reset({ colors: [] })
    }
  }, [id, reset])

  useEffect(() => {
    reset({ ...product })
  }, [reset, product])

  if (productLoader) return <Loader isFullscreen type={'ring'} />
  return (
    <>
      <Paper elevation={1}>
        <Grid container direction={'row'} padding={'1em'} spacing={2}>
          <Grid xs={12}>
            <PaperHeader
              label={product?.id ? t('labels.editRecord') : t('labels.newRecord')}
              marginBottom={0}
              rightChild={
                <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
                  <Button
                    startIcon={<PreviewIcon />}
                    type='button'
                    color='primary'
                    variant='contained'
                    onClick={openProductToView}
                  >
                    {t('buttons.view')}
                  </Button>
                  <Button
                    type='button'
                    color='primary'
                    variant='contained'
                    onClick={handleSubmit(submit)}
                  >
                    {t('buttons.update')}
                  </Button>
                </ButtonGroup>
              }
            />
          </Grid>
          <Grid xs={12}>
            <ProductDetails control={control} product={product} watch={watch} errors={errors} />
          </Grid>
          <Grid xs={12}>
            <ProductColorList
              setMainPhoto={setMainPhotoHandler}
              deletePhoto={deletePhotoHandler}
              productId={id}
              control={control}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}
