import { Skeleton } from '@mui/material'

export const MiSkeleton = ({ lines = 1 }: { lines?: number }) => {
  return (
    <>
      <Skeleton />
      {lines > 1 && <Skeleton />}
      {lines > 2 && <Skeleton />}
      {lines > 3 && <Skeleton />}
      {lines > 4 && <Skeleton />}
      {lines > 5 && <Skeleton />}
      {lines > 6 && <Skeleton />}
      {lines > 7 && <Skeleton />}
      {lines > 8 && <Skeleton />}
      {lines > 9 && <Skeleton />}
      {lines > 10 && <Skeleton />}
    </>
  )
}
