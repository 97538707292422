import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { User } from '../../../common/models/User'
import { useNavigate } from 'react-router-dom'
import { useValidator } from '../../../../utils/useValidator'
import { replaceNullWithUndefined } from '../../../../utils/FormExtensions'

export interface IUseColorProps {
  loader: boolean
  user?: User
  create: (user: User) => Promise<void>
  update: (user: User) => Promise<void>
  resetPassword(id: string): Promise<void>
}

export function useUser(id?: string): IUseColorProps {
  const [loader, setLoading] = useState(false)
  const service = useBaseService('adminuser')
  const { t } = useTranslation()
  const { isGuid } = useValidator()
  const navigate = useNavigate()
  const [user, setUser] = useState<User>()

  const update = useCallback(
    async (entity: User) => {
      setLoading(true)
      const result = await service.update<User>(entity, entity.id)
      setLoading(false)
      if (result) {
        setUser(result)
        enqueueSnackbar(t('messages.successSave'), {
          variant: 'success'
        })
      }
    },
    [service, t]
  )

  const create = useCallback(
    async (entity: User) => {
      setLoading(true)
      const result = await service.create(entity)
      setLoading(false)
      if (result) {
        setUser(result)
        enqueueSnackbar(t('messages.successSave'), {
          variant: 'success'
        })
      }
    },
    [service, t]
  )

  const resetPassword = useCallback(
    async (id: string) => {
      setLoading(true)
      const result = await service.post('resetpassword', id)
      setLoading(false)
      if (result) {
        enqueueSnackbar(t('messages.successSave'), {
          variant: 'success'
        })
      }
    },
    [service, t]
  )

  useEffect(() => {
    if (id !== 'new' && !isGuid(id as string)) {
      navigate('/manager/users')
      return
    }
    if (!id || id === 'new') {
      setUser(new User())
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<User>(id)
      if (response) setUser(replaceNullWithUndefined(response))
      setLoading(false)
    }
    getById(id)
  }, [id, isGuid, navigate, service, setLoading])

  return {
    loader,
    update,
    create,
    user,
    resetPassword
  }
}
