import { useEffect, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { DiscountCouponDto } from '../../../common/models/DiscountCoupon'

export interface IUseCouponsProps {
  couponLoader: boolean
  updateCoupons: any
  coupons: DiscountCouponDto[]
}

export function useCoupons(): IUseCouponsProps {
  const [couponLoader, setLoading] = useState(true)
  const [coupons, setCoupons] = useState<DiscountCouponDto[]>([])
  const service = useBaseService('admincoupon')

  const updateCoupons = (coupon: DiscountCouponDto) => {
    const index = coupons.findIndex((x) => x.id === coupon.id)
    if (index > -1) {
      coupons[index].discountPercentage = coupon.discountPercentage
      coupons[index].expiryDate = coupon.expiryDate
      coupons[index].TimesUsed = coupon.TimesUsed
      coupons[index].useLimit = coupon.useLimit
      coupons[index].lastUpdated = coupon.lastUpdated
      coupons[index].isEnabled = coupon.isEnabled
      setCoupons([...coupons])
    } else {
      setCoupons([...coupons, coupon])
    }
  }

  useEffect(() => {
    async function fetchLookup() {
      setLoading(true)

      const response = await service.getAll('admincoupon/discountCoupons')
      if (response) setCoupons(response)
      setLoading(false)
    }
    if (service) fetchLookup()
  }, [service])

  return {
    couponLoader,
    updateCoupons,
    coupons
  }
}
