import { TextFieldProps } from '@mui/material'

export const fieldProps = (
  label: string,
  ref: any,
  field: any,
  fullWidth = true
): TextFieldProps => ({
  fullWidth: fullWidth,
  margin: 'normal',
  size: 'small',
  label: label,
  inputRef: ref,
  ...field
})

export const replaceNullWithUndefined = (obj: any): any =>
  Object.fromEntries(
    Object.entries(obj).map(([key, value]) => [key, value === null ? undefined : value])
  )
