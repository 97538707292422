import { FormControl, IconButton, InputAdornment, OutlinedInput, Stack } from '@mui/material'
import { useState } from 'react'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import { IProductFilterCriteriaDto } from '../useEProducts'
import { v4 as uuid } from 'uuid'

interface IEPricePickerFilterProps {
  filters: IProductFilterCriteriaDto
  onChangeFilters: any
}
const minPrice = 0
const maxPrice = 300
export const EPricePickerFilter = ({ filters, onChangeFilters }: IEPricePickerFilterProps) => {
  const [minValue, setMinValue] = useState(filters.minPrice.toString())
  const [maxValue, setMaxValue] = useState(filters.maxPrice.toString())

  return (
    <Stack direction={'row'} spacing={1} marginBottom={2}>
      <FormControl>
        <OutlinedInput
          id={uuid()}
          inputProps={{
            min: minPrice,
            size: 'small'
          }}
          onChange={(e) => setMinValue(Number(e.target.value).toString())}
          value={minValue}
          startAdornment={<InputAdornment position='start'>€</InputAdornment>}
          type='number'
          size='small'
          placeholder={minPrice.toString()}
        />
      </FormControl>
      <FormControl>
        <OutlinedInput
          id={uuid()}
          inputProps={{
            max: maxPrice,
            size: 'small'
          }}
          onChange={(e) => setMaxValue(Number(e.target.value).toString())}
          value={maxValue}
          startAdornment={<InputAdornment position='start'>€</InputAdornment>}
          type='number'
          size='small'
          placeholder={maxPrice.toString()}
        />
      </FormControl>
      <IconButton
        type='button'
        color='primary'
        onClick={() => onChangeFilters({ ...filters, maxPrice: +maxValue, minPrice: +minValue })}
      >
        <ManageSearchIcon />
      </IconButton>
    </Stack>
  )
}
