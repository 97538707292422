import { makeStyles } from 'tss-react/mui'
import { Button, Typography } from '@mui/material'
import { ISimpleLookup } from '../../../../common/models/SimpleLookup'
import i18n from '../../../../../i18n/i18n'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()(() => {
  return {
    root: {
      cursor: 'pointer',
      marginBottom: '10px'
    }
  }
})
interface IECategoryPickerFilterProps {
  selectedCategoryId: string | undefined
  setSelectedCategoryId: any
  categories: ISimpleLookup[]
}

export const ECategoryPickerFilter = ({
  selectedCategoryId,
  setSelectedCategoryId,
  categories
}: IECategoryPickerFilterProps) => {
  const { classes } = useStyles()
  const [showMore, setShowMore] = useState(false)
  const { t } = useTranslation()
  const productTypes = showMore ? categories : categories.slice(0, 4)
  return (
    <div>
      {productTypes?.map((c: ISimpleLookup) => (
        <Typography
          key={c.id}
          onClick={() => setSelectedCategoryId(c.id)}
          className={classes.root}
          style={{
            fontWeight: selectedCategoryId === c.id ? '900' : ''
          }}
        >
          {i18n.language === 'en' ? c.valueEn : c.valueGr}
        </Typography>
      ))}
      <Button
        style={{ padding: 0 }}
        variant='text'
        color='info'
        onClick={() => setShowMore(!showMore)}
      >
        {showMore ? t('buttons.less') : t('buttons.more')}
      </Button>
    </div>
  )
}
