import { Card, CardContent, TextField, Typography } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Order } from '../../../common/models/Order'
import { MiControlledSelectWithData } from '../../../common/components/MiControlledSelectWithData'
import { Country } from '../../../common/models/Country'

export interface ICartCustomerInfoProps {
  control: Control<Order, any>
  errors: FieldErrors<Order>
  countries: Country[]
}

const countryLabel = (c: Country, i18n: any) => {
  return i18n.language === 'en' ? c.titleEn : c.titleGr
}

export const CartCustomerInfo = ({ control, errors, countries }: ICartCustomerInfoProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant='h6' fontWeight={600} marginBottom={3}>
        {t('labels.customerInfo')}
      </Typography>
      <Card>
        <CardContent>
          <Grid container direction={'row'} spacing={2}>
            <Grid xs={12}>
              <Controller
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                name='email'
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    type='text'
                    inputRef={ref}
                    margin='none'
                    {...field}
                    fullWidth
                    size='small'
                    error={!!errors.email}
                    label={t('labels.email')}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <MiControlledSelectWithData
                data={countries}
                optionValue={countryLabel}
                control={control}
                errors={errors}
                label={t('labels.country')}
                name='countryId'
                margin='normal'
              />
            </Grid>
            <Grid xs={5}>
              <Controller
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                name='firstName'
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    type='text'
                    inputRef={ref}
                    margin='normal'
                    {...field}
                    fullWidth
                    size='small'
                    error={!!errors.firstName}
                    label={t('labels.name')}
                  />
                )}
              />
            </Grid>
            <Grid xs={7}>
              <Controller
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                name='lastName'
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    type='text'
                    inputRef={ref}
                    margin='normal'
                    {...field}
                    fullWidth
                    size='small'
                    error={!!errors.lastName}
                    label={t('labels.lastname')}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={9}>
              <Controller
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                name='address'
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    type='text'
                    inputRef={ref}
                    margin='normal'
                    {...field}
                    fullWidth
                    size='small'
                    error={!!errors.address}
                    label={t('labels.address')}
                  />
                )}
              />
            </Grid>
            <Grid xs={12} sm={3}>
              <Controller
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                name='postalCode'
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    type='number'
                    inputRef={ref}
                    margin='normal'
                    {...field}
                    fullWidth
                    size='small'
                    error={!!errors.postalCode}
                    label={t('labels.postalCode')}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={''}
                name='city'
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    type='text'
                    inputRef={ref}
                    margin='normal'
                    {...field}
                    fullWidth
                    size='small'
                    error={!!errors.city}
                    label={t('labels.city')}
                  />
                )}
              />
            </Grid>
            <Grid xs={12}>
              <Controller
                control={control}
                defaultValue={''}
                rules={{ required: true }}
                name='mobileNumber'
                render={({ field: { ref, ...field } }) => (
                  <TextField
                    type='text'
                    inputRef={ref}
                    margin='normal'
                    {...field}
                    fullWidth
                    size='small'
                    error={!!errors.mobileNumber}
                    label={t('labels.mobile')}
                  />
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
