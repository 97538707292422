import { Stack, Theme, Typography } from '@mui/material'

export const LabelValue = ({
  label,
  value,
  variant = 'h6',
  flexLabel = '30%',
  flexValue = '70%',
  alignItems = 'start',
  noWrap = true
}: {
  label: string
  value: any
  noWrap?: boolean
  alignItems?: 'start' | 'end' | 'center'
  flexLabel?: any
  flexValue?: any
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'body1' | 'body2' | 'subtitle1' | 'subtitle2'
}) => {
  return (
    <Stack flex={1} direction={'row'} spacing={1} alignItems={alignItems}>
      <Typography
        noWrap={noWrap}
        flexBasis={flexLabel}
        color={(theme: any) => theme.palette.primary.light}
        variant={variant}
      >
        {label}
      </Typography>
      <Typography
        flexBasis={flexValue}
        noWrap={noWrap}
        fontWeight={600}
        variant={variant}
        color={'rgb(103 58 14))'}
      >
        {value}
      </Typography>
    </Stack>
  )
}
