import axios from 'axios'
import { useSnackbar } from 'notistack'
import { useCallback, useMemo } from 'react'
import { useConfig } from '../../manager/services/useConfig'
import { BaseResult } from '../../manager/models/BaseResult'
import { useTranslation } from 'react-i18next'

export const useBaseService = (url: string) => {
  const { config } = useConfig()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const hasErrors = useCallback(
    (errors: string[]) => {
      if (errors != null && errors.length > 0) {
        enqueueSnackbar(errors[0], {
          variant: 'error',
          autoHideDuration: 5000
        })
        return true
      }
      return false
    },
    [enqueueSnackbar]
  )

  const getAll = useCallback(
    async (urlToOverride?: string) => {
      try {
        const _url = urlToOverride ? urlToOverride : `${url}`
        const response = await axios.get(`${config.apiUrl}${_url}`)
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (error) {
        hasErrors([t('messages.unknownError')])
        return null
      }
    },
    [config.apiUrl, hasErrors, t, url]
  )

  const getLookupView = useCallback(
    async <T,>(lookup: string) => {
      try {
        const response = await axios.get(`${config.apiUrl}${url}/${lookup}`)
        return hasErrors(response.data?.errors) ? null : (response.data.data as T)
      } catch (error) {
        hasErrors([t('messages.unknownError')])
        return null
      }
    },
    [config.apiUrl, hasErrors, t, url]
  )

  const getById = useCallback(
    async <T,>(id: string, urlToOverride?: string) => {
      const _url = urlToOverride ? urlToOverride : `${url}/${id}`
      try {
        const response = await axios.get(`${config.apiUrl}${_url}`)
        return hasErrors(response.data?.errors) ? null : (response.data.data as T)
      } catch (error) {
        hasErrors([t('messages.unknownError')])
        return null
      }
    },
    [config.apiUrl, hasErrors, t, url]
  )

  const remove = useCallback(
    async (id: string, urlToOverride?: string) => {
      const _url = urlToOverride ? urlToOverride : `${url}/${id}`
      try {
        const response = await axios.delete(`${config.apiUrl}${_url}`)
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (error) {
        hasErrors([t('messages.unknownError')])
        return null
      }
    },
    [config.apiUrl, hasErrors, t, url]
  )

  const create = useCallback(
    async <T,>(entity: T, endpoint: string = '') => {
      try {
        const response = await axios.post(`${config.apiUrl}${url}${endpoint}`, entity)

        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (error) {
        hasErrors([t('messages.unknownError')])
        return null
      }
    },
    [config.apiUrl, hasErrors, t, url]
  )

  const post = useCallback(
    async <T,>(entity: any, endpoint: string) => {
      try {
        const response = await axios.post(`${config.apiUrl}${endpoint}`, entity)
        return hasErrors(response.data?.errors) ? null : (response.data.data as T)
      } catch (error) {
        hasErrors([t('messages.unknownError')])
        return null
      }
    },
    [config.apiUrl, hasErrors, t]
  )

  const update = useCallback(
    async <T,>(entity: T, route: string) => {
      try {
        const response = await axios.put<BaseResult<T>>(`${config.apiUrl}${url}/${route}`, entity)
        return hasErrors(response.data?.errors) ? null : response.data.data
      } catch (error) {
        hasErrors([t('messages.unknownError')])
        return null
      }
    },
    [config.apiUrl, hasErrors, t, url]
  )

  return useMemo(() => {
    return {
      getAll,
      getById,
      remove,
      create,
      update,
      post,
      getLookupView
    }
  }, [getAll, getById, remove, post, create, update, getLookupView])
}
