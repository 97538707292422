import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EProductColorChip } from '../EProductColorChip'
import { makeStyles } from 'tss-react/mui'
import { EProductTable } from '../../../models/EProduct'
import { EProductSizeChip } from '../EProductSizeChip'
import { EProductCartHandler } from '../EProductCartHandler'
import { EPriceView } from '../EPriceView'
import { useEffect, useRef } from 'react'

interface IEProductDetailsProps {
  product: EProductTable
  selectedColor: number
  setSelectedColor: any
  setSelectedSize: any
  selectedSize: number
  quantity: number
  setQuanity: any
  addToCart: any
}
const useStyles = makeStyles()((theme) => {
  return {
    productSpecificatons: {
      fontFamily: theme.typography.fontFamily
    }
  }
})

export const EProductDetails = ({
  product,
  selectedColor,
  setSelectedColor,
  selectedSize,
  setSelectedSize,
  quantity,
  setQuanity,
  addToCart
}: IEProductDetailsProps) => {
  const topRef = useRef<any>(null)
  const { t, i18n } = useTranslation()
  const { classes } = useStyles()
  const stock = product.colors[selectedColor]?.sizes[selectedSize]?.stock

  useEffect(() => {
    const timeout = setTimeout(() => {
      topRef.current.scrollIntoView({ block: 'start' })
    }, 0)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div ref={topRef}>
      <Typography variant='h4' color={'primary'} fontWeight={400}>
        {i18n.language === 'en' ? product.titleEn : product.titleGr}
      </Typography>
      <Typography variant='subtitle2' fontWeight={100} marginBottom={0}>
        {t('labels.productCode')}: {product.sku}
      </Typography>
      <EPriceView
        marginBottom={2}
        price={product.colors[selectedColor]?.price}
        discount={product.colors[selectedColor]?.discount}
      />

      <Stack direction={'row'}>
        <Typography variant='body1' fontWeight={600} marginBottom={2}>
          {t('labels.color')}:
        </Typography>
        <Typography variant='body1' marginLeft={1}>
          {i18n.language === 'en'
            ? product.colors[selectedColor]?.colorNameEn
            : product.colors[selectedColor]?.colorNameGr}
        </Typography>
      </Stack>
      <EProductColorChip
        selectedColor={selectedColor}
        setSelectedColor={setSelectedColor}
        colors={product.colors}
      />
      <Stack marginTop={2} direction={'row'}>
        <Typography variant='body1' fontWeight={600} marginBottom={2}>
          {t('labels.size')}:
        </Typography>
        <Typography variant='body1' marginLeft={1}>
          {product.colors[selectedColor]?.sizes[selectedSize]?.sizeType}
        </Typography>
      </Stack>
      <EProductSizeChip
        setSelectedSize={setSelectedSize}
        selectedSize={selectedSize}
        sizes={product.colors[selectedColor]?.sizes}
      />
      {stock === undefined || stock === 0 ? (
        <Typography variant='body1' fontWeight={500} marginTop={2}>
          {i18n.language === 'en' ? product.outOfStockMessageEn : product.outOfStockMessageGr}
        </Typography>
      ) : (
        <>
          <Typography variant='body1' fontWeight={600} marginTop={2}>
            {t('labels.stock')}:
          </Typography>
          <EProductCartHandler
            quantity={quantity}
            changeQuantity={setQuanity}
            maxQuantity={stock}
            addToCart={addToCart}
          />
        </>
      )}
      <p
        className={classes.productSpecificatons}
        dangerouslySetInnerHTML={{
          __html: i18n.language === 'en' ? product.specificationsEn : product.specificationsGr
        }}
      ></p>
    </div>
  )
}
