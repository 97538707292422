import { Stack, Button, Card, CardContent } from '@mui/material'
import { AdminOrderDetailed, OrderStatus } from '../../../../common/models/Order'
import { useTranslation } from 'react-i18next'
import { IAdminOrderUpdate } from '../useAdminOrder'

interface IOrderActionsProps {
  order: AdminOrderDetailed
  changeOrderStatus: any
}

export const OrderActions = ({ order, changeOrderStatus }: IOrderActionsProps) => {
  const { t } = useTranslation()

  const changeStatus = async (status: OrderStatus) => {
    const req: IAdminOrderUpdate = {
      address: order.address,
      city: order.city,
      comments: order.comments,
      deliveryMethod: order.deliveryMethod,
      email: order.email,
      firstName: order.firstName,
      id: order.id,
      lastName: order.lastName,
      mobileNumber: order.mobileNumber,
      orderStatus: status,
      postalCode: order.postalCode
    }
    await changeOrderStatus(req)
  }
  if (order.orderStatus === OrderStatus.CANCELED) return null
  return (
    <Card>
      <CardContent>
        <Stack direction={'row'} spacing={2}>
          <Button
            variant='outlined'
            color='warning'
            onClick={() => changeStatus(OrderStatus.PENDING)}
          >
            {t('buttons.pendingOrder')}
          </Button>
          <Button variant='outlined' color='warning' onClick={() => changeStatus(OrderStatus.SEND)}>
            {t('buttons.toShipping')}
          </Button>

          <Button
            variant='contained'
            color='error'
            onClick={() => changeStatus(OrderStatus.CANCELED)}
          >
            {t('buttons.cancel')}
          </Button>
          <Button
            variant='contained'
            color='success'
            onClick={() => changeStatus(OrderStatus.COMPLETED)}
          >
            {t('buttons.complete')}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  )
}
