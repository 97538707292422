import { FormControl, InputLabel, Select, MenuItem } from '@mui/material'
import { Control, Controller, FieldErrors } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export interface IMiControlledSelectProps {
  errors: FieldErrors<any>
  data: any
  control: Control<any, any>
  label: string
  rowId?: string
  required?: boolean
  name: string
  margin?: 'none' | 'dense' | 'normal' | undefined
  flex?: string
  optionValue?: (item: any, i18n: any) => any
}

export const MiControlledSelectWithData = ({
  control,
  errors,
  name,
  margin = 'none',
  rowId = 'id',
  label,
  data,
  flex = 'unset',
  required = true,
  optionValue
}: IMiControlledSelectProps) => {
  const { i18n } = useTranslation()
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={''}
      rules={{ required: required }}
      render={({ field }) => (
        <FormControl size='small' style={{ flex: flex }} fullWidth margin={margin}>
          <InputLabel id={label + '_id'}>{label}</InputLabel>
          {data && data.length > 0 && (
            <Select {...field} fullWidth error={!!errors[name]} label={label}>
              {data.map((l: any, index: number) => (
                <MenuItem key={index} value={l[rowId]}>
                  {optionValue
                    ? optionValue(l, i18n)
                    : i18n.language === 'en'
                    ? l.valueEn
                    : l.valueGr}
                </MenuItem>
              ))}
            </Select>
          )}
        </FormControl>
      )}
    />
  )
}
