import { Button, Divider, Stack, TextField, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useCart } from '../../context/CartProvider'

interface ICartOrderSummaryProps {
  step: number
  sendOrder: any
  getValues: any
  watch: any
}
export const CartOrderSummary = ({ step, watch, sendOrder, getValues }: ICartOrderSummaryProps) => {
  const { t } = useTranslation()
  const { calculateTotal, calculateDiscount } = useCart()
  watch('paymentMethodId')
  watch('couponCode')
  const subTotal = calculateTotal()
  const deliveryCost = getValues('deliveryCost')
  const payOnDelivery = getValues('payOnDelivery') ?? 0

  const couponValue = getValues('couponValue')
  const discountAmount = calculateDiscount(subTotal, couponValue)
  const total = subTotal - discountAmount + deliveryCost + payOnDelivery
  return (
    <div style={{ marginBottom: '10px' }}>
      <Typography variant='h6' fontWeight={600} marginBottom={3}>
        {t('labels.orderSummary')}
      </Typography>

      <Stack direction={'row'} alignContent={'space-between'}>
        <Typography flex={1} variant='body1'>
          {t('labels.subtotal')}
        </Typography>
        <Typography variant='body1'> {subTotal.toFixed(2)} &euro;</Typography>
      </Stack>

      <Divider style={{ margin: '.5em 0' }} />

      <Stack direction={'row'} alignContent={'space-between'}>
        <Typography flex={1} variant='body1'>
          {t('labels.transferFee')}
        </Typography>
        <Typography variant='body1'> {deliveryCost} &euro;</Typography>
      </Stack>

      <Divider style={{ margin: '.5em 0' }} />

      {!!payOnDelivery && (
        <>
          <Stack direction={'row'} alignContent={'space-between'}>
            <Typography flex={1} variant='body1'>
              {t('labels.payOnDelivery')}
            </Typography>
            <Typography variant='body1'> {payOnDelivery} &euro;</Typography>
          </Stack>
          <Divider style={{ margin: '.5em 0' }} />
        </>
      )}
      {/* {!!couponValue && couponValue > 0 && (
        <>
          <Stack direction={'row'} alignContent={'space-between'}>
            <Typography flex={1} variant='body1'>
              {t('labels.discount')}
            </Typography>
            <Typography variant='body1'> {couponValue} %</Typography>
          </Stack>
          <Divider style={{ margin: '.5em 0' }} />
          <Stack direction={'row'} alignContent={'space-between'}>
            <Typography flex={1} variant='body1'>
              {t('labels.discountValue')}
            </Typography>
            <Typography variant='body1'> {discountAmount} &euro;</Typography>
          </Stack>
          <Divider style={{ margin: '.5em 0' }} />
        </>
      )} */}
      <Stack direction={'row'} alignContent={'space-between'} marginBottom={5}>
        <Typography flex={1} variant='body1' fontWeight={600}>
          {t('labels.total')}
        </Typography>
        <Typography variant='body1'> {total.toFixed(2)} &euro;</Typography>
      </Stack>

      {step === 2 && (
        <Button
          fullWidth
          size='large'
          style={{ color: 'white', background: '#303235', fontWeight: '600' }}
          variant='contained'
          onClick={sendOrder}
        >
          {t('labels.checkout')}
        </Button>
      )}
    </div>
  )
}
