import { EProductColor, EProductColorSize, EProductTable } from '../../eshop/models/EProduct'

export class Order {
  email: string
  firstName: string
  lastName: string
  mobileNumber: string
  countryId: string
  address: string
  city: string
  postalCode: string
  comments: string
  vivaWalletTransactionId: string
  orderTotalCost: number
  deliveryCost: number
  paymentMethodId: string
  cart: ICartDto
  payOnDelivery: number
  paymentCode: string
  couponCode: string
  // web use only
  couponValue: number
}
export interface ICartDto {
  userId: string
  cartItems: ICartItemDto[]
}

export interface ICartItemDto {
  cartId: string | null
  productId: string
  product: EProductTable
  colorId: string
  color: EProductColor
  sizeId: string
  size: EProductColorSize
  quantity: number
  price: number
  discount: number
}
export class AdminOrderTable {
  id: string
  orderTotalCost: number
  firstName: string
  lastName: string
  orderCode: string
  created: string
  paymentMethodEnum: PaymentMethodEnum
  paymentMethodName: string
  deliveryMethod: DeliveryMethod
  orderStatus: OrderStatus
}

export class AdminOrderDetailed {
  id: string
  orderTotalCost: number
  orderCode: string
  firstName: string
  lastName: string
  created: string
  paymentMethodEnum: PaymentMethodEnum
  paymentMethodName: string
  deliveryMethod: DeliveryMethod
  orderStatus: OrderStatus
  email: string
  mobileNumber: string
  countryName: string
  address: string
  city: string
  postalCode: string
  comments: string
  payOnDeliveryCost: number
  deliveryCost: number
  discountCostFiat: number
  discountCostPercent: number
  cart: AdminCart
}

export class AdminCart {
  cartItems: AdminCartItem[]
  totalCost: number
}

export class AdminCartItem {
  productTitle: string
  productPriceWhenBought: number
  quantity: number
  totalPrice: number
  productPrice: number
  productDiscount: number
  productImageUrl: string
  sizeType: string
  sizeValue: string
  sku: string
  productTypeName: string
  colorNameGr: string
}

export enum OrderStatus {
  CANCELED,
  PENDING,
  SEND,
  COMPLETED
}

export enum DeliveryMethod {
  COURIER, // Customer's package will be delivered
  PICKUP // Customer will pick up the package from the store
}

export enum PaymentMethodEnum {
  //Cash on delivery
  COD,
  PAYPAL,
  //Viva or something else
  CARD,
  BANK
}
