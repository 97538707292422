import { createContext, useContext, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { ICartItemDto, Order } from '../../common/models/Order'

export const CartStorageName = 'cart'
export const OrderStorageName = 'orderdetails'

const EmptyCartValue = { cart: { cartItems: [] as ICartItemDto[] } } as Order

const CartContext = createContext({
  cartOrder: EmptyCartValue,
  addToCart: (cardItem: ICartItemDto) => {},
  removeFromCart: (sizeId: string) => {},
  updateQuantity: (sizeId: string, newQuantity: number) => {},
  clearCart: () => null,
  calculateTotal: () => 0,
  calculateDiscount: (price: number, percent: number): number => 0
})

export const useCart = () => {
  return useContext(CartContext)
}

const initializeCart = () => {
  const cart = localStorage.getItem(CartStorageName)
  if (cart) {
    const parsed = JSON.parse(cart)
    return parsed
  }
  return EmptyCartValue
}

export const CartProvider = ({ children }: any) => {
  const [cartOrder, setCartOrder] = useState<Order>(initializeCart())
  const { t } = useTranslation()

  const addToCart = (cartItem: ICartItemDto) => {
    const updatedCart = {
      ...cartOrder,
      cart: { ...cartOrder.cart, cartItems: [...cartOrder.cart.cartItems] }
    }
    const existingItem = updatedCart.cart.cartItems.find((item) => item.sizeId === cartItem.sizeId)

    if (existingItem) {
      const hasMaxQuantity = existingItem.quantity + cartItem.quantity >= existingItem.size.stock
      existingItem.quantity = hasMaxQuantity
        ? existingItem.size.stock
        : existingItem.quantity + cartItem.quantity
    } else {
      updatedCart.cart.cartItems.push({ ...cartItem })
    }
    setCartOrder(updatedCart)
    localStorage.setItem(CartStorageName, JSON.stringify(updatedCart))
    enqueueSnackbar(t('messages.successCartItemAdded'), {
      variant: 'success'
    })
  }

  const clearCart = () => {
    const completeOrderCart = { ...EmptyCartValue }
    setCartOrder(completeOrderCart)
    localStorage.setItem(CartStorageName, JSON.stringify(completeOrderCart))
    return null
  }

  const removeFromCart = (sizeId: string) => {
    const cartItems = cartOrder.cart.cartItems.filter((item) => item.sizeId !== sizeId)
    const updatedCart = { ...cartOrder, cart: { ...cartOrder.cart, cartItems: [...cartItems] } }
    setCartOrder(updatedCart)
    localStorage.setItem(CartStorageName, JSON.stringify(updatedCart))
    enqueueSnackbar(t('messages.successCartItemRemoved'), {
      variant: 'success'
    })
  }

  const calculateDiscount = (price: number, percent: number) => {
    if (!percent || percent > 100 || percent < 0) return 0
    const discount = price * (percent / 100)
    return +discount.toFixed(2)
  }

  const updateQuantity = (sizeId: string, newQuantity: number) => {
    const cartItems = cartOrder.cart.cartItems.map((item) => {
      if (item.sizeId === sizeId) {
        item.quantity = newQuantity
      }
      return item
    })

    const updatedCart = { ...cartOrder, cart: { ...cartOrder.cart, cartItems: [...cartItems] } }
    setCartOrder(updatedCart)
    localStorage.setItem(CartStorageName, JSON.stringify(updatedCart))
    enqueueSnackbar(t('messages.successCartItemUpdated'), {
      variant: 'success'
    })
  }

  const calculateTotal = (): any => {
    return cartOrder.cart.cartItems.reduce(
      (total, item) => total + item.quantity * (item.price - item.discount),
      0
    )
  }

  return (
    <CartContext.Provider
      value={{
        cartOrder,
        addToCart,
        clearCart,
        removeFromCart,
        updateQuantity,
        calculateTotal,
        calculateDiscount
      }}
    >
      {children}
    </CartContext.Provider>
  )
}
