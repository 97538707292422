import {
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  ThemeProvider,
  Typography
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import { Controller, useForm } from 'react-hook-form'
import { makeStyles } from 'tss-react/mui'
import { ILoginModel, useManagerLogin } from './useManagerLogin'
import { LoadingButton } from '@mui/lab'
import { useBaseTheme } from '../../../../utils/useManagerTheme'

const useStyles = makeStyles()((theme) => {
  return {
    paper: {
      background: theme.palette.primary.main,
      padding: '1.2rem 1rem',
      margin: '0 2rem',
      position: 'relative',
      top: '4rem',
      textAlign: 'center'
    }
  }
})

const ManagerLogin = () => {
  const { loading, login } = useManagerLogin()
  const { classes } = useStyles()
  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ILoginModel>({
    defaultValues: { email: '', password: '', rememberMe: false }
  })

  return (
    <Grid spacing={0}>
      <Grid display='flex' height={'100vh'} justifyContent='center' alignItems='center'>
        <div>
          <Paper className={classes.paper} elevation={4}>
            <Typography variant='h6' color={'white'}>
              Σύνδεση σε αποθήκη Madoo
            </Typography>
            <Typography color={'whitesmoke'} variant='body1' sx={{ fontSize: '14px' }}>
              Παρακαλώ συμπληρώστε τα στοιχεία σας για να συνδεθείτε
            </Typography>
          </Paper>
          <Card style={{ paddingTop: '4rem' }}>
            <CardContent style={{ padding: '2rem' }}>
              <div>
                <Controller
                  name='email'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      inputRef={ref}
                      {...field}
                      margin='normal'
                      fullWidth
                      id='username'
                      required
                      error={!!errors.email}
                      helperText='Email is required'
                      label='Email'
                    />
                  )}
                />
                <Controller
                  name='password'
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, ...field } }) => (
                    <TextField
                      inputRef={ref}
                      {...field}
                      id='password'
                      margin='normal'
                      fullWidth
                      required
                      error={!!errors.password}
                      helperText='Password is required'
                      label='Password'
                    />
                  )}
                />
              </div>
              <div className='mb-5'>
                <FormControlLabel
                  label={'Remember Me'}
                  control={
                    <Controller
                      name='rememberMe'
                      control={control}
                      render={({ field: { onChange, onBlur, value, name, ref } }) => (
                        <Checkbox
                          name={name}
                          onBlur={onBlur} // notify when input is touched
                          onChange={onChange} // send value to hook form
                          checked={value}
                          inputRef={ref}
                        />
                      )}
                    />
                  }
                />
              </div>
              <LoadingButton
                fullWidth
                loading={loading}
                variant='contained'
                color='primary'
                onClick={handleSubmit((data) => login(data))}
              >
                Σύνδεση
              </LoadingButton>
            </CardContent>
          </Card>
        </div>
      </Grid>
    </Grid>
  )
}

export const ManagerLoginContainer = () => {
  const theme = useBaseTheme()
  return (
    <ThemeProvider theme={theme}>
      <ManagerLogin />
    </ThemeProvider>
  )
}
