import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Chip,
  Avatar
} from '@mui/material'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ProductColor } from '../../../models/Product'
import { fieldProps } from '../../../../../utils/FormExtensions'
import { MiControlledSelectWithData } from '../../../../common/components/MiControlledSelectWithData'
import { ProductSize } from '../../../../common/models/Sizes'
import { SelectChip } from '../../../components/SelectChip'
import { Color } from '../../../../common/models/Color'

export interface IProductColorModalProps {
  entity?: ProductColor
  onConfirm: any
  onCancel: any
  colors: Color[]
  sizes: ProductSize[]
}
export const ProductColorModal = ({
  onCancel,
  onConfirm,
  entity = new ProductColor(),
  colors,
  sizes
}: IProductColorModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'newRecord' : 'editRecord'

  const colorSizeControl = useForm<ProductSize>({
    defaultValues: new ProductSize()
  })

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<ProductColor>({
    defaultValues: entity
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'sizes'
  })

  const addEntity = (size: ProductSize) => {
    const _size = sizes.find((x) => x.sizeId === size.sizeId) as ProductSize
    _size.stock = size.stock
    append(_size)
    colorSizeControl.reset(new ProductSize())
  }

  const removeEntity = (size: ProductSize) => {
    const i = fields.findIndex((f: ProductSize) => f.id === size.id)
    remove(i)
  }

  const sumbitForm = (data: ProductColor) => onConfirm(data)

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle id='alert-dialog-title'>{t('labels.' + title)}</DialogTitle>
      <DialogContent>
        <MiControlledSelectWithData
          data={colors}
          optionValue={SelectChip}
          control={control}
          errors={errors}
          label={t('labels.color')}
          name='colorId'
          margin='normal'
        />
        <Controller
          control={control}
          defaultValue={0}
          name='price'
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t('labels.price'), ref, field)} error={!!errors.price} />
          )}
        />

        <Controller
          control={control}
          defaultValue={0}
          name='discount'
          render={({ field: { ref, ...field } }) => (
            <TextField {...fieldProps(t('labels.discount'), ref, field)} error={!!errors.price} />
          )}
        />
        <Stack
          direction={'row'}
          spacing={1}
          marginBottom={2}
          marginTop={1}
          alignContent={'space-between'}
          alignSelf={'center'}
        >
          <MiControlledSelectWithData
            data={sizes}
            rowId={'sizeId'}
            optionValue={(size: ProductSize, _: any) => `${size.sizeValue} - (${size.sizeType})`}
            control={colorSizeControl.control}
            errors={errors}
            label={t('labels.size')}
            name='sizeId'
            margin='normal'
          />
          <Controller
            control={colorSizeControl.control}
            defaultValue={0}
            rules={{ required: true }}
            name='stock'
            render={({ field: { ref, ...field } }) => (
              <TextField {...fieldProps(t('labels.stock'), ref, field, false)} type={'number'} />
            )}
          />
          <Button style={{ flex: 1 }} onClick={colorSizeControl.handleSubmit(addEntity)}>
            {t('buttons.add')}
          </Button>
        </Stack>
        {fields?.map((s: ProductSize, i: number) => (
          <Chip
            component={'span'}
            key={i}
            style={{ marginRight: '8px' }}
            variant='outlined'
            label={s.sizeValue}
            onDelete={() => removeEntity(s)}
            avatar={<Avatar component={'span'}>{s.stock}</Avatar>}
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        <Button color='success' onClick={handleSubmit(sumbitForm)} autoFocus>
          {t('buttons.insert')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
