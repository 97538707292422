import { StepConnector, stepConnectorClasses, styled } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import CheckroomIcon from '@mui/icons-material/Checkroom'
import InfoIcon from '@mui/icons-material/Info'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { StepIconProps } from '@mui/material/StepIcon'
import { useTranslation } from 'react-i18next'
import PaymentIcon from '@mui/icons-material/Payment'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#95785c '
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: '#95785c '
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}))

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <CheckroomIcon />,
    2: <InfoIcon />,
    3: <PaymentIcon />,
    4: <TaskAltIcon />
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}
const steps = ['labels.products', 'labels.customerInfo', 'labels.orderDetails', 'labels.complete']
const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '5%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: '#95785c ',
    boxShadow: '0px 1px 12px 1px #6c512f'
  }),
  ...(ownerState.completed && {
    backgroundColor: '#95785c '
  })
}))

export interface ICartStepperProps {
  step: number
}

export const CartStepper = ({ step }: ICartStepperProps) => {
  const { t } = useTranslation()
  return (
    <Stepper
      style={{ width: '100%', margin: '2em 0' }}
      alternativeLabel
      activeStep={step}
      connector={<ColorlibConnector />}
    >
      {steps.map((label) => (
        <Step key={label}>
          <StepLabel StepIconComponent={ColorlibStepIcon}>{t(label)}</StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}
