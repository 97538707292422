import { useCallback, useState } from 'react'
import { AdminOrderTable } from '../../../common/models/Order'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { IOrderSearchOptions } from './table/IOrderSearchOptions'

export interface IOrdersTableResponse {
  items: AdminOrderTable[]
  totalItems: number
  totalPages: number
  currentPage: number
}

export interface IUseOrdersProps {
  orderLoader: boolean
  orders: IOrdersTableResponse
  searchOrders: (options: IOrderSearchOptions) => Promise<void>
}

export function useOrders(): IUseOrdersProps {
  const [orderLoader, setLoading] = useState(false)
  const [orders, setOrders] = useState<IOrdersTableResponse>({} as IOrdersTableResponse)
  const service = useBaseService('order')

  const searchOrders = useCallback(
    async (options: IOrderSearchOptions) => {
      setLoading(true)
      const response = await service.post<IOrdersTableResponse>(options, `api/adminorder/getorders`)
      if (response) setOrders(response)
      setLoading(false)
    },
    [service]
  )

  return {
    orderLoader,
    orders,
    searchOrders
  }
}
