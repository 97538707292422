import { Paper, Button, ButtonGroup, IconButton, Chip } from '@mui/material'
import { MiDatagrid } from '../../../../common/components/MiDatagrid'
import { AdminOrderTable } from '../../../../common/models/Order'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import moment from 'moment'
import { Source } from '@mui/icons-material'
import { OrderStatusChip } from '../../../../common/components/OrderStatusChip'

export interface IOrdersTableProps {
  data: AdminOrderTable[]
  loading: boolean
}

export const OrdersTable = ({ data, loading }: IOrdersTableProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },

    {
      headerName: t('labels.orderCode') as string,
      field: 'orderCode',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => `#${params.row?.orderCode}`
    },
    {
      headerName: t('labels.total') as string,
      field: 'orderTotalCost',
      flex: 0,
      width: 90,
      renderCell: (params: GridRenderCellParams) => `${params.row.orderTotalCost} €`
    },
    {
      headerName: t('labels.status') as string,
      field: 'orderStatus',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <OrderStatusChip status={params.row?.orderStatus} />
      )
    },

    {
      headerName: t('labels.name') as string,
      field: 'firstName',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        `${params.row?.lastName} ${params.row?.firstName}`
    },
    {
      headerName: t('labels.paymentMethod') as string,
      field: 'paymentMethodName',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip size='medium' label={`${params.row?.paymentMethodName}`} color='info' />
      )
    },
    {
      headerName: t('labels.created') as string,
      field: 'created',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.created).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.actions') as string,
      field: 'actions',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 90,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='primary' onClick={() => navigate(`/manager/orders/${params.row.id}`)}>
            <Source fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]
  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      <MiDatagrid
        title={t('labels.products')}
        actions={
          <Button
            color={'primary'}
            size='small'
            variant='outlined'
            onClick={() => navigate('/manager/products/new')}
          >
            {t('buttons.add')}
          </Button>
        }
        data={data}
        columns={columns}
        loading={loading}
      />
    </Paper>
  )
}
