import { Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { makeStyles } from 'tss-react/mui'
import { Loader } from './Loader'
import { EmptyTable } from './EmptyTable'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      border: 'none',
      '& .MuiDataGrid-overlayWrapper': { height: '200px' }
    }
  }
})

export interface IPageState<T> {
  loading: boolean
  data: T[]
  total: number
  page: number
  pageSize: number
}

export interface IMiPaginatedDataGridProps<T> {
  pageState: IPageState<T>
  columns: GridColDef[]
  onPaginationModelChange: any
  title: string
  actions?: any
}

export const MiPaginatedDataGrid = <T extends object>({
  pageState,
  columns,
  onPaginationModelChange,
  title,
  actions
}: IMiPaginatedDataGridProps<T>) => {
  const { classes } = useStyles()
  return (
    <Stack>
      <Stack
        padding={1}
        direction='row'
        spacing={2}
        alignItems={'center'}
        alignContent={'space-between'}
      >
        <Stack flex={1}>
          <Typography variant='h5' component='div'>
            {title}
          </Typography>
        </Stack>
        <Stack>{actions}</Stack>
      </Stack>

      <DataGrid
        className={classes.root}
        rows={pageState.loading ? [] : pageState.data ?? []}
        rowCount={pageState.total}
        loading={pageState.loading}
        slots={{
          loadingOverlay: () => <Loader type='circles' noText />,
          noRowsOverlay: () => <EmptyTable />
        }}
        paginationMode='server'
        onPaginationModelChange={onPaginationModelChange}
        getRowId={(row) => row.id}
        columns={columns}
        disableRowSelectionOnClick
        paginationModel={{ page: pageState.page - 1, pageSize: pageState.pageSize }}
        pageSizeOptions={[10, 20, 50, 100]}
        disableColumnMenu
      />
    </Stack>
  )
}
