export class EProductTable {
  id: string
  titleGr: string
  titleEn: string
  descriptionEn: string
  descriptionGr: string
  specificationsEn: string
  specificationsGr: string
  outOfStockMessageEn: string
  outOfStockMessageGr: string
  discount: number
  sku:string
  colors: EProductColor[]
}

export class EProductColor {
  id: string
  colorNameEn: string
  colorNameGr: string
  colorValue: string
  price: number
  isMain: boolean
  discount: number
  sizes: EProductColorSize[]
  images: EProductColorImage[]
}

export class EProductColorSize {
  id: string
  stock: number
  sizeType: string
  sizeValue: string
  unitSystem:string;
  unit:string;
}

export class EProductColorImage {
  id: string
  url: string
  isMain: boolean
}
