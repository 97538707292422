import { AdminCartItem } from '../../../../common/models/Order'
import { OrderItem } from './OrderItem'

interface IOrderItemsProps {
  items: AdminCartItem[] | undefined
}

export const OrderItems = ({ items }: IOrderItemsProps) => {
  return <div>{items && items.map((item, index) => <OrderItem key={index} item={item} />)}</div>
}
