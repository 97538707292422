import {
  Card,
  Typography,
  CardContent,
  CardMedia,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { EProductColorChip } from '../EProductColorChip'
import { useNavigate } from 'react-router-dom'
import defaultDress from '../../../../../images/defaultDress.png'
import { EProductTable } from '../../../models/EProduct'
import { calculatePriceAfterDiscount } from '../../../../../utils/calculations'
import { EPriceDiscountChip } from './EPriceDiscountChip'

export interface IEProductCardViewProps {
  product: EProductTable
}
export const EProductCardView = ({ product }: IEProductCardViewProps) => {
  const { i18n } = useTranslation()
  const [selectedColor, setSelectedColor] = useState(0)
  const navigate = useNavigate()
  const mainImage = product.colors[selectedColor]?.images.find((x) => x.isMain)
  const displayImage = mainImage?.url ?? defaultDress
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <Card elevation={0}>
      <CardMedia
        onClick={() => navigate(`/products/${product.id}`)}
        style={{ cursor: 'pointer', aspectRatio: '3/4' }}
        component='img'
        height='100%'
        image={displayImage}
        alt={'Image not visible'}
      />
      <CardContent>
        <Stack
          direction={'row'}
          position={'relative'}
          alignContent={'space-between'}
          alignItems={'center'}
          marginBottom={1}
        >
          {isMobile ? (
            <Typography
              flex={1}
              textAlign={'center'}
              style={{ cursor: 'pointer', fontSize: '1rem' }}
              onClick={() => navigate(`/products/${product.id}`)}
              fontWeight={600}
            >
              {i18n.language === 'en' ? product.titleEn : product.titleGr}
            </Typography>
          ) : (
            <Typography
              flex={1}
              style={{ cursor: 'pointer' }}
              onClick={() => navigate(`/products/${product.id}`)}
              fontWeight={600}
            >
              {i18n.language === 'en' ? product.titleEn : product.titleGr}
            </Typography>
          )}
          <EPriceDiscountChip
            price={product.colors[selectedColor]?.price}
            discount={product.colors[selectedColor]?.discount}
          />
        </Stack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignContent={'space-between'}
          alignItems={'center'}
        >
          <EProductColorChip
            isMobile={isMobile}
            selectedColor={selectedColor}
            setSelectedColor={setSelectedColor}
            colors={product.colors}
          />

          <Typography variant='h6' fontWeight={800}>
            {calculatePriceAfterDiscount(
              product.colors[selectedColor]?.price,
              product.colors[selectedColor]?.discount
            )}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}
