import { Button } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { MiDatagrid } from '../../../common/components/MiDatagrid'
import { Color } from '../../../common/models/Color'

export interface IColorTableProps {
  columns: GridColDef[]
  setSelectedEntity: any
  loader: boolean
  data: Color[]
}

export const ColorTable = ({ data, loader, columns, setSelectedEntity }: IColorTableProps) => {
  const { t } = useTranslation()

  return (
    <>
      <MiDatagrid
        title={t('labels.colors')}
        actions={
          <Button
            color={'primary'}
            size='small'
            variant='outlined'
            onClick={() => setSelectedEntity(new Color())}
          >
            {t('buttons.add')}
          </Button>
        }
        data={data}
        columns={columns}
        loading={loader}
      />
    </>
  )
}
