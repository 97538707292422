export function calculatePercentage(part: any, whole: any) {
  if (!whole || whole === 0) {
    return 0
  }
  return (part / whole) * 100
}

export function getFirstElementSafe(arr: any[] | null): any {
  if (Array.isArray(arr) && arr.length > 0) {
    return arr[0]
  } else {
    return null // or any other default value you prefer
  }
}

export function calculateDiscountPercentage(
  initialPrice: number,
  discountAmount: number
): string | null {
  if (initialPrice <= 0 || discountAmount <= 0) {
    return null // Prevent division by zero and negative discounts
  }

  // Calculate the percentage discount
  const percentageDiscount = (discountAmount / initialPrice) * 100
  return percentageDiscount.toFixed(0)
}

export function calculatePriceAfterDiscount(initialPrice: number, discountAmount: number): string {
  if (!discountAmount) return `${initialPrice.toFixed(1)} €`

  // Calculate the percentage discount
  const finalPrice = initialPrice - discountAmount
  return `${finalPrice.toFixed(1)} €`
}
