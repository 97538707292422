import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { HomeCard } from './HomeCard'
import proposal1 from '../../../../images/shirtTop.jpg'
import proposal2 from '../../../../images/sorts.jpg'
import proposal3 from '../../../../images/setsImage.jpg'
import proposal4 from '../../../../images/dresses.jpg'
import { useNavigate } from 'react-router-dom'

export const HomeCategories = () => {
  const navigate = useNavigate()
  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12} sm={3}>
        <HomeCard
          title='home.shirts'
          image={proposal1}
          alt={''}
          action={() => navigate('/products?category=A5860938-0E97-4FF6-A696-060B02126F43')}
        />
      </Grid2>
      <Grid2 xs={12} sm={3}>
        <HomeCard
          title='home.jumpsuits'
          image={proposal2}
          alt={''}
          action={() => navigate('/products?category=c59652e2-c1f9-46f9-b189-b0e8e4e6c7bb')}
        />
      </Grid2>
      <Grid2 xs={12} sm={3}>
        <HomeCard
          title='home.sets'
          image={proposal3}
          alt={''}
          action={() => navigate('/products?category=560cbe5b-7651-4cb8-8f58-cfd7bb48f4b3')}
        />
      </Grid2>
      <Grid2 xs={12} sm={3}>
        <HomeCard
          title='home.dresses'
          image={proposal4}
          alt={''}
          action={() => navigate('/products?category=9bb57610-bac8-4e76-a40e-a407ee8576b8')}          
        />
      </Grid2>
    </Grid2>
  )
}
