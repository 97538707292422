import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import proposal1 from '../../../../images/pjrilspe3r1cnucpgb2t.jpg'
import proposal2 from '../../../../images/bkrwx1txdzle5nye9y5r.jpg'
import proposal3 from '../../../../images/emcgp3p5pd5v5cnfodlo.jpg'
import proposal4 from '../../../../images/aqyykndfcbwcakmwsznh.jpg'
import { HomeCard } from './HomeCard'

export const HomeProposals = () => {
  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12} sm={3}>
        <HomeCard title='home.product1' image={proposal1} alt={''} />
      </Grid2>
      <Grid2 xs={12} sm={3}>
        <HomeCard title='home.product2' image={proposal2} alt={''} />
      </Grid2>
      <Grid2 xs={12} sm={3}>
        <HomeCard title='home.product3' image={proposal3} alt={''} />
      </Grid2>
      <Grid2 xs={12} sm={3}>
        <HomeCard title='home.product4' image={proposal4} alt={''} />
      </Grid2>
    </Grid2>
  )
}
