import { Container, ThemeProvider } from '@mui/material'
import { EShopNavbar } from './EShopNavbar'
import { Outlet } from 'react-router-dom'
import { useEshopTheme } from '../../utils/useEshopTheme'
import { CartProvider } from '../../context/CartProvider'
import { EShopBreadCrumb } from './EShopBreadCrumb'
import { EShopFooter } from './EShopFooter'
import { EshopSubFooter } from './EshopSubFooter'

export const EShopLayout = () => {
  const theme = useEshopTheme()

  return (
    <ThemeProvider theme={theme}>
      <CartProvider>
        <EShopNavbar />
        <Container maxWidth={'xl'}>
          <EShopBreadCrumb />
          <Outlet />
        </Container>
        <EshopSubFooter />
        <EShopFooter />
      </CartProvider>
    </ThemeProvider>
  )
}
