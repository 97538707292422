// DashboardCounterItem.js
import React from 'react'
import { Card, CircularProgress, Stack, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useCounterItem } from './useCounterItem'

const useStyles = () =>
  makeStyles()(() => {
    return {
      root: {
        position: 'relative',
        border: '1px solid #8d55425e'
      },
      iconRight: {
        position: 'absolute',
        right: 20,
        top: '30%',
        fontSize: 60,
        opacity: 0.1
      },
      title: {
        fontSize: 14
      }
    }
  })
interface IDashboardCounterItemProps {
  title: string
  valueSuffix?: string
  description: string
  icon: any
  type: string
}

const DashboardCounterItem = ({
  title,
  description,
  icon,
  valueSuffix,
  type
}: IDashboardCounterItemProps) => {
  const { metric, loading } = useCounterItem(type)
  const { classes } = useStyles()()
  const Icon = icon

  return (
    <Card className={classes.root}>
      <Icon className={classes.iconRight} />
      <Stack padding={2} direction='column' justifyContent='space-between' alignItems='flex-start'>
        <Stack>
          <Typography
            marginBottom={0}
            className={classes.title}
            color='primary'
            fontWeight={600}
            gutterBottom
          >
            {title}
          </Typography>
        </Stack>
        <Stack>
          {loading ? (
            <CircularProgress
              size={34}
              sx={{
                padding: '0.5rem 0'
              }}
            />
          ) : (
            <Typography marginBottom={1} variant='h4' component='h1'>
              {metric?.counter ?? 0} {valueSuffix}
            </Typography>
          )}
        </Stack>
        <Stack>
          <Typography variant='body2' color='GrayText'>
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  )
}

export default DashboardCounterItem
