import DashboardCounterItem from './DashboardCounterItem'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import ReceiptIcon from '@mui/icons-material/Receipt'
import QrCodeIcon from '@mui/icons-material/QrCode'
import { useTranslation } from 'react-i18next'
import CustomerChart from './CustomerChart'

export const DashboardContainer = () => {
  const { t } = useTranslation()
  const data = [
    {
      title: t('labels.sales'),
      description: t('messages.monthly', { value: t('labels.sales') }),
      icon: PointOfSaleIcon,
      valueSuffix: '€',
      type: 'sales'
    },
    {
      title: t('labels.purchases'),
      description: t('messages.monthly', { value: t('labels.purchases') }),
      icon: ReceiptIcon,
      valueSuffix: '€',
      type: 'expenses'
    },
    {
      title: t('labels.products'),
      description: t('labels.availableProducts'),
      icon: QrCodeIcon,
      valueSuffix: '',
      type: 'products'
    },
    {
      title: t('labels.orders'),
      description: t('labels.activeOrders'),
      icon: ShoppingBasketIcon,
      valueSuffix: '',
      type: 'orders'
    }
  ]
  return (
    <section>
      <Grid2 container spacing={2} marginBottom={'10rem'}>
        {data.map((item, index) => (
          <Grid2
            key={index}
            xs={2}
            sm={12 / data.length}
            md={12 / data.length}
            lg={12 / data.length}
          >
            <DashboardCounterItem
              key={index}
              title={item.title}
              valueSuffix={item.valueSuffix}
              description={item.description}
              icon={item.icon}
              type={item.type}
            />
          </Grid2>
        ))}
        <Grid2 xs={4}>
          <CustomerChart />
        </Grid2>
      </Grid2>
    </section>
  )
}
