import AppBar from '@mui/material/AppBar'
import { makeStyles } from 'tss-react/mui'
import Container from '@mui/material/Container'
import { useNavigate } from 'react-router-dom'

import MenuIcon from '@mui/icons-material/Menu'
import logo from '../../../../images/newLogo.png'
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography
} from '@mui/material'
import { changeLanguage, t } from 'i18next'
import { useState } from 'react'
import { LanguageOutlined, LocalMall } from '@mui/icons-material'
import { useCart } from '../../context/CartProvider'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()((theme) => {
  return {
    image: {
      width: '250px',
      [theme.breakpoints.down('sm')]: {
        width: '200px'
      }
    },
    header: {
      background: `radial-gradient(circle, #cebfb1 20%, ${theme.palette.primary.main} 100%)`
    }
  }
})

export const EShopNavbar = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { cartOrder } = useCart()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget)
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  const goToProudcts = () => {
    navigate('/products')
    handleCloseNavMenu()
  }

  const handleLangChange = (lang: string) => {
    localStorage.setItem('selectedLang', lang)
    changeLanguage(lang)
  }
  const handleClose = () => setAnchorEl(null)
  return (
    <AppBar className={classes.header} position='static'>
      <Container maxWidth='xl'>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          justifyItems={'center'}
          alignItems={'center'}
          alignSelf={'center'}
          height={'80px'}
        >
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size='large'
              aria-label='account of current user'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={handleOpenNavMenu}
              color='inherit'
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
              }}
            >
              <MenuItem key={1} onClick={goToProudcts}>
                <Typography textAlign='center'> {t('labels.products')}</Typography>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleLangChange('en')
                  handleCloseNavMenu()
                }}
              >
                English
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleLangChange('el')
                  handleCloseNavMenu()
                }}
              >
                Ελληνικά
              </MenuItem>
            </Menu>
          </Box>
          <Stack sx={{ display: { xs: 'none', sm: 'flex' } }}>
            <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
              <Button
                style={{ color: 'whitesmoke', fontSize: '24px' }}
                onClick={() => navigate('/products')}
              >
                {t('labels.products')}
              </Button>
            </ButtonGroup>
          </Stack>
          <Stack>
            <img
              onClick={() => navigate('/')}
              className={classes.image}
              src={logo}
              alt='MadooBrand'
            />
          </Stack>
          <Stack>
            <ButtonGroup variant='outlined' aria-label='outlined button group'>
              <IconButton onClick={() => navigate('/products/cart')}>
                <Badge
                  slotProps={{
                    badge: { style: { background: '#3f3838', color: 'white', fontSize: '12px' } }
                  }}
                  badgeContent={cartOrder.cart?.cartItems?.length}
                >
                  <LocalMall style={{ color: 'whitesmoke' }} />
                </Badge>
              </IconButton>
              <IconButton onClick={handleClick} sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <LanguageOutlined style={{ color: 'whitesmoke' }} />
                <Typography
                  style={{ height: '1em', lineHeight: '1.2', color: 'whitesmoke' }}
                  marginLeft={1}
                >
                  {i18n.language.toUpperCase()}
                </Typography>
              </IconButton>
            </ButtonGroup>
            <Menu
              anchorEl={anchorEl}
              id='language-menu'
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              slotProps={{
                paper: {
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0
                    }
                  }
                }
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <MenuItem onClick={() => handleLangChange('en')}>English</MenuItem>
              <MenuItem onClick={() => handleLangChange('el')}>Ελληνικά</MenuItem>
            </Menu>
          </Stack>
        </Stack>
      </Container>
    </AppBar>
  )
}
