import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import imageRight from '../../../../images/image1.jpg'
import imageLeft from '../../../../images/aggohzw2cfua8koubyfv.jpg'
import { HomeCard } from './HomeCard'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(() => {
  return {
    overlay: {
      position: 'absolute',
      background: '#00000052',
      top: 0,
      left: 0,
      width: '100%',
      height: 'calc(100%)'
    },
    video: {
      position: 'relative',
      width: '100%',
      height: 'calc(100% - 5px)',
      '> div > video': {
        objectFit: 'cover'
      }
    }
  }
})
export const HomeProducts = () => {
  const { classes } = useStyles()
  return (
    <Grid2 container spacing={1}>
      <Grid2 xs={12} sm={6}>
        {/* <div className={classes.video}>
          <ReactPlayer
            width='100%'
            height='100%'
            style={{ position: 'absolute' }}
            playing
            muted
            loop
            url={process.env.PUBLIC_URL + '/videos/home.mp4'}
          />
          <div className={classes.overlay}></div>
        </div> */}
        <HomeCard image={imageLeft} title='' alt={'No Image available'} />
      </Grid2>
      <Grid2 xs={12} sm={6}>
        <HomeCard image={imageRight} title='' alt={'No Image available'} />
      </Grid2>
    </Grid2>
  )
}
