import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { IOrderCompleteResponse } from '../../../common/hooks/useOrder'

const useStyles = makeStyles()((theme) => {
  return {
    message: {
      fontSize: '22px',
      textAlign: 'justify',
      marginBottom: '1rem'
    },
    brand: {
      color: theme.palette.primary.main
    },
    follow: {
      fontSize: '18px'
    },
    successIcon: {
      fontSize: '72px',
      color: theme.palette.success.main,
      marginLeft: '-5px'
    },
    social: {
      color: theme.palette.primary.main,
      fontSize: '24px'
    }
  }
})

export const CartCompleted = ({ orderCode }: IOrderCompleteResponse) => {
  const { i18n, t } = useTranslation()
  const isEnglish = i18n.language === 'en'
  const { classes } = useStyles()
  return (
    <div>
      {/* success icon and order number */}
      <Stack direction={'row'} alignItems={'center'}>
        <Stack marginRight={1}>
          <CheckCircleOutlineIcon className={classes.successIcon} />
        </Stack>
        <Stack>
          <Typography variant='h6' fontWeight={400}>
            {t('labels.order')}: {orderCode}
          </Typography>
          <Typography variant='h5' fontWeight={600}>
            {t('messages.thankYou')}
          </Typography>
        </Stack>
      </Stack>
      {isEnglish ? (
        <Typography className={classes.message}>
          Thank you for choosing <span className={classes.brand}>MadooBrand</span>! Your order is
          confirmed, and we're now working to get your items to you as swiftly as possible. We
          appreciate your trust in us and can't wait for you to enjoy your new fashion finds. An
          email wil arrive sortly with all the details of this order.
        </Typography>
      ) : (
        <Typography className={classes.message}>
          Σας ευχαριστούμε που επιλέξατε την <span className={classes.brand}>MadooBrand</span>! Η
          παραγγελία σας επιβεβαιωθηκε και τώρα εργαζόμαστε για να σας παραδώσουμε τα προϊόντα σας
          το συντομότερο δυνατό. Εμείς εκτιμούμε την εμπιστοσύνη σας σε εμάς και ανυπομονούμε να
          απολαύσετε τα νέα σας ρούχα. Θα λάβετε σύντομα ένα email με όλες τις λεπτομέρειες της
          παραγγελίας.
        </Typography>
      )}
      <Typography className={classes.message}>{t('messages.followsonsocial')}</Typography>
      <div>
        <a
          href='https://www.instagram.com/madoobrand__/?igshid=MzRlODBiNWFlZA%3D%3D'
          rel='noreferrer'
          target='_blank'
          className={classes.social}
        >
          Instagram
        </a>
      </div>
      <br />
      <div>
        <a
          href='https://www.tiktok.com/@madoobrand__?_t=8fxSADGPGJk&_r=1'
          rel='noreferrer'
          target='_blank'
          className={classes.social}
        >
          TikTok
        </a>
      </div>
    </div>
  )
}
