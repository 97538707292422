import { makeStyles } from 'tss-react/mui'
import { EProductColorSize } from '../../models/EProduct'

const useStyles = makeStyles()((theme) => {
  return {
    dot: {
      height: '32px',
      borderRadius: '2px',
      border: '1px solid black',
      lineHeight: '1em',
      marginRight: '10px',
      cursor: 'pointer',
      display: 'inline-block',
      padding: '0px 5px',
      minWidth: '24px'
    },
    label: {
      position: 'relative',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      textAlign: 'center',
      lineHeight: '32px',
      zIndex: 2
    }
  }
})

interface IEProductSizeChipProps {
  selectedSize: number
  setSelectedSize: any
  sizes: EProductColorSize[]
}

export const EProductSizeChip = ({
  selectedSize,
  setSelectedSize,
  sizes
}: IEProductSizeChipProps) => {
  const { classes } = useStyles()

  return (
    <div style={{ flex: 1 }}>
      {sizes?.map((c: EProductColorSize, index: number) => (
        <div
          key={index}
          className={classes.dot}
          onClick={() => setSelectedSize(index)}
          style={{
            boxShadow: selectedSize === index ? '0px 0px 6px 1px #474242' : ''
          }}
        >
          <div className={classes.label}>{c.sizeValue}</div>
        </div>
      ))}
    </div>
  )
}
