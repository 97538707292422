import { useParams } from 'react-router-dom'
import { useEProduct } from '../useEProduct'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { EProductDetails } from './EProductDetails'
import { useState } from 'react'
import { Carousel } from '../../../../common/components/Carousel'
import { Loader } from '../../../../common/components/Loader'
import { EProductColor, EProductTable } from '../../../models/EProduct'
import { useCart } from '../../../context/CartProvider'
import ScrollToTop from '../../../../common/components/ScrollToTop'
import { useTranslation } from 'react-i18next'

export const EProductContainer = () => {
  const { addToCart } = useCart()
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const [selectedColor, setSelectedColor] = useState(0)
  const [selectedSize, setSelectedSize] = useState(0)
  const [quantity, setQuanity] = useState(1)
  const { productLoader, product } = useEProduct(id as string)

  const addProductToCart = () => {
    addToCart({
      productId: product?.id as string,
      cartId: null,
      colorId: product?.colors[selectedColor].id as string,
      color: product?.colors[selectedColor] as any,
      sizeId: product?.colors[selectedColor].sizes[selectedSize].id as string,
      size: product?.colors[selectedColor].sizes[selectedSize] as any,
      quantity: quantity,
      price: product?.colors[selectedColor].price as number,
      discount: product?.colors[selectedColor].discount as any,
      product: product as any
    })
  }

  const handleColorSelection = (colorIndex: number) => {
    setSelectedSize(0)
    setQuanity(1)
    setSelectedColor(colorIndex)
  }

  const handleSizeSelection = (sizeIndex: number) => {
    setQuanity(1)
    setSelectedSize(sizeIndex)
  }
  if (productLoader) <Loader type='circles' />

  if (product?.colors === null || product?.colors.length === 0)
    return <div>{t('labels.productWasRemoved')}</div>

  return (
    <Grid2 container spacing={2} marginBottom={5}>
      {product && <ScrollToTop />}
      <Grid2 xs={12} sm={5} md={7}>
        {product ? (
          <Carousel colors={product?.colors as EProductColor[]} selectedColor={selectedColor} />
        ) : (
          <Loader type='circles' noText />
        )}
      </Grid2>
      <Grid2 xs={12} sm={7} md={5}>
        {product && (
          <EProductDetails
            product={product as EProductTable}
            selectedColor={selectedColor}
            setSelectedColor={handleColorSelection}
            selectedSize={selectedSize}
            setSelectedSize={handleSizeSelection}
            quantity={quantity}
            setQuanity={setQuanity}
            addToCart={addProductToCart}
          />
        )}
      </Grid2>
    </Grid2>
  )
}
