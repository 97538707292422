import { useCallback, useState } from 'react'
import { ProductTableView } from '../../models/Product'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { IProductSearchOptions } from './table/ProductSearchFilter'
import { IPageState } from '../../../common/components/MiPaginatedDataGrid'

export interface IProductsTableResponse {
  items: ProductTableView[]
  totalItems: number
  totalPages: number
  currentPage: number
}
export interface IUseProducts {
  productState: IPageState<ProductTableView>
  searchProducts: (options: IProductSearchOptions) => void
  removeProduct: (id: string) => void
}

export function useProducts(): IUseProducts {
  const [productState, setProductState] = useState<IPageState<ProductTableView>>({
    data: [] as any,
    loading: false,
    page: 0,
    pageSize: 20,
    total: 0
  } as IPageState<ProductTableView>)
  const service = useBaseService('product')

  const removeProduct = (id: string) => {
    const _products = productState.data?.filter((x) => x.id !== id)
    setProductState({ ...productState, data: _products })
  }
  const searchProducts = useCallback(
    async (options: IProductSearchOptions) => {
      setProductState((prev) => {
        return { ...prev, loading: true }
      })
      const response = await service.post<IProductsTableResponse>(
        options,
        `adminproductmanagement/getProducts`
      )
      if (response) {
        setProductState({
          data: response.items,
          loading: false,
          page: response.currentPage,
          pageSize: options.pageSize,
          total: response.totalItems
        })
      } else {
        setProductState((prev) => {
          return { ...prev, loading: false }
        })
      }
    },
    [service]
  )

  return {
    productState,
    removeProduct,
    searchProducts
  }
}
