import { Navigate } from 'react-router-dom'
import { useToken } from '../app/manager/services/useToken'

interface PrivateRouteProps {
  component: React.ComponentType<any>
}

export const PrivateRoute = ({ component: Component, ...props }: PrivateRouteProps) => {
  var isTokenExpired = useToken().isTokenExpired()

  if (isTokenExpired) return <Navigate to='/login' replace />
  return <Component {...props} />
}
