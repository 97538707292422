import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  Typography
} from '@mui/material'
import { MiTableRow } from './MiTableRow'

export interface IColumnProps {
  headerName: string
  align?: 'left' | 'right' | 'center' | 'justify'
  headerAlign?: 'left' | 'right' | 'center' | 'justify'
  field: string
  width?: number
  flex?: 0 | 1
  renderCell?: (row: any, i: number) => any,
}

export interface IMiTableProps {
  columns: IColumnProps[]
  data: any[]
  options: any
  title: string
  actions: any
}

export const MiTable = ({ columns, data, options, title, actions }: IMiTableProps) => (
  <Stack>
    <Stack
      padding={1}
      direction='row'
      spacing={2}
      alignItems={'center'}
      alignContent={'space-between'}
    >
      <Stack flex={1}>
        <Typography variant='h5' component='div'>
          {title}
        </Typography>
      </Stack>
      <Stack>{actions}</Stack>
    </Stack>
    <TableContainer component={Paper}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: 10 }} width={50} component={'th'}></TableCell>
            {columns.map((column: IColumnProps, i: number) => (
              <TableCell key={i} style={{ padding: 10 }} component={'th'} align={column.align}>
                {column.headerName}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row: any, i: number) => (
            <MiTableRow key={i} row={row} columns={columns} index={i} options={options} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Stack>
)
