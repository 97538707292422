import { Chip } from '@mui/material'
import { Color } from '../../common/models/Color'

export const SelectChip = (color: Color, i18n: any) => (
  <div>
    <Chip
      size='small'
      label={'_'}
      style={{
        background: color.colorValue,
        color: color.colorValue,
        marginRight: '10px'
      }}
    />
    {i18n.language === 'en' ? color.colorNameEn : color.colorNameGr}
  </div>
)
