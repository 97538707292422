import { Card, CardContent, CardMedia, Chip, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { AdminCartItem } from '../../../../common/models/Order'
import { LabelValue } from '../../../../common/components/LabelValue'
import { useTranslation } from 'react-i18next'

interface IOrderItemProps {
  item: AdminCartItem
}

export const OrderItem = ({ item }: IOrderItemProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Card sx={{ display: 'flex', marginBottom: 1 }}>
      <CardMedia
        onClick={() => navigate(`/products/${item.sku}`)}
        component='img'
        sx={{ width: 80, objectFit: 'cover' }}
        image={item?.productImageUrl}
        alt='Sorry no image available'
      />
      <CardContent style={{ flex: 1 }}>
        <Stack flex={1} direction={'row'} justifyContent={'space-between'}>
          <Stack flex={1}>
            <Typography variant='h5'>{item.productTitle}</Typography>
            <Typography component={'span'} variant='subtitle1'>
              {item.productTypeName} - {item.sku}
            </Typography>
          </Stack>
          <Stack flex={1}>
            <LabelValue
              variant='subtitle1'
              label={t('labels.currentPrice') + ':'}
              value={`${item.productPrice} €`}
            />
            <LabelValue
              variant='subtitle1'
              label={t('labels.boughtPrice') + ':'}
              value={`${item.productPriceWhenBought} €`}
            />
            <LabelValue
              variant='subtitle1'
              label={t('labels.total') + ':'}
              value={`${item.totalPrice} €`}
            />
          </Stack>
          <Stack spacing={1}>
            <Chip size='small' variant='outlined' label={item.quantity + ' Τεμάχια'} />
            <Chip size='small' variant='outlined' label={item.sizeValue} />
            <Chip size='small' variant='outlined' label={item.colorNameGr} />
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  )
}
