import { Paper, Button, Chip, ButtonGroup, IconButton } from '@mui/material'
import { t } from 'i18next'
import { MiDatagrid } from '../../../../common/components/MiDatagrid'
import { useUsers } from '../useUsers'
import moment from 'moment'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Source } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'

export const UsersContainer = () => {
  const { loading, users } = useUsers()
  const navigate = useNavigate()

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.lastUpdate') as string,
      field: 'lastUpdated',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.lastUpdated).format('DD/MM/YYYY')
    },
    {
      headerName: t('labels.email') as string,
      field: 'email',
      flex: 1
    },
    {
      headerName: t('labels.name') as string,
      field: 'firstName',
      flex: 1
    },
    {
      headerName: t('labels.lastname') as string,
      field: 'lastName',
      flex: 1
    },
    {
      headerName: t('labels.mobile') as string,
      field: 'mobile',
      flex: 1
    },

    {
      headerName: t('labels.role') as string,
      field: 'isAdmin',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        params.row.isAdmin ? (
          <Chip variant='filled' color='error' label={t('labels.admin')} />
        ) : (
          <Chip variant='filled' label={t('labels.user')} />
        )
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton
            title='Άνοιγμα'
            color='primary'
            onClick={() => navigate(`/manager/users/${params.row.id}`)}
          >
            <Source fontSize={'small'} />
          </IconButton>
          <IconButton
            title='Άνοιγμα σε νέο παράθυρο'
            color='primary'
            onClick={() => openInNewTab(`/manager/users/${params.row.id}`)}
          >
            <OpenInNewIcon fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]

  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      <MiDatagrid
        title={t('labels.users')}
        actions={
          <Button
            color={'primary'}
            size='small'
            variant='outlined'
            onClick={() => navigate('/manager/users/new')}
          >
            {t('buttons.add')}
          </Button>
        }
        data={users}
        columns={columns}
        loading={loading}
      />
    </Paper>
  )
}
