import { makeStyles } from 'tss-react/mui'
import { Size } from '../../../../common/models/Sizes'
import { Chip } from '@mui/material'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      marginRight: '10px',
      cursor: 'pointer',
      marginBottom: '10px',
      border: '1px solid #00000047'
    }
  }
})
interface IESizessPickerFilterProps {
  selectedSizeId: string | undefined
  setSelectedSizeId: any
  sizes: Size[]
}

export const ESizesPickerFilter = ({
  selectedSizeId,
  setSelectedSizeId,
  sizes
}: IESizessPickerFilterProps) => {
  const { classes } = useStyles()
  return (
    <div style={{ marginBottom: '1em' }}>
      {sizes?.map((c: Size) => (
        <Chip
          style={{
            boxShadow: selectedSizeId === c.id ? '0px 0px 6px 1px #474242' : '',
            borderRadius: '2px'
          }}
          key={c.id}
          className={classes.root}
          label={c.sizeValue}
          variant='outlined'
          onClick={() => setSelectedSizeId(c.id)}
        />
      ))}
    </div>
  )
}
