import { useState, useEffect } from 'react'
import { useOrders } from '../useOrders'
import { IOrderSearchOptions } from './IOrderSearchOptions'
import Grid from '@mui/material/Unstable_Grid2'
import { OrdersTable } from './OrdersTable'

const defaultReq: IOrderSearchOptions = {
  currentPage: 1,
  pageSize: 200,
  mobileNumber: '',
  deliveryStatus: undefined,
  orderStatus: undefined
}

export const OrdersContainer = () => {
  const { orders, orderLoader, searchOrders } = useOrders()
  const [filters] = useState<IOrderSearchOptions>(defaultReq)

  useEffect(() => {
    const getOrders = async () => {
      await searchOrders(filters)
    }
    getOrders()
  }, [filters, searchOrders])

  return (
    <Grid container spacing={3}>
      <Grid xs={12}>{/* <ProductSearch handleEnter={handleEnter} /> */}</Grid>
      <Grid xs={12}>
        <OrdersTable data={orders.items} loading={orderLoader} />
      </Grid>
    </Grid>
  )
}
