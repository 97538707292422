import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { fieldProps } from '../../../../../utils/FormExtensions'
import { MiControlledSelectWithData } from '../../../../common/components/MiControlledSelectWithData'

export interface IUserDetailsProps {
  control: any
  errors: any
}
export const UserDetails = ({ control, errors }: IUserDetailsProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Controller
        control={control}
        defaultValue=''
        name='firstName'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.name'), ref, field)} error={!!errors.firstName} />
        )}
      />
      <Controller
        control={control}
        defaultValue=''
        name='lastName'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.lastname'), ref, field)} error={!!errors.lastName} />
        )}
      />
      <Controller
        control={control}
        defaultValue=''
        name='email'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.email'), ref, field)} error={!!errors.email} />
        )}
      />
      <Controller
        control={control}
        defaultValue=''
        name='mobile'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.mobile'), ref, field)} error={!!errors.mobile} />
        )}
      />
      <Controller
        control={control}
        defaultValue=''
        name='postalCode'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField
            {...fieldProps(t('labels.postalCode'), ref, field)}
            error={!!errors.postalCode}
          />
        )}
      />
      <Controller
        control={control}
        defaultValue=''
        name='city'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.city'), ref, field)} error={!!errors.city} />
        )}
      />
      <Controller
        control={control}
        defaultValue=''
        name='area'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.area'), ref, field)} error={!!errors.area} />
        )}
      />
       <Controller
        control={control}
        defaultValue=''
        name='address'
        rules={{ required: true }}
        render={({ field: { ref, ...field } }) => (
          <TextField {...fieldProps(t('labels.address'), ref, field)} error={!!errors.address} />
        )}
      />
      <MiControlledSelectWithData
        data={[
          { id: true, valueEn: t('labels.admin'), valueGr: t('labels.admin') },
          { id: false, valueEn: t('labels.user'), valueGr: t('labels.user') }
        ]}
        required={false}
        control={control}
        margin='normal'
        label={t('labels.role')}
        name='isAdmin'
        errors={errors}
      />
    </>
  )
}
