import { useCallback, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { Color } from '../../../common/models/Color'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export interface IUseColorProps {
  loader: boolean
  create: (color: Color) => Promise<Color>
  update: (color: Color) => Promise<Color>
}

export function useColor(): IUseColorProps {
  const [loader, setLoading] = useState(false)
  const service = useBaseService('admincolor')
  const { t } = useTranslation()
  const update = useCallback(
    async (entity: Color) => {
      setLoading(true)
      const result = await service.update<Color>(entity, entity.id)
      setLoading(false)
      enqueueSnackbar(t('messages.successSave'), {
        variant: 'success'
      })
      return result as Color
    },
    [service, t]
  )

  const create = useCallback(
    async (entity: Color) => {
      setLoading(true)
      const result = await service.create(entity)
      setLoading(false)
      enqueueSnackbar(t('messages.successSave'), {
        variant: 'success'
      })
      return result
    },
    [service, t]
  )

  return {
    loader,
    update,
    create
  }
}
