import { Divider, Stack, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const AboutUs = () => {
  const topRef = useRef<any>(null)
  const { i18n, t } = useTranslation()
  const isEnglish = i18n.language === 'en'
  useEffect(() => {
    const timeout = setTimeout(() => {
      topRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }, 0)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <Stack ref={topRef} sx={{ margin: '3rem 0 12rem' }}>
      <Typography variant='h3' color={'primary'}>
        {t('labels.aboutUs')}
      </Typography>
      <Divider style={{ margin: '1rem 0' }} />
      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        {isEnglish ? 'Welcome to' : 'Καλωσήρθατε στο'} <strong>MadooBrand</strong>{' '}
        {isEnglish
          ? `, your ultimate destination for contemporary women's
        fashion. We are more than just a clothing store; we're a celebration of individuality,
        confidence, and style. Our mission is to empower women to express their unique personalities
        through fashion while feeling comfortable and confident in what they wear.`
          : `ο τελικός σας προορισμός για τη σύγχρονη γυναικεία μόδα. Δεν είμαστε απλώς μία εταιρία ρούχων· είμαστε μια γιορτή της ατομικότητας, 
          της αυτοπεποίθησης και του στιλ. Η αποστολή μας είναι να ενδυναμώσουμε τις γυναίκες να εκφράσουν τη μοναδική προσωπικότητά τους 
          μέσα από τη μόδα, αισθανόμενες άνετα και αυτοπεποίθηση με αυτά που φορούν.`}
      </Typography>
      <Typography variant='h4' color={'primary'}>
        {t('labels.ourStory')}
      </Typography>
      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        <strong>MadooBrand</strong>{' '}
        {isEnglish
          ? `was founded by a team of passionate fashion enthusiasts who
        recognized the need for a platform that caters to every woman's fashion preferences. Our
        journey began with a simple idea: to curate a diverse collection of clothing that reflects
        the beauty and diversity of women worldwide. From casual chic to elegant evening wear, we
        strive to offer a range of styles that embrace every woman's unique taste.`
          : `ιδρύθηκε από μια ομάδα ανθρώπων που αναγνώρισαν την ανάγκη για μια πλατφόρμα 
          που εξυπηρετεί τις μόδες κάθε γυναίκας. Το ταξίδι μας ξεκίνησε με μια απλή ιδέα: να συλλέξουμε μια 
          ποικιλία ρούχων που αντικατοπτρίζει την ομορφιά και την ποικιλομορφία των γυναικών παγκοσμίως. Από το
          ανεπίσημο στυλ έως τα κομψά βραδινά ρούχα, προσπαθούμε να προσφέρουμε μια ποικιλία στυλ που αγκαλιάζει τη 
          μοναδική γεύση κάθε γυναίκας`}
      </Typography>

      <Typography variant='h4' color={'primary'}>
        {isEnglish ? 'Our Values' : 'Οι Αξίες μας'}
      </Typography>

      <ul>
        <li>
          <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
            <strong>{isEnglish ? 'Diversity and Inclusivity' : 'Πολυπολιτισμικότητα'}:</strong>{' '}
            {isEnglish
              ? `We celebrate the beauty
            of diversity in all its forms, and our clothing reflects this belief. Our collections
            are designed to suit women of all backgrounds, shapes, and sizes.`
              : `Γιορτάζουμε την ομορφιά της ποικιλομορφίας σε όλες τις μορφές της, και τα ρούχα μας
               αντικατοπτρίζουν αυτήν την πεποίθηση. Οι συλλογές μας σχεδιάζονται για να προσαρμόζονται 
               σε γυναίκες όλων των υποβάθρων, σχημάτων και μεγεθών.`}
          </Typography>
        </li>
        <li>
          <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
            <strong>{isEnglish ? 'Quality and Craftsmanship' : 'Ποιότητα και Τεχνοτροπία'}:</strong>{' '}
            {isEnglish
              ? `We're committed to delivering high-quality clothing that not only looks great but also
            stands the test of time. We work with skilled artisans and select materials that meet
            our rigorous standards.`
              : `Εχουμε δεσμευτεί να παρέχουμε υψηλής ποιότητας ρούχα που όχι μόνο φαίνονται υπέροχα αλλά και
              αντέχει στη δοκιμασία του χρόνου. Συνεργαζόμαστε με ειδικευμένους τεχνίτες και επιλέγουμε υλικά που ανταποκρίνονται
              τα αυστηρά μας πρότυπα.`}
          </Typography>
        </li>
        <li>
          <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
            <strong>
              {isEnglish ? 'Customer-Centric Approach' : 'Προσανατολισμός προς τον πελάτη'}:{' '}
            </strong>
            {isEnglish
              ? `Our customers are at the heart of everything we do. We listen to your feedback,
            suggestions, and needs to continually improve our products and services.`
              : `Οι πελάτες μας βρίσκονται στο επίκεντρο όλων όσων κάνουμε. Ακούμε τα σχόλιά σας,
              προτάσεις και τις ανάγκες σας για να βελτιώνουμε συνεχώς τα προϊόντα και τις υπηρεσίες μας.`}
          </Typography>
        </li>
        <li>
          <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
            <strong>{isEnglish ? 'Sustainability' : 'Βιωσιμότητα'}:</strong>{' '}
            {isEnglish
              ? `We're dedicated to
            reducing our environmental footprint. We're continually exploring eco-friendly materials
            and sustainable production methods to contribute to a greener future.`
              : `Είμαστε αφοσιωμένοι στο
              στη μείωση του περιβαλλοντικού μας αποτυπώματος. Εξερευνούμε συνεχώς φιλικά προς το περιβάλλον υλικά
              και βιώσιμες μεθόδους παραγωγής για να συμβάλλουμε σε ένα πιο πράσινο μέλλον.`}
          </Typography>
        </li>
      </ul>
      <Typography variant='h4' color={'primary'}>
        {isEnglish ? 'Our Collections' : 'Οι Συλλογές μας'}
      </Typography>
      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        {isEnglish
          ? ` Discover a wide range of clothing options that cater to different tastes and occasions.
        Whether you're looking for casual wear, office-appropriate attire, or show-stopping evening
        dresses, our carefully curated collections have something for everyone. We believe in
        offering fashion that helps you feel confident, no matter where life takes you.`
          : `Ανακαλύψτε μια ευρεία γκάμα επιλογών ρούχων που καλύπτουν διάφορα γούστα και περιστάσεις. 
             Είτε ψάχνετε για ρούχα ευκολοφόρετα, είτε για ενδυμασία κατάλληλη για το γραφείο, είτε για εντυπωσιακά βραδινά φορέματα, 
             οι προσεκτικά επιλεγμένες συλλογές μας έχουν κάτι για όλους. Πιστεύουμε οτι
             οι προτάσεις μας θα σας βοηθήσουν να νιώσετε αυτοπεποίθηση, ανεξάρτητα από το πού σας οδηγεί η ζωή.`}
      </Typography>

      <Typography variant='h4' color={'primary'}>
        {isEnglish ? 'Get in Touch' : 'Κρατήστε Επαφή'}
      </Typography>
      <Typography variant='body1' marginBottom={1} textAlign={'justify'}>
        {isEnglish
          ? `We'd love to hear from you! Have questions, feedback, or suggestions? Don't hesitate to
        reach out to us on Instagram or on email. Your satisfaction is our priority.`
          : `Θα θέλαμε πολύ να ακούσουμε από εσάς! Έχετε ερωτήσεις, προτάσεις ή γνώμη που θέλετε να μοιραστείτε; 
          Μην διστάσετε να επικοινωνήσετε μαζί μας στο Instagram ή μέσω email. Η ευχαρίστηση σας είναι η προτεραιότητά μας`}
      </Typography>
    </Stack>
  )
}
