import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { CartStorageName, OrderStorageName, useCart } from '../../context/CartProvider'
import { Button, Stack } from '@mui/material'
import { CartOrderSummary } from './CartOrderSummary'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'
import { makeStyles } from 'tss-react/mui'
import { CartStepper } from './CartStepper'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CartItems } from './CartItems'
import { CartCustomerInfo } from './CartCustomerInfo'
import { CartOrderInfo } from './CartOrderInfo'
import { useForm } from 'react-hook-form'
import { Order } from '../../../common/models/Order'
import { useCountries } from '../../../common/hooks/useCountries'
import { useOrder } from '../../../common/hooks/useOrder'
import { Loader } from '../../../common/components/Loader'
import { useNavigate, useSearchParams } from 'react-router-dom'

const useStyles = makeStyles()((theme) => {
  return {
    emptyState: {
      textAlign: 'center',
      padding: '2em 0',
      margin: '6em 0'
    },
    emptyStateText: {
      color: theme.palette.primary.main,
      fontSize: '32px',
      marginBottom: '0.5em'
    },
    emptyStateIcon: {
      color: theme.palette.primary.main,
      fontSize: '64px'
    }
  }
})

export const CartContainer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const { cartOrder, removeFromCart, updateQuantity, calculateTotal, clearCart } = useCart()
  const { classes } = useStyles()
  const [cartStep, setCartStep] = useState(0)
  const { countries } = useCountries()
  const { orderLoader, sendOrder, sendVivaOrder, vivaWallet } = useOrder()
  const {
    control,
    handleSubmit,
    trigger,
    watch,
    getValues,
    setValue,
    formState: { errors, isValid }
  } = useForm<Order>({
    defaultValues: {}
  })

  useEffect(() => {
    setValue('deliveryCost', calculateTotal() > 80 ? 0 : 3, {
      shouldValidate: true
    })
  }, [calculateTotal, cartOrder, setValue])

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'paymentMethodId' && type === 'change') {
        const selectedCountry = countries.find((x) => (x.id = value.countryId as string))
        if (selectedCountry) {
          const method = selectedCountry.paymentMethods.find((x) => x.id === value.paymentMethodId)
          const payOnDelivery = calculateTotal() > 80 ? 0 : method?.value ?? 0
          setValue('payOnDelivery', payOnDelivery)
        }
      }
    })
    return () => subscription.unsubscribe()
  }, [calculateTotal, countries, setValue, watch, cartOrder])

  useEffect(() => {
    async function completeOrderAfterViva() {
      const order = localStorage.getItem(CartStorageName)
      const parsedOrder = JSON.parse(order as string)
      const result = await sendVivaOrder({
        vivaWalletTransactionId: params.get('transId') as string,
        paymentCode: parsedOrder.paymentCode
      })
      clearCart()
      localStorage.setItem(OrderStorageName, result?.orderCode as string)
      navigate('completed')
    }
    if (params.get('transId')) {
      completeOrderAfterViva()
    }
  }, [clearCart, getValues, navigate, params, sendOrder, sendVivaOrder])

  const nextStep = () => {
    if (cartStep !== 1) {
      setCartStep((prev) => prev + 1)
    }
    if (cartStep === 1) {
      trigger()
      if (isValid) {
        const selectedCountry = countries.find((x) => x.id === getValues('countryId'))
        if (getValues('paymentMethodId') === null || getValues('paymentMethodId') === undefined) {
          const defaultMethod = selectedCountry?.paymentMethods[0]
          setValue('paymentMethodId', defaultMethod?.id as string)
        }
        setCartStep((prev) => prev + 1)
      }
    }
  }

  const previousStep = () => setCartStep((prev) => prev - 1)

  const handleOrder = (formData: any) => {
    const selectedCountry = countries.find((x) => x.id === getValues('countryId'))
    const paymentMethdod = selectedCountry?.paymentMethods.find(
      (x) => x.id === formData.paymentMethodId
    )
    paymentMethdod?.methodName.toString() === '3' ? payOrder(formData) : placeOrder(formData)
  }

  const payOrder = async (formData: any) => {
    const pendingOrder = {
      ...formData,
      cart: {
        cartItems: cartOrder.cart.cartItems
      }
    }
    await vivaWallet(pendingOrder)
  }

  const placeOrder = async (formData: any) => {
    const pendingOrder = {
      ...formData,
      vivaWalletTransactionId: params.get('transId'),
      cart: {
        cartItems: cartOrder.cart.cartItems
      }
    }
    const order = await sendOrder(pendingOrder)
    clearCart()
    localStorage.setItem(OrderStorageName, order?.orderCode as string)
    navigate('completed')
  }

  if (cartOrder.cart.cartItems.length === 0)
    return (
      <div className={classes.emptyState}>
        <ShoppingBasketIcon className={classes.emptyStateIcon} />
        <div className={classes.emptyStateText}>{t('labels.emptyCart')}</div>
      </div>
    )

  if (orderLoader) return <Loader type='ring' isFullscreen />
  return (
    <Grid2 container columnSpacing={10}>
      <Grid2 xs={12} sm={12}>
        <CartStepper step={cartStep} />
      </Grid2>
      <Grid2 xs={12} sm={12} md={8} order={{ xs: 2, sm: 2, md: 1 }}>
        {cartStep === 0 && (
          <CartItems
            cartItems={cartOrder.cart.cartItems}
            updateQuantity={updateQuantity}
            removeFromCart={removeFromCart}
          />
        )}
        {cartStep === 1 && (
          <CartCustomerInfo countries={countries} control={control} errors={errors} />
        )}
        {cartStep === 2 && (
          <CartOrderInfo
            watch={watch}
            control={control}
            countries={countries}
            getValues={getValues}
            setValue={setValue}
          />
        )}
        <Stack marginTop={2} direction={'row'} justifyContent={'space-between'}>
          <Stack>
            {cartStep > 0 && cartStep < 3 && (
              <Button onClick={previousStep} variant='contained'>
                {t('buttons.previous')}
              </Button>
            )}
          </Stack>
          <Stack>
            {cartStep < 2 && (
              <Button onClick={nextStep} variant='contained'>
                {t('buttons.next')}
              </Button>
            )}
          </Stack>
        </Stack>
      </Grid2>
      <Grid2 xs={12} sm={12} md={4} order={{ xs: 1, sm: 1, md: 2 }}>
        <CartOrderSummary
          watch={watch}
          getValues={getValues}
          step={cartStep}
          sendOrder={handleSubmit(handleOrder)}
        />
      </Grid2>
    </Grid2>
  )
}
