import { createTheme, darken, Theme } from '@mui/material'
import { CommonColors } from '@mui/material'
import type {} from '@mui/x-data-grid/themeAugmentation'
declare module '@mui/material/styles' {
  interface CommonColors {
    black: string
    white: string
    bluesHeavyBlue: string
    bluesLightBlue1: string
    bluesLightBlue2: string
    bluesMidBlue: string
    bluesPrimaryBlue: string
    bluesSecondaryBlue: string
    greysDarkGrey: string
    greysLightGrey: string
    greysLightGrey2: string
    greysMidGrey: string
    greysWhite: string
    inputSelectionStatesActive: string
    linksBlue: string
    redAlert: string
    statusChipsMessagesBlueNeutral: string
    statusChipsMessagesGreenSuccess: string
    statusChipsMessagesOrangeWarning: string
    statusChipsMessagesRedAlert: string
    statusChipsMessagesClient: string
    graphsBlue: string
    graphsGreen: string
    graphsBrown: string
    graphsPurple: string
    graphsOrange: string
    inputSelectionStatesError: string
  }
}
//
export const commonColors: CommonColors = {
  black: '#000000',
  white: '#ffffff',
  bluesHeavyBlue: '#2e4d6b',
  bluesLightBlue1: '#edf2f7',
  bluesLightBlue2: '#f8fafc',
  bluesMidBlue: '#bdcbd8',
  bluesPrimaryBlue: '#8d5542',
  bluesSecondaryBlue: '#2c9fb6',
  greysDarkGrey: '#4a5868',
  greysLightGrey: '#e4e8ec',
  greysLightGrey2: '#fafafa',
  greysMidGrey: '#8da2b7',
  greysWhite: '#ffffff',
  redAlert: '#c53232',
  inputSelectionStatesActive: '#eff8ff',
  linksBlue: '#0056b3',
  statusChipsMessagesBlueNeutral: '#66b1ff',
  statusChipsMessagesGreenSuccess: '#78d690',
  statusChipsMessagesOrangeWarning: '#fd9c34',
  statusChipsMessagesRedAlert: '#c53232',
  statusChipsMessagesClient: '#e0be41',
  graphsBlue: '#6099d3',
  graphsGreen: '#709d83',
  graphsBrown: '#ae7474',
  graphsPurple: '#ac75c9',
  graphsOrange: '#cf8217',
  inputSelectionStatesError: '#ffeceb'
} as const

export type CommonColorsType = keyof typeof commonColors

export const useBaseTheme = (): Theme =>
  createTheme({
    palette: {
      background: {
        default: commonColors.greysWhite
      },
      text: {
        primary: darken(commonColors.bluesPrimaryBlue, 0.5),
        secondary: commonColors.bluesSecondaryBlue
      },
      primary: {
        main: commonColors.bluesPrimaryBlue
      },
      secondary: {
        main: commonColors.bluesSecondaryBlue
      },
      common: commonColors,
      warning: {
        main: commonColors.statusChipsMessagesOrangeWarning
      },
      error: {
        main: commonColors.statusChipsMessagesRedAlert
      },
      info: {
        main: commonColors.bluesSecondaryBlue
      }
    },
    typography: {
      button: { textTransform: 'none' }
    },
    components: {
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontWeight: 500,
            color: '#607383'
          }
        }
      },
      MuiChip: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            borderRadius: '4px',
            border: '1px solid',
            lineHeight: '1em',
            ...(ownerState.variant === 'filled' &&
              ownerState.color === 'error' && {
                borderColor: '#ffa39',
                backgroundColor: '#fff1f0',
                color: '#cf1322'
              }),
            ...(ownerState.variant === 'filled' &&
              ownerState.color === 'success' && {
                borderColor: '#b7eb8f',
                backgroundColor: '#f6ffed',
                color: '#389e0d'
              }),
            ...(ownerState.variant === 'filled' &&
              ownerState.color === 'warning' && {
                borderColor: '#ffd591',
                backgroundColor: '#fff7e6',
                color: '#d46b08'
              }),
            ...(ownerState.variant === 'filled' &&
              ownerState.color === 'primary' && {
                borderColor: '#91caff',
                backgroundColor: '#e6f4ff',
                color: '#0958d9'
              }),
            ...(ownerState.variant === 'filled' &&
              ownerState.color === 'info' && {
                borderColor: '#87e8de',
                backgroundColor: '#e6fffb',
                color: '#08979c'
              })
          })
        }
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            '--unstable_DataGrid-headWeight': '600' // replace 'your desired value' with the value you want
          }
        }
      },
      MuiCard: {
        styleOverrides: { root: { borderRadius: '.5rem' } }
      },
      MuiPaper: {
        styleOverrides: {
          root: { borderRadius: '.5rem' }
        }
      }
    }
  })
