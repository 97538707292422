import { BaseEntity } from '../../manager/models/BaseEntity'

export class ProductSize {
  id: string
  sizeId: string
  sizeType: string
  sizeValue: string
  unitSystem: string
  stock: number
  unit: string

  constructor(size?: Size) {
    if (size) {
      this.sizeId = size.id
      this.sizeType = size.sizeType
      this.sizeValue = size.sizeValue
      this.unitSystem = size.unitSystem
      this.unit = size.unit
    }
  }
}

export class Size extends BaseEntity {
  sizeType: string
  sizeValue: string
  unitSystem: string
  unit: string
}
