const development = {
  apiUrl: 'https://madoobrand.com/api/',
  tokenPrefix: 'madoo_dev_'
}
const production = {
  apiUrl: 'https://madoobrand.com/api/',
  tokenPrefix: 'madoo_production_'
}

interface IUseConfig {
  config: any
  isDevelopment: boolean
}

export function useConfig(): IUseConfig {
  let config
  if (process.env.NODE_ENV === 'production') {
    config = production
  } else {
    config = development
  }

  return {
    isDevelopment: process.env.NODE_ENV === 'development',
    config
  }
}
