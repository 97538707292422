import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import CancelIcon from '@mui/icons-material/Cancel'

const useStyles = makeStyles()((theme) => {
  return {
    message: {
      fontSize: '22px',
      textAlign: 'justify',
      marginBottom: '1rem'
    },
    successIcon: {
      fontSize: '48px',
      color: theme.palette.error.main,
      marginLeft: '-5px'
    },
    social: {
      color: theme.palette.primary.main,
      fontSize: '24px'
    }
  }
})

export const CartFailed = () => {
  const { i18n, t } = useTranslation()
  const isEnglish = i18n.language === 'en'
  const { classes } = useStyles()
  return (
    <div>
      {/* success icon and order number */}
      <Stack direction={'row'} alignItems={'center'}>
        <Stack marginRight={1}>
          <CancelIcon className={classes.successIcon} />
        </Stack>
        <Stack>
          <Typography variant='h5' fontWeight={600}>
            {t('messages.somethingWentWrong')}
          </Typography>
        </Stack>
      </Stack>
      {isEnglish ? (
        <Typography className={classes.message}>
          It seems that something went wrong during your Payment. If the payment didn't go through
          please try again. If the problem persists or the payment went thourght contact us and we
          will sort it out for you.
        </Typography>
      ) : (
        <Typography className={classes.message}>
          Φαίνεται ότι κάτι πήγε στραβά κατά τη διάρκεια της πληρωμής σας. Εάν η πληρωμή δεν
          ολοκληρώθηκε, παρακαλώ δοκιμάστε ξανά. Εάν το πρόβλημα παραμένει ή χρεωθήκατε το ποσό
          επικοινωνήστε μαζί μας και θα το λύσουμε εμείς για εσάς.
        </Typography>
      )}
    </div>
  )
}
