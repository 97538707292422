import { useEffect, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { EProductTable } from '../../models/EProduct'
import { getFirstElementSafe } from '../../../../utils/calculations'

export interface IProductFilterCriteriaDto {
  colorIds: string[]
  sizeId?: string | undefined
  minPrice: number
  maxPrice: number
  currentPage: number
  changeType: 'pagination' | 'filters'
  pageSize: number
  sortBy: ProductFilterSortBy
  categoryId?: string | undefined
}
export enum ProductFilterSortBy {
  PriceLowToHigh,
  PriceHighToLow,
  MostRecent
}

export interface IProductFilterCriteriaResponse {
  totalItems: number
  totalPages: number
  scrollTo: string
  currentPage: number
  items: EProductTable[] | null
}
export interface IUseEProductsProps {
  loading: boolean
  products: IProductFilterCriteriaResponse
}

export function useEProducts(options: IProductFilterCriteriaDto): IUseEProductsProps {
  const [loading, setLoading] = useState(false)
  const [products, setProducts] = useState<IProductFilterCriteriaResponse>({
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    scrollTo: '',
    items: []
  } as IProductFilterCriteriaResponse)
  const service = useBaseService('product')

  useEffect(() => {
    async function searchProducts() {
      if (!options) return
      setLoading(true)
      const response = await service.post<IProductFilterCriteriaResponse>(options, `product`)
      if (response)
        setProducts((prev: any) => {
          return {
            ...response,
            scrollTo: getFirstElementSafe(response?.items)?.id,
            items:
              options.changeType === 'pagination'
                ? [...prev?.items, ...(response.items as any)]
                : response.items
          }
        })
      setLoading(false)
    }
    searchProducts()
  }, [options, service])

  return {
    loading,
    products
  }
}
