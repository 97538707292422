import { useCallback, useState } from 'react'
import { useBaseService } from './useBaseService'
import { Order } from '../models/Order'
import { CartStorageName } from '../../eshop/context/CartProvider'

interface IVivaWalletResponse {
  vivaCode: string
}

export interface IVivaWalletOrderDto {
  paymentCode: number
  vivaWalletTransactionId: string
}
export interface IUseOrderProps {
  orderLoader: boolean
  sendOrder: (order: Order) => Promise<IOrderCompleteResponse | null | undefined>
  sendVivaOrder: (vivaOrder: IVivaWalletOrderDto) => Promise<void | IOrderCompleteResponse | null>
  vivaWallet: any
}

export interface IOrderCompleteResponse {
  orderCode: string
}

export function useOrder(): IUseOrderProps {
  const [orderLoader, setLoading] = useState(false)
  const service = useBaseService('order')

  const sendOrder = useCallback(
    async (order: Order) => {
      setLoading(true)
      const response = await service.post<IOrderCompleteResponse>(order, 'order/sendOrder')
      setLoading(false)
      return response
    },
    [service]
  )

  const sendVivaOrder = useCallback(
    async (vivaOrder: IVivaWalletOrderDto) => {
      setLoading(true)
      const response = await service.post<IOrderCompleteResponse>(vivaOrder, 'order/sendVivaOrder')
      setLoading(false)
      return response
    },
    [service]
  )

  const vivaWallet = useCallback(
    async (order: Order) => {
      setLoading(true)
      const response = await service.post<IVivaWalletResponse>(order, 'order/vivaWallet')
      if (response) {
        order.paymentCode = response.vivaCode
        localStorage.setItem(CartStorageName, JSON.stringify(order))
        setLoading(false)
        window.location.replace(
          `https://www.vivapayments.com/web/checkout?ref=${response.vivaCode}&color=9a7149`
        )
      }
    },
    [service]
  )

  return {
    orderLoader,
    sendVivaOrder,
    vivaWallet,
    sendOrder
  }
}
