import axios from 'axios'
import { useToken } from '../../../manager/services/useToken'
import i18n from 'i18next';


// Add a request interceptor
axios.interceptors.request.use(
    config => {
        const token = useToken()
        config.headers['Accept-Language'] = i18n.language === 'gr' ? 'el' : i18n.language;
        config.headers['Authorization'] = `Bearer ${token.loadToken()}`;
        config.headers['Accept'] = `application/json`;
        return config
    },
    error => {
        Promise.reject(error)
    }
)