import React from 'react'
import ReactDOM from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import AppRouter from './app/AppRouter'
import { MaterialDesignContent, SnackbarProvider } from 'notistack'
import './styles/index.sass'
import './i18n/i18n'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import './app/common/services/interceptors/requestInterceptor'
import './app/common/services/interceptors/responseInterceptor'
import styled from '@emotion/styled'
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
  '&.notistack-MuiContent-success': {
    backgroundColor: '#4a8370',
    border: '1px solid #085d41'
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: '#970C0C',
    border: '1px solid #620a0a'
  }
}))
root.render(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <SnackbarProvider
        dense={true}
        maxSnack={3}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
        autoHideDuration={3000}
        Components={{ success: StyledMaterialDesignContent, error: StyledMaterialDesignContent }}
      >
        <AppRouter />
      </SnackbarProvider>
    </LocalizationProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
