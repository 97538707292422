import * as React from 'react'
import { Card } from '@mui/material'
import { BarChart } from '@mui/x-charts'

export default function GridDemo() {
  return (
    <Card>
      <BarChart
        sx={(theme: any) => ({
          [`.MuiBarElement-root`]: {
            fill: 'url(#gradient)', // Reference to the gradient ID
            strokeWidth: 2
          }
        })}
        slotProps={{
          bar: {
            clipPath: `inset(0px round 10px 10px 0px 0px)`
          }
        }}
        xAxis={[
          {
            categoryGapRatio: 0.6,
            tickPlacement: 'middle',
            scaleType: 'band',
            label: 'Weeks',
            data: ['5', '4', '3', '2', '1']
          } as any
        ]}
        series={[
          {
            label: 'New Users',
            data: [11, 5, 2, 8, 6]
          } as any
        ]}
        height={300}
        margin={{ left: 40, right: 30, top: 60, bottom: 60 }}
        grid={{ horizontal: true }}
      >
        <defs>
          <linearGradient id='gradient' x1='0%' y1='100%' x2='0%' y2='0%'>
            <stop offset='0%' style={{ stopColor: 'rgba(255,255,255,0)' }} />
            <stop offset='10%' style={{ stopColor: 'rgba(84,64,44,0.3)' }} />
            <stop offset='60%' style={{ stopColor: 'rgba(84,64,44,0.7)' }} />
            <stop offset='100%' style={{ stopColor: 'rgba(84,64,44,1)' }} />
          </linearGradient>
        </defs>
      </BarChart>
    </Card>
  )
}
