import { makeStyles } from 'tss-react/mui'
import { EProductColor } from '../../models/EProduct'

const useStyles = makeStyles()(() => {
  return {
    dot: {
      height: '24px',
      width: '24px',
      borderRadius: '2px',
      border: '1px solid #0000002b',
      marginRight: '10px',
      cursor: 'pointer',
      display: 'inline-block'
    },
    dotSmall: {
      height: '18px',
      width: '18px',
      borderRadius: '2px',
      border: '1px solid #0000002b',
      marginRight: '10px',
      cursor: 'pointer',
      display: 'inline-block'
    }
  }
})

interface IEProductColorChipProps {
  selectedColor: number
  setSelectedColor: any
  colors: EProductColor[]
  isMobile?: boolean
}

export const EProductColorChip = ({
  selectedColor,
  setSelectedColor,
  colors,
  isMobile
}: IEProductColorChipProps) => {
  const { classes } = useStyles()

  return (
    <div style={{ flex: 1 }}>
      {colors?.map((c: EProductColor, index: number) => (
        <div
          key={index}
          className={isMobile ? classes.dotSmall : classes.dot}
          onClick={() => setSelectedColor(index)}
          style={{
            backgroundColor: c.colorValue,
            boxShadow: selectedColor === index ? '0px 0px 6px 1px #474242' : ''
          }}
        />
      ))}
    </div>
  )
}
