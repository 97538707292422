import { useCallback, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { Size } from '../../../common/models/Sizes'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export interface IUseSizeProps {
  loader: boolean
  create: (color: Size) => Promise<Size>
  update: (color: Size) => Promise<Size>
}

export function useSize(): IUseSizeProps {
  const { t } = useTranslation()
  const [loader, setLoading] = useState(false)
  const service = useBaseService('adminsizemanagment')

  const update = useCallback(
    async (entity: Size) => {
      setLoading(true)
      const result = await service.update<Size>(entity, entity.id)
      setLoading(false)
      enqueueSnackbar(t('messages.successSave'), {
        variant: 'success'
      })
      return result as Size
    },
    [service, t]
  )

  const create = useCallback(
    async (entity: Size) => {
      setLoading(true)
      const result = await service.create(entity)
      enqueueSnackbar(t('messages.successSave'), {
        variant: 'success'
      })
      setLoading(false)
      return result
    },
    [service, t]
  )

  return {
    loader,
    update,
    create
  }
}
