import { Button } from '@mui/material'
import { MiDatagrid } from '../../../common/components/MiDatagrid'
import { useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import { Size } from '../../../common/models/Sizes'

export interface ISizeTableProps {
  columns: GridColDef[]
  setSelectedEntity: any
  loader: boolean
  data: Size[]
}

export const SizeTable = ({
  data,
  loader,
  columns,
  setSelectedEntity
}: ISizeTableProps) => {
  const { t } = useTranslation()

  return (
    <MiDatagrid
      title={t('labels.sizes')}
      actions={
        <Button
          color={'primary'}
          size='small'
          variant='outlined'
          onClick={() => setSelectedEntity(new Size())}
        >
          {t('buttons.add')}
        </Button>
      }
      data={data}
      columns={columns}
      loading={loader}
    />
  )
}
