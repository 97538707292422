import Container from '@mui/material/Container'
import { makeStyles } from 'tss-react/mui'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Instagram, Email } from '@mui/icons-material'
import { Box, Paper, Stack } from '@mui/material'
import logo from '../../../../images/fulllogo.png'
import { TikTokIcon } from '../../../common/components/TikTokIcon'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles()(() => {
  return {
    image: {
      width: '200px'
    }
  }
})
export const EShopFooter = (theme: any) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  return (
    <Paper
      component={'footer'}
      sx={{
        position: 'relative',
        bottom: 0,
        left: 0,
        width: '100%',
        paddingTop: '3em',
        paddingBottom: '.5em',
        background: (theme) => theme.palette.primary.main,
        borderTop: (theme) => `1px solid rgb(148 123 91 / 32%)`
      }}
    >
      <Container maxWidth='lg'>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={4} textAlign={'center'}>
            <img className={classes.image} src={logo} alt='Kitty Katty!' />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4' color='white' gutterBottom>
              Information
            </Typography>
            <Typography variant='h6' component={'p'} color='whitesmoke'>
              <Link style={{ textDecoration: 'none', color: 'white' }} to='shippingdelivery'>
                {t('labels.shippingDelivery')}
              </Link>
            </Typography>
            <Typography variant='h6' component={'p'} color='whitesmoke'>
              <Link style={{ textDecoration: 'none', color: 'white' }} to='returnpolicy'>
                {t('labels.returnpolicy')}
              </Link>
            </Typography>
            <Typography variant='h6' component={'p'} color='whitesmoke'>
              <Link style={{ textDecoration: 'none', color: 'white' }} to='about'>
                {t('labels.aboutUs')}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant='h4' color='white' gutterBottom>
              {t('labels.contactus')}
            </Typography>
            <Link
              to='https://www.instagram.com/madoobrand__/?igshid=MzRlODBiNWFlZA%3D%3D'
              color='white'
              style={{ textDecoration: 'none' }}
            >
              <Stack color='whitesmoke' direction={'row'} alignItems={'center'} spacing={1}>
                <Instagram htmlColor={'#fff'} style={{ fontSize: '34px' }} />
                <Typography variant='h6' color='whitesmoke'>
                  Instragram
                </Typography>
              </Stack>
            </Link>
            <Link
              to='https://www.tiktok.com/@madoobrand__?_t=8fxSADGPGJk&_r=1'
              style={{ textDecoration: 'none', color: 'white' }}
            >
              <Stack direction={'row'} alignItems={'center'} color='whitesmoke' spacing={1}>
                <TikTokIcon color='#fff' width='32px' />

                <Typography variant='h6' color='whitesmoke'>
                  ΤικΤοκ
                </Typography>
              </Stack>
            </Link>
            <Stack color='whitesmoke' direction={'row'} alignItems={'center'} spacing={1}>
              <Email htmlColor={'#fff'} style={{ fontSize: '34px' }} />
              <Typography variant='h6' color='whitesmoke'>
                sales@madoobrand.com
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Box mt={10}>
        <Typography variant='body2' color='white' align='center'>
          {'Copyright © '}
          <Link style={{ textDecoration: 'none', color: 'white' }} to='https://madoobrand.com'>
            MadooBrand
          </Link>{' '}
          {new Date().getFullYear()}
          {'.'}
        </Typography>
      </Box>
    </Paper>
  )
}
