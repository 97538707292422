const isGuid = (guid: string) => {
    const guidRegex = /^[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}$/
    return guidRegex.test(guid)
  }
  
  export interface IUseValidator {
    isGuid: (guid: string) => boolean
  }
  export const useValidator = (): IUseValidator => {
    return {
      isGuid
    }
  }
  