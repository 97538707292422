import { Typography, Divider, useMediaQuery, useTheme } from '@mui/material'
import { CartItemMobile } from './CartItemMobile'
import { useTranslation } from 'react-i18next'
import { ICartItemDto } from '../../../common/models/Order'
import { CartItemDesktop } from './CartItemDesktop'

export interface ICartItemsProps {
  cartItems: ICartItemDto[]
  removeFromCart: any
  updateQuantity: any
}

export const CartItems = ({ cartItems, removeFromCart, updateQuantity }: ICartItemsProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <>
      <Typography variant='h6' fontWeight={600} marginBottom={3}>
        {t('labels.products')}
      </Typography>
      {cartItems.map((item: any, i: number) => (
        <div key={i}>
          {i !== 0 && <Divider style={{ margin: '20px 0' }} />}
          {isMobile ? (
            <CartItemMobile
              removeFromCart={removeFromCart}
              cartItem={item}
              updateQuantity={updateQuantity}
            />
          ) : (
            <CartItemDesktop
              removeFromCart={removeFromCart}
              cartItem={item}
              updateQuantity={updateQuantity}
            />
          )}
        </div>
      ))}
    </>
  )
}
