import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { EProductCardView } from './EProductCardView'
import { IProductFilterCriteriaDto, ProductFilterSortBy, useEProducts } from '../useEProducts'
import { Loader } from '../../../../common/components/Loader'
import { EProductsFilters } from './EProductsFilters'
import { useEffect, useRef, useState } from 'react'
import { useColors } from '../../../../common/hooks/useColors'
import { useLookup } from '../../../../common/hooks/useLookup'
import { useSizes } from '../../../../common/hooks/useSizes'
import { useSearchParams } from 'react-router-dom'
import ScrollToTop from '../../../../common/components/ScrollToTop'
import { Button, LinearProgress, MenuItem, Select, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { calculatePercentage } from '../../../../../utils/calculations'
import { ETableEmptyState } from './ETableEmptyState'

const defaultOptions: IProductFilterCriteriaDto = {
  currentPage: 1,
  pageSize: 12,
  maxPrice: 300,
  changeType: 'filters',
  minPrice: 0,
  sortBy: ProductFilterSortBy.MostRecent,
  colorIds: [],
  sizeId: undefined,
  categoryId: undefined
}

const sortingFilters = [
  { label: 'labels.priceLowToHigh', value: ProductFilterSortBy.PriceLowToHigh },
  { label: 'labels.priceHighToLow', value: ProductFilterSortBy.PriceHighToLow },
  { label: 'labels.mostRecent', value: ProductFilterSortBy.MostRecent }
]

const getOptionsWithParams = (params: URLSearchParams): IProductFilterCriteriaDto => {
  const paramCategory = params.get('category')
  if (paramCategory) {
    return { ...defaultOptions, categoryId: paramCategory }
  }
  return defaultOptions
}

export const EProductsContainer = () => {
  const productRef = useRef<any>(null)
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const [filters, setFilters] = useState<IProductFilterCriteriaDto>(
    getOptionsWithParams(searchParams)
  )
  const { loading, products } = useEProducts(filters)

  const { colors } = useColors()
  const { sizes } = useSizes()
  const { lookup } = useLookup('lookup', 'productType')

  const onChangeFilters = (filters: IProductFilterCriteriaDto) => {
    if (filters == null) setFilters(defaultOptions)
    else {
      filters.changeType = 'filters'
      filters.currentPage = 1
      setFilters(filters)
    }
  }

  const sortProducts = (sortOption: ProductFilterSortBy) => {
    setFilters({ ...filters, changeType: 'filters', currentPage: 1, sortBy: sortOption })
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (productRef.current) {
        productRef.current.scrollIntoView()
      }
    }, 200)
    return () => clearTimeout(timeout)
  }, [products])

  const loadMore = () => {
    setFilters((prev) => {
      return { ...prev, changeType: 'pagination', currentPage: prev.currentPage + 1 }
    })
  }

  if (loading) return <Loader isFullscreen noText type='ring' />
  return (
    <Grid2 container spacing={2}>
      <ScrollToTop />
      <Grid2 xs={6} sm={6} md={3}>
        <EProductsFilters
          colors={colors}
          sizes={sizes}
          categories={lookup}
          filters={filters}
          onChangeFilters={onChangeFilters}
        />
      </Grid2>

      <Grid2 display={{ md: 'none' }} textAlign={'right'} xs={6} sm={6}>
        <Select
          size='small'
          style={{ fontSize: '1.1rem' }}
          defaultValue={filters.sortBy}
          onChange={(e) => sortProducts(e.target.value as any)}
        >
          {sortingFilters.map((l, index) => (
            <MenuItem style={{ fontSize: '1.1rem' }} key={index} value={l.value}>
              {t(l.label)}
            </MenuItem>
          ))}
        </Select>
      </Grid2>
      <Grid2 container xs={12} sm={12} md={9} spacing={2}>
        <Grid2 display={{ xs: 'none', sm: 'none', md: 'inline-flex' }} xs={12}>
          <Select
            size='small'
            style={{ fontSize: '1.1rem' }}
            defaultValue={filters.sortBy}
            onChange={(e) => sortProducts(e.target.value as any)}
          >
            {sortingFilters.map((l, index) => (
              <MenuItem style={{ fontSize: '1.1rem' }} key={index} value={l.value}>
                {t(l.label)}
              </MenuItem>
            ))}
          </Select>
        </Grid2>
        <Grid2 xs={12} container gap={0}>
          {products?.items?.length === 0 ? (
            <Grid2 xs={12}>
              <ETableEmptyState />
            </Grid2>
          ) : (
            products?.items?.map((p, i) => (
              <Grid2
                ref={products.scrollTo === p.id ? productRef : null}
                key={i}
                xs={6}
                sm={6}
                md={4}
              >
                <EProductCardView product={p} />
              </Grid2>
            ))
          )}
        </Grid2>
        <Stack alignItems={'center'} flex={1} marginTop={2} spacing={2}>
          <Typography variant='body1' color={'black'}>
            {t('messages.paginationInfo', {
              showing: products?.items?.length,
              total: products?.totalItems
            })}
          </Typography>
          <LinearProgress
            variant='determinate'
            value={calculatePercentage(products?.items?.length, products.totalItems)}
            sx={{ width: '260px', height: '5px' }}
          />
          {products?.items?.length !== products?.totalItems && (
            <Button variant='contained' style={{ width: '200px' }} onClick={loadMore}>
              {t('buttons.loadMore')}
            </Button>
          )}
        </Stack>
      </Grid2>
    </Grid2>
  )
}
