import { Card, StepConnector, stepConnectorClasses, styled } from '@mui/material'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import PendingIcon from '@mui/icons-material/Pending'
import WhereToVoteIcon from '@mui/icons-material/WhereToVote'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import WrongLocationIcon from '@mui/icons-material/WrongLocation'
import { StepIconProps } from '@mui/material/StepIcon'
import { useTranslation } from 'react-i18next'
import { OrderStatus } from '../../../../common/models/Order'

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main
    }
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: theme.palette.primary.main
    }
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1
  }
}))

function ColorlibStepIcon(props: StepIconProps, step: number, status: number) {
  const { active, completed, className } = props

  const icons: { [index: string]: React.ReactElement } = {
    1: <PendingIcon />,
    2: <LocalShippingIcon />,
    3: <WhereToVoteIcon />
  }

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {status === 0 && step === 0 ? <WrongLocationIcon /> : icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  )
}
const steps = ['labels.pending', 'labels.pendingDelivery', 'labels.completed']
const ColorlibStepIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean }
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '5%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0px 1px 12px 1px ${theme.palette.primary.light}`
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main
  })
}))

export interface ICartStepperProps {
  status: OrderStatus
}

export const OrderStatusTracker = ({ status }: ICartStepperProps) => {
  const { t } = useTranslation()
  const _step = status === 0 ? 0 : status - 1
  return (
    <Card>
      <Stepper
        style={{ width: '100%', margin: '2em 0' }}
        alternativeLabel
        activeStep={_step}
        connector={<ColorlibConnector />}
      >
        {steps.map((label, index) => {
          const labelProps: {
            optional?: React.ReactNode
            error?: boolean
          } = {}
          if (status === 0 && index === 0) {
            // labelProps.optional = (
            //   <Typography variant='caption' color='error'>
            //     Alert message
            //   </Typography>
            // )
            labelProps.error = true
          }

          return (
            <Step key={label}>
              <StepLabel
                StepIconComponent={(props) => ColorlibStepIcon(props, index, status)}
                {...labelProps}
              >
                {status === 0 && index === 0 ? t('labels.cancelled') : t(label)}
              </StepLabel>
            </Step>
          )
        })}
      </Stepper>
    </Card>
  )
}
