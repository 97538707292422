import jwtDecode from 'jwt-decode'
import { useConfig } from './useConfig'

export interface IUserInfo {
  given_name: string
  email: string
  isAdmin: string
  exp: number
  Id: string
  aud: string
  jti: string
  iss: string
  nbf: number
}

export interface IUseToken {
  saveToken: (token: string, rememberMe?: boolean) => boolean
  rememberToken: (rememberMe?: boolean) => void
  loadToken: () => string | null
  removeToken: () => void
  isTokenExpired: () => boolean
  getUserInfo: () => IUserInfo | null
}

export function useToken(): IUseToken {
  const { config } = useConfig()

  const saveToken = (token: string, rememberMe: boolean = false) => {
    if (token == null || isTokenExpired(token)) return false
    if (rememberMe === true) {
      localStorage.setItem(`${config.tokenPrefix}token`, token)
    } else {
      sessionStorage.setItem(`${config.tokenPrefix}token`, token)
    }
    rememberToken(rememberMe)

    return true
  }

  const rememberToken = (rememberMe: boolean = false) => {
    localStorage.setItem(`${config.tokenPrefix}rememberMe`, `${rememberMe === true}`)
  }

  const loadToken = () => {
    const rememberMe = localStorage.getItem(`${config.tokenPrefix}rememberMe`)
    if (rememberMe === 'true') {
      return localStorage.getItem(`${config.tokenPrefix}token`)
    }
    return sessionStorage.getItem(`${config.tokenPrefix}token`)
  }

  const removeToken = () => {
    localStorage.removeItem(`${config.tokenPrefix}token`)
    sessionStorage.removeItem(`${config.tokenPrefix}token`)
  }

  const isTokenExpired = (token?: string) => {
    const _token = token ? token : loadToken()
    if (_token == null) return true
    const decodedToken = jwtDecode(_token as string) as any
    const currentTime = Date.now() / 1000
    return decodedToken.exp < currentTime
  }

  const getUserInfo = () => {
    const token = loadToken()
    if (token == null) return null
    const decodedToken = jwtDecode(token as string) as IUserInfo
    return decodedToken
  }

  return {
    saveToken,
    loadToken,
    rememberToken,
    removeToken,
    isTokenExpired,
    getUserInfo
  }
}
