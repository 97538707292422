import { Stack, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { EPriceDiscountChip } from './table/EPriceDiscountChip'

const useStyles = makeStyles()(() => {
  return {
    price: {
      marginBottom: 2,
      fontWeight: 600
    },
    oldPrice: {
      textDecoration: 'line-through',
      marginLeft: 20,
      fontWeight: 200,
      color: '#6b6b6b'
    }
  }
})

export interface IEPriceViewProps {
  price: number
  showDiscountTag?: boolean
  discount: number
  marginBottom?: number
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'subtitle1' | 'subtitle2' | 'body1'
}
export const EPriceView = ({
  price,
  discount,
  variant = 'h6',
  marginBottom = 1,
  showDiscountTag = true
}: IEPriceViewProps) => {
  const { classes } = useStyles()
  const priceClass = discount !== 0 ? classes.oldPrice : classes.price
  return (
    <Stack direction={'row'} alignItems={'center'} marginBottom={marginBottom}>
      {discount !== 0 && (
        <>
          {showDiscountTag && (
            <EPriceDiscountChip
              margin='0 10px 0 0'
              defaultPosition
              price={price}
              discount={discount}
            />
          )}
          <Typography variant={variant} className={classes.price}>
            {(price - discount).toFixed(2)} €
          </Typography>
        </>
      )}
      <Typography variant={variant} className={priceClass}>
        {price.toFixed(2)} €
      </Typography>
    </Stack>
  )
}
