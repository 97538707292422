import { Divider, Typography } from '@mui/material'
import { AdminOrderDetailed } from '../../../../common/models/Order'
import { LabelValue } from '../../../../common/components/LabelValue'
import { useTranslation } from 'react-i18next'

export interface IOrderCustomerInfoProps {
  order: AdminOrderDetailed
}

export const OrderCustomerInfo = ({ order }: IOrderCustomerInfoProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Typography variant={'h6'} color={(theme) => theme.palette.primary.dark}>
        {t('labels.customerInfo')}
      </Typography>
      <Divider style={{ margin: '.5em 0' }} />

      <LabelValue
        variant='body2'
        label={t('labels.name')}
        value={`${order.lastName} ${order.firstName}`}
      />
      <LabelValue variant='body2' label={t('labels.email')} value={order.email} />
      <LabelValue variant='body2' label={t('labels.mobile')} value={order.mobileNumber} />

      <LabelValue variant='body2' label={t('labels.city')} value={order.city} />
      <LabelValue variant='body2' label={t('labels.country')} value={order.countryName} />
      <LabelValue
        variant='body2'
        label={t('labels.address')}
        value={`${order.address} - ${order.postalCode}`}
      />
    </>
  )
}
