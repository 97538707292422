import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Switch,
  Typography
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Loader } from '../../../common/components/Loader'
import { fieldProps } from '../../../../utils/FormExtensions'
import { useCoupon } from '../../../common/hooks/useCoupon'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import { DiscountCouponDto } from '../../../common/models/DiscountCoupon'

export interface ICouponModalProps {
  entity: DiscountCouponDto
  onConfirm: any
  onCancel: any
}

export const CouponModal = ({ onCancel, onConfirm, entity }: ICouponModalProps) => {
  const { t } = useTranslation()
  const title = entity?.id ? 'editRecord' : 'newRecord'

  const { create, loader, update } = useCoupon() as any
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<DiscountCouponDto>({
    defaultValues: entity
  })

  const submitForm = async (data: DiscountCouponDto) => {
    let result = null
    if (entity.id) {
      result = await update(data)
    } else {
      result = await create(data)
    }
    onConfirm(result)
  }

  return (
    <Dialog open={true} fullWidth>
      <DialogTitle>{t('labels.' + title)}</DialogTitle>
      <DialogContent>
        {loader && <Loader type='circles' />}
        {!loader && (
          <>
            <Controller
              control={control}
              defaultValue=''
              name='code'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.couponCode'), ref, field)}
                  error={!!errors.code}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={0}
              name='discountPercentage'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.couponDiscountPercentage'), ref, field)}
                  error={!!errors.discountPercentage}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={0}
              name='useLimit'
              rules={{ required: true }}
              render={({ field: { ref, ...field } }) => (
                <TextField
                  {...fieldProps(t('labels.useLimit'), ref, field)}
                  error={!!errors.useLimit}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={undefined}
              rules={{ required: true }}
              name='expiryDate'
              render={({ field: { onChange, value } }) => (
                <DatePicker
                  label={t('labels.expiryDate')}
                  value={dayjs(value)}
                  format={'DD/MM/YYYY'}
                  onChange={(newValue: any) => {
                    onChange(
                      newValue.$d instanceof Date && !isNaN(newValue.$d) ? newValue.$d : null
                    )
                  }}
                  slotProps={{
                    textField: {
                      margin: 'normal',
                      size: 'small',
                      fullWidth: true,
                      error: !!errors.expiryDate
                    }
                  }}
                />
              )}
            />
            <Controller
              control={control}
              defaultValue={false}
              name='isEnabled'
              render={({ field: { ref, onChange, value } }) => (
                <Stack flex={1} direction='row' spacing={1} alignItems='center'>
                  <Switch
                    checked={value}
                    onChange={onChange}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                  <Typography>{t('labels.status')}</Typography>
                </Stack>
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>{t('buttons.cancel')}</Button>
        {!loader && (
          <Button color='success' onClick={handleSubmit(submitForm)} autoFocus>
            {t('buttons.insert')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
