import { Divider, Typography } from '@mui/material'
import { AdminOrderDetailed } from '../../../../common/models/Order'
import { t } from 'i18next'
import { LabelValue } from '../../../../common/components/LabelValue'

interface IOrderSummaryProps {
  order: AdminOrderDetailed
}

export const OrderSummary = ({ order }: IOrderSummaryProps) => {
  return (
    <>
      <Typography variant={'h6'} color={(theme) => theme.palette.primary.dark}>
        {t('labels.paymentDetails')}
      </Typography>
      <Divider style={{ margin: '.5em 0' }} />
      <LabelValue
        variant='body2'
        label={t('labels.payOnDelivery')}
        value={order.payOnDeliveryCost + ' €'}
      />
      <LabelValue
        variant='body2'
        label={t('labels.deliveryCost')}
        value={order.deliveryCost + ' €'}
      />
      {order?.discountCostPercent && order.discountCostPercent > 0 ? (
        <LabelValue
          variant='body2'
          label={t('labels.couponCode')}
          value={order.discountCostPercent + ' %'}
        />
      ) : null}
      {order?.discountCostFiat && order.discountCostFiat > 0 ? (
        <LabelValue
          variant='body2'
          label={t('labels.discountValue')}
          value={order.discountCostFiat + ' €'}
        />
      ) : null}
      <LabelValue
        variant='body2'
        label={t('labels.subtotal')}
        value={order.cart.totalCost + ' €'}
      />
      <LabelValue variant='body2' label={t('labels.total')} value={order.orderTotalCost + ' €'} />
    </>
  )
}
