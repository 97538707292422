import {
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography
} from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Order } from '../../../common/models/Order'
import { Country } from '../../../common/models/Country'
import { CouponDiscountField } from './CouponDiscountField'

export interface ICartOrderInfoProps {
  control: Control<Order, any>
  countries: Country[]
  watch: any
  getValues: any
  setValue: any
}

const banksInfo = [
  { iban: 'GR5101100460000004600673265', bankName: 'banks.nbg' },
  { iban: 'GR7101716320006632164657181', bankName: 'banks.peiraios' }
]

const showPaymentValue = (payment: any, language: string) => {
  let title = `${language === 'en' ? payment.titleEn : payment.titleGr}`
  if (payment.value) {
    title += ` (+${payment.value} €)`
  }
  return title
}
export const CartOrderInfo = ({
  control,
  countries,
  watch,
  getValues,
  setValue
}: ICartOrderInfoProps) => {
  const { t, i18n } = useTranslation()
  const selectedCountry = countries.find((x) => x.id === getValues('countryId'))
  watch('paymentMethodId')
  const paymentMethod = selectedCountry?.paymentMethods.find(
    (x) => x.id === getValues('paymentMethodId')
  )

  return (
    <>
      <Typography variant='h6' fontWeight={600} marginBottom={3}>
        {t('labels.orderDetails')}
      </Typography>
      <Card>
        <CardContent>
          <Typography variant='body1' fontWeight={600}>
            {t('labels.shippingMethod')}
          </Typography>
          <Typography variant='body2' marginBottom={2}>
            {'Acs Courier'} ({t('messages.courierMessage')})
          </Typography>
          <Typography variant='body1' fontWeight={600}>
            {t('labels.paymentMethod')}
          </Typography>
          <Controller
            name='paymentMethodId'
            control={control}
            defaultValue=''
            rules={{ required: 'This field is required' }}
            render={({ field }) => (
              <RadioGroup aria-label='gender' {...field}>
                {selectedCountry?.paymentMethods.map((p) => (
                  <FormControlLabel
                    key={p.id}
                    value={p.id}
                    control={<Radio />}
                    label={showPaymentValue(p, i18n.language)}
                  />
                ))}
              </RadioGroup>
            )}
          />
          {paymentMethod?.id === '67a7cc66-865a-467b-a46f-58d57fb2c1a5' && (
            <>
              <Typography variant='body1' fontWeight={600} marginTop={2} marginBottom={2}>
                {t('labels.cartPaymentDetails')}
              </Typography>
              <Typography variant='body2' marginBottom={2}>
                {t('labels.beneficiary')}: ΠΕΡΠΕΡΙΔΟΥ ΜΑΡΙΑ ΚΩΝΣΤΑΝΤΙΝΟΣ
              </Typography>
              {banksInfo.map((x) => (
                <div style={{ marginBottom: '10px' }}>
                  <div>{t(x.bankName)}</div>
                  <div>{x.iban}</div>
                </div>
              ))}
            </>
          )}
          {/* <Typography variant='body1' fontWeight={600} marginTop={2}>
            {t('labels.discountCoupon')}
          </Typography>

          <CouponDiscountField setValue={setValue} /> */}

          <Typography variant='body1' fontWeight={600} marginTop={2}>
            {t('labels.comments')}
          </Typography>
          <Controller
            control={control}
            defaultValue={''}
            name={'comments'}
            render={({ field: { ref, value, ...field } }) => (
              <TextareaAutosize
                ref={ref}
                value={value as string}
                {...field}
                minRows={5}
                maxRows={5}
                style={{
                  width: 'calc(100% - 10px)',
                  resize: 'none',
                  borderRadius: '4px',
                  borderColor: '#c4c4c4',
                  padding: '5px',
                  fontSize: '14px',
                  marginBottom: '10px'
                }}
              />
            )}
          />
        </CardContent>
      </Card>
    </>
  )
}
