import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { PrivateRoute } from '../utils/PrivateRoute'
import { ManagerLoginContainer } from './manager/views/layout/ManagerLogin'
import { ManagerLogout } from './manager/views/layout/ManagerLogout'
import NotFound from './NotFound'
import { ProductContainer } from './manager/views/product/details/ProductContainer'
import { SizeContainer } from './manager/views/sizes/SizeContainer'
import { EProductsContainer } from './eshop/views/products/table/EProductsContainer'
import { EProductContainer } from './eshop/views/products/details/EProductContainer'
import { ProductTableContainer } from './manager/views/product/table/ProductTableContainer'
import { AboutUs } from './eshop/views/infromation/AboutUs'
import { ReturnPolicy } from './eshop/views/infromation/ReturnPolicy'
import { ShippingDelivery } from './eshop/views/infromation/ShippingDelivery'
import { ColorContainer } from './manager/views/color/ColorsContainer'
import { CartContainer } from './eshop/views/cart/CartContainer'
import { EShopLayout } from './eshop/views/layout/EShopLayout'
import { Home } from './eshop/views/home/Home'
import { UserContainer } from './manager/views/users/details/UserContainer'
import { UsersContainer } from './manager/views/users/table/UsersContainer'
import { CustomersContainer } from './manager/views/customers/table/CustomerContainer'
import { CustomerContainer } from './manager/views/customers/details/CustomerContainer'
import { OrdersContainer } from './manager/views/orders/table/OrdersContainer'
import { OrderContainer } from './manager/views/orders/details/OrderContainer'
import { CardCompletedContainer } from './eshop/views/cart/CardCompletedContainer'
import { CartFailed } from './eshop/views/cart/CartFailed'
import { CouponContainer } from './manager/views/coupons/CouponsContainer' 
import { DashboardContainer } from './manager/views/dashboard/DashboardContainer'

const ManagerLayout = lazy(() => import('./manager/views/layout/ManagerLayout'))

const AppRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<EShopLayout />}>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<AboutUs />} />
          <Route path='shippingdelivery' element={<ShippingDelivery />} />
          <Route path='returnpolicy' element={<ReturnPolicy />} />
          <Route path='products' element={<EProductsContainer />} />
          <Route path='products/:id' element={<EProductContainer />} />
          <Route path='products/cart' element={<CartContainer />} />
          <Route path='products/cart/failed' element={<CartFailed />} />
          <Route path='products/cart/completed' element={<CardCompletedContainer />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        <Route
          path='/manager'
          element={
            <Suspense fallback={<>...</>}>
              <PrivateRoute component={ManagerLayout} />
            </Suspense>
          }
        >
          <Route path='/manager' element={<DashboardContainer />} />
          <Route path='products/:id' element={<ProductContainer />} />
          <Route path='products' element={<ProductTableContainer />} />
          <Route path='sizes' element={<SizeContainer />} />
          <Route path='coupons' element={<CouponContainer />} />
          <Route path='colors' element={<ColorContainer />} />
          <Route path='users/:id' element={<UserContainer />} />
          <Route path='users' element={<UsersContainer />} />
          <Route path='customers/:id' element={<CustomerContainer />} />
          <Route path='customers' element={<CustomersContainer />} />
          <Route path='orders' element={<OrdersContainer />} />
          <Route path='orders/:id' element={<OrderContainer />} />
        </Route>
        <Route path='/login' element={<ManagerLoginContainer />} />
        <Route path='/logout' element={<ManagerLogout />} />
      </Routes>
    </BrowserRouter>
  )
}

export default AppRouter
