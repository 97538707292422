import { useCallback, useState } from 'react'
import { useBaseService } from './useBaseService'
import { DiscountCouponDto } from '../models/DiscountCoupon'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export interface IUseCouponProps {
  loader: boolean
  create: (coupon: DiscountCouponDto) => Promise<DiscountCouponDto>
  update: (coupon: DiscountCouponDto) => Promise<DiscountCouponDto>
}

export function useCoupon(): IUseCouponProps {
  const { t } = useTranslation()
  const [loader, setLoading] = useState(false)
  const service = useBaseService('admincoupon')

  const update = useCallback(
    async (entity: DiscountCouponDto) => {
      setLoading(true)
      const result = await service.update<DiscountCouponDto>(entity, entity.id)
      setLoading(false)
      enqueueSnackbar(t('messages.successSave'), {
        variant: 'success'
      })
      return result as DiscountCouponDto
    },
    [service, t]
  )

  const create = useCallback(
    async (entity: DiscountCouponDto) => {
      setLoading(true)
      const result = await service.create(entity)
      if (result)
        enqueueSnackbar(t('messages.successSave'), {
          variant: 'success'
        })
      setLoading(false)
      return result
    },
    [service, t]
  )

  return {
    loader,
    update,
    create
  }
}
