import { Card, CardContent, Divider, Typography } from '@mui/material'
import { AdminOrderDetailed } from '../../../../common/models/Order'
import { t } from 'i18next'
import { LabelValue } from '../../../../common/components/LabelValue'
import Grid2 from '@mui/material/Unstable_Grid2'
import { OrderCustomerInfo } from './OrderCustomerInfo'
import { OrderSummary } from './OrderSummary'
export interface IOrderDetailsProps {
  order: AdminOrderDetailed
}
export const OrderDetails = ({ order }: IOrderDetailsProps) => {
  return (
    <Card>
      <CardContent>
        <Grid2 container spacing={2}>
          <Grid2 xs={12}>
            <OrderSummary order={order} />
          </Grid2>
          <Grid2 xs={12}>
            <OrderCustomerInfo order={order} />
          </Grid2>
          <Grid2 xs={12}>
            <Typography variant={'h6'} color={(theme) => theme.palette.primary.dark}>
              {t('labels.orderDetails')}
            </Typography>
            <Divider style={{ margin: '.5em 0' }} />
            <LabelValue
              variant='body2'
              label={t('labels.paymentMethod')}
              value={order.paymentMethodName}
            />
            <LabelValue
              variant='body2'
              label={t('labels.deliveryMethod')}
              value={order.deliveryMethod}
            />
            <LabelValue
              variant='body2'
              noWrap={false}
              label={t('labels.comments')}
              value={!!order.comments ? order.comments : '-'}
            />
          </Grid2>
        </Grid2>
      </CardContent>
    </Card>
  )
}
