import { Divider, Stack, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

export const ShippingDelivery = () => {
  const topRef = useRef<any>(null)
  const { i18n, t } = useTranslation()

  useEffect(() => {
    const timeout = setTimeout(() => {
      topRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }, 0)
    return () => clearTimeout(timeout)
  }, [])
  return (
    <Stack ref={topRef} sx={{ margin: '3rem 0 12rem' }}>
      <Typography variant='h3' color={'primary'}>
        {t('labels.shippingDelivery')}
      </Typography>
      <Divider style={{ margin: '1rem 0' }} />

      <Typography variant='body1' marginBottom={1}>
        {i18n.language === 'en'
          ? 'The shipping is been forwarded by Acs Courier (1-3 days depending your location)'
          : 'Η παράδοση εκτελείτε απο την Acs Courier (1-3 μέρες ανάλογα με την τοποθεσία)'}
        .
      </Typography>
      <Typography variant='body1' marginBottom={1}>
        {i18n.language === 'en'
          ? `Currently we are shipping only to greece and the shipping cost is free for orders above 80 €  and 3 € (+1,90 € for pay on delivery) for
        orders below 80 € .`
          : 'Προς το παρόν εξυπηρετούμε παραδόσεις μόνο στην Ελλάδα και το κόστος για τα μεταφορικά ειναι δωρεάν για αγορές άνω των 80 € και 3 € (+1,90 € έξοδα αντικαταβολής) για αγορές κάτω των 80 €'}
      </Typography>
      <Typography variant='body1'>
        {i18n.language === 'en'
          ? 'For Athens the delivery can be made within the same day if you contact us on'
          : 'Η παράδοση εντός Αθήνα μπορεί να πραγματοποιηθεί και αυθημερόν κατόπιν συννενόησης στο'}
        <a
          href='https://www.instagram.com/madoobrand__/?igshid=MzRlODBiNWFlZA%3D%3D'
          style={{ marginLeft: '5px' }}
          target='_blank'
          rel='noreferrer'
        >
          Instagram
        </a>
        .
      </Typography>
      <p></p>
      <p></p>
    </Stack>
  )
}
