import { Add, Remove } from '@mui/icons-material'
import { ButtonGroup, Chip, IconButton } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

export interface IAddRemoveButtonProps {
  quantity: number
  size?: 'small' | 'medium'
  maxQuantity: number
  setNewQuantity: (quantity: number) => {}
}

const useStyles = (props: any) =>
  makeStyles()(() => {
    return {
      group: {
        alignItems: 'center',
        width: props.size === 'small' ? '100px' : '120px',
        textAlign: 'center',
        background: 'rgba(0, 0, 0, 0.08)'
      }
    }
  })

export const AddRemoveButton = ({
  quantity,
  maxQuantity,
  size = 'medium',
  setNewQuantity
}: IAddRemoveButtonProps) => {
  const { classes } = useStyles({ size })()
  const add = () => {
    if (quantity === maxQuantity) return
    setNewQuantity(quantity + 1)
  }

  const remove = () => {
    if (quantity === 1) return
    setNewQuantity(quantity - 1)
  }
  return (
    <ButtonGroup className={classes.group}>
      <IconButton size={size} aria-label='fingerprint' color='secondary' onClick={remove}>
        <Remove color='secondary' />
      </IconButton>
      <Chip size={size} label={quantity} style={{ background: 'unset' }} />
      <IconButton size={size} aria-label='fingerprint' color='success' onClick={add}>
        <Add color='secondary' />
      </IconButton>
    </ButtonGroup>
  )
}
