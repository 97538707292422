import { Stack, Typography } from '@mui/material'
import AppRegistrationIcon from '@mui/icons-material/AppRegistration'

export interface IPaperHeaderProps {
  label: string
  marginBottom?: number
  padding?: number
  rightChild?: any
  rightChildSpacing?: number
}

export const PaperHeader = ({
  label,
  marginBottom = 2,
  padding,
  rightChild,
  rightChildSpacing = 0
}: IPaperHeaderProps) => {
  return (
    <Stack
      direction='row'
      spacing={2}
      padding={padding}
      alignItems={'center'}
      alignContent={'space-between'}
      marginBottom={marginBottom}
    >
      <Stack flex={1} direction='row' alignItems='center'>
        <Stack>
          <AppRegistrationIcon color='primary' fontSize={'large'} />
        </Stack>
        <Stack>
          <Typography variant='h5' fontWeight={600}>
            {label}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        flex={1}
        direction='row'
        justifyContent='flex-end'
        alignItems='center'
        spacing={rightChildSpacing}
      >
        {rightChild}
      </Stack>
    </Stack>
  )
}
