import { useCallback, useState } from 'react'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import { LoadingButton } from '@mui/lab'
import { BaseResult } from '../../../models/BaseResult'
import { useConfig } from '../../../services/useConfig'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export interface IProductImageUploadProps {
  productId: string | undefined
  productColorId: string | undefined
}

export const ProductImageUpload = ({ productId, productColorId }: IProductImageUploadProps) => {
  const { config } = useConfig()
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const handleFileChange = (event: any) => {
    handleUpload(event.target.files[0])
  }
  const handleUpload = async (selectedFile: any) => {
    setLoading(true)
    await uploadImage(selectedFile as any as Blob)
    setLoading(false)
  }

  const uploadImage = useCallback(
    async (image: Blob) => {
      if (!image) return
      const formData = new FormData()
      formData.append('file', image)
      formData.append('productId', productId as string)
      formData.append('productColorId', productColorId as string)

      try {
        await axios.post<BaseResult<any>>(`${config.apiUrl}adminphoto/add-productPhoto`, formData)
        enqueueSnackbar(t('messages.successPhoto'), {
          variant: 'success'
        })
      } catch (error: any) {
        const errorMsg = JSON.parse(error.response.data.errors[0]).errors[""][0]
        enqueueSnackbar(errorMsg, {
          variant: 'error'
        })
      }
    },
    [config.apiUrl, productColorId, productId, t]
  )
  return (
    <LoadingButton
      fullWidth
      style={{ border: 'none' }}
      loading={loading}
      disabled={!productId || !productColorId}
      component='label'
      color='primary'
      variant='outlined'
    >
      <DriveFolderUploadIcon />
      <input type='file' hidden onChange={handleFileChange} />
    </LoadingButton>
  )
}
