import { useTranslation } from 'react-i18next'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { Chip, Paper } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { ProductTableView } from '../../../models/Product'
import { Button, ButtonGroup, IconButton } from '@mui/material'
import { Source } from '@mui/icons-material'
import { IPageState, MiPaginatedDataGrid } from '../../../../common/components/MiPaginatedDataGrid'

export interface IProductTableProps {
  pageState: IPageState<ProductTableView>
  onPaginationModelChange: any
}

export const ProductTable = ({ pageState, onPaginationModelChange }: IProductTableProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.lastUpdate') as string,
      field: 'lastUpdated',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.lastUpdated).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.sku') as string,
      field: 'sku',
      flex: 1
    },
    {
      headerName: t('labels.productType') as string,
      field: 'productTypeId',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return i18n.language === 'en' ? params.row.productTypeNameEn : params.row.productTypeNameGr
      }
    },
    {
      headerName: t('labels.name') as string,
      field: 'titleGr',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return i18n.language === 'en' ? params.row.titleEn : params.row.titleGr
      }
    },
    {
      headerName: t('labels.eshop') as string,
      field: 'visibleToEshop',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        return params.row.visibleToEshop ? (
          <Chip size='small' label='Ενεργό' color='success' />
        ) : (
          <Chip size='small' label='Ανενεργό' color='error' />
        )
      }
    },
    {
      headerName: t('labels.actions') as string,
      field: 'actions',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 90,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton
            title='Άνοιγμα'
            color='primary'
            onClick={() => navigate(`/manager/products/${params.row.id}`)}
          >
            <Source fontSize={'small'} />
          </IconButton>
          <IconButton
            title='Άνοιγμα σε νέο παράθυρο'
            color='primary'
            onClick={() => openInNewTab(`/manager/products/${params.row.id}`)}
          >
            <OpenInNewIcon fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]
  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      <MiPaginatedDataGrid
        title={t('labels.products')}
        actions={
          <Button
            color={'primary'}
            size='small'
            variant='outlined'
            onClick={() => navigate('/manager/products/new')}
          >
            {t('buttons.add')}
          </Button>
        }
        onPaginationModelChange={onPaginationModelChange}
        pageState={pageState}
        columns={columns}
      />
    </Paper>
  )
}
