import { useEffect, useState } from 'react'
import { useBaseService } from './useBaseService'
import { ISimpleLookup } from '../models/SimpleLookup'

export interface IUseLookUp {
  loading: boolean
  lookup: ISimpleLookup[]
}

export function useLookup(path: string, lookupUrl: string): IUseLookUp {
  const [loading, setLoading] = useState(true)
  const [lookup, setLookup] = useState<ISimpleLookup[]>([])
  const service = useBaseService(path)

  useEffect(() => {
    async function fetchLookup() {
      setLoading(true)

      const response = await service.getLookupView<ISimpleLookup>(lookupUrl)
      if (response) setLookup(response as any)
      setLoading(false)
    }
    if (service) fetchLookup()
  }, [service, lookupUrl])

  return {
    loading,
    lookup
  }
}
