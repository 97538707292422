export class DiscountCouponDto {
  id: string
  created: Date
  lastUpdated: Date
  code: string
  discountPercentage: number
  useLimit: number
  expiryDate: Date
  TimesUsed: number
  isEnabled: boolean
}
