import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Card, CardContent, List, ListItem, ListItemText, Paper, Stack } from '@mui/material'

import acs from '../../../../images/acs.png'
import viva from '../../../../images/viva.png'
import { useLookup } from '../../../common/hooks/useLookup'
import { useTranslation } from 'react-i18next'
import { MiSkeleton } from '../../../common/components/MiSkeleton'
import { useNavigate } from 'react-router-dom'

export const EshopSubFooter = () => {
  const { lookup, loading } = useLookup('lookup', 'productType')
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  return (
    <>
      <Paper
        component={'footer'}
        sx={{
          marginTop: '5rem',
          position: 'relative',
          bottom: 0,
          left: 0,
          width: '100%',
          paddingTop: '3em',
          paddingBottom: '3em',
          background: '#dfdfdf',
          borderTop: '#d7d7d7e6',
          boxShadow: 'none'
        }}
      >
        <Container maxWidth='lg'>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={6} textAlign={'center'}>
              <Typography variant='h5'>{t('labels.categories')}</Typography>
              {loading ? (
                <MiSkeleton lines={8} />
              ) : (
                <Stack direction={'row'} spacing={2} justifyContent={'space-evenly'}>
                  <List dense>
                    {lookup?.map((x, i) => (
                      <div key={i}>
                        {i % 2 === 0 && (
                          <ListItem
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`products?category=${x.id}`)}
                          >
                            <ListItemText
                              primaryTypographyProps={{ fontSize: '22px', lineHeight: 1 }}
                              primary={i18n.language === 'en' ? x.valueEn : x.valueGr}
                            />
                          </ListItem>
                        )}
                      </div>
                    ))}
                  </List>
                  <List dense>
                    {lookup?.map((x, i) => (
                      <div key={i}>
                        {i % 2 !== 0 && (
                          <ListItem
                            style={{ cursor: 'pointer' }}
                            onClick={() => navigate(`products?category=${x.id}`)}
                          >
                            <ListItemText
                              primaryTypographyProps={{ fontSize: '22px', lineHeight: 1 }}
                              primary={i18n.language === 'en' ? x.valueEn : x.valueGr}
                            />
                          </ListItem>
                        )}
                      </div>
                    ))}
                  </List>
                </Stack>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                height={'100%'}
                alignItems={'center'}
                justifyContent={'space-around'}
                spacing={2}
              >
                <img width={'60%'} src={acs} alt='noimage' />
                <img width={'60%'} src={viva} alt='noimage' />
              </Stack>
            </Grid>
            {/* <Grid item xs={12} sm={4} textAlign={'center'}></Grid> */}
          </Grid>
        </Container>
      </Paper>
      <Card>
        <CardContent>
          <Typography variant='h6' textAlign={'center'}>
            {t('home.madooSaying')}
          </Typography>
        </CardContent>
      </Card>
    </>
  )
}
