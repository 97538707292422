import { TableRow, TableCell, IconButton, Collapse } from '@mui/material'
import React, { useState } from 'react'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { IColumnProps } from './MiTable'

export interface IMiTableRowProps {
  columns: IColumnProps[]
  row: any
  index: number
  options: any
}

export const MiTableRow = ({ index, row, columns, options }: IMiTableRowProps) => {
  const [open, setOpen] = useState(false)
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ padding: 10 }} width={50}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {columns.map((col: IColumnProps, i: number) => (
          <TableCell key={i} style={{ padding: 10 }} align={col.align} component='td' scope='row'>
            {col.renderCell ? col.renderCell(row, index) : row[col.field]}
          </TableCell>
        ))}
      </TableRow>
      {options.expandableRow && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: open ? 10 : 0, paddingTop: open ? 10 : 0 }}
            colSpan={columns.length + 1}
          >
            <Collapse in={open} timeout='auto' unmountOnExit>
              {options.renderExpandableRow(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  )
}
