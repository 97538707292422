import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { User } from '../../../../common/models/User'
import { useUser } from '../useUser'
import { useEffect } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import { UserDetails } from './UserDetails'
import { Button, Paper } from '@mui/material'
import { PaperHeader } from '../../../components/PaperHeader'
import { Loader } from '../../../../common/components/Loader'

export const UserContainer = () => {
  const { id } = useParams<{ id: string }>()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { create, loader, update, user } = useUser(id)

  const submit = async (user: User) => {
    user.id ? await update(user) : await create(user)
  }
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<User>({
    defaultValues: { ...user } as User,
    reValidateMode: 'onChange'
  })

  useEffect(() => {
    reset({ ...user })
  }, [reset, user])

  return (
    <Paper elevation={1}>
      <Grid container direction={'row'} padding={'1em'} spacing={2}>
        <Grid xs={12}>
          <PaperHeader
            label={user?.id ? t('labels.editRecord') : t('labels.newRecord')}
            marginBottom={0}
            rightChildSpacing={1}
            rightChild={
              <>
                <Button
                  type='button'
                  color='info'
                  variant='contained'
                  onClick={() => navigate('/manager/users')}
                >
                  {t('buttons.cancel')}
                </Button>
                <Button
                  type='button'
                  color='info'
                  variant='contained'
                  onClick={() => navigate('/manager/users')}
                >
                  {t('buttons.resetPassword')}
                </Button>
                <Button
                  type='button'
                  color='primary'
                  variant='contained'
                  onClick={handleSubmit(submit)}
                >
                  {t('buttons.update')}
                </Button>
              </>
            }
          />
        </Grid>
        <Grid xs={12}>
          {loader ? <Loader type='circles' /> : <UserDetails control={control} errors={errors} />}
        </Grid>
      </Grid>
    </Paper>
  )
}
