import { Button, Stack } from '@mui/material'
import { AddRemoveButton } from '../../../common/components/AddRemoveButton'
import { t } from 'i18next'

interface IEProductCartHandlerProps {
  quantity: number
  maxQuantity: number
  changeQuantity: any
  addToCart: any
}
export const EProductCartHandler = ({
  quantity,
  maxQuantity,
  changeQuantity,
  addToCart
}: IEProductCartHandlerProps) => {
  return (
    <Stack direction={'row'} spacing={2} marginTop={2}>
      <AddRemoveButton
        quantity={quantity}
        maxQuantity={maxQuantity}
        setNewQuantity={changeQuantity}
      />
      <Button type='button' variant='contained' onClick={addToCart}>
        {t('buttons.addtoCart')}
      </Button>
    </Stack>
  )
}
