import { useEffect, useState } from 'react'
import { useBaseService } from './useBaseService'
import { Color } from '../models/Color'

export interface IUseColorsProps {
  colorLoader: boolean
  updateColors: any
  colors: Color[]
}

export function useColors(): IUseColorsProps {
  const [colorLoader, setLoading] = useState(true)
  const [colors, setColors] = useState<Color[]>([])
  const service = useBaseService('lookup')

  const updateColors = (color: Color) => {
    const index = colors.findIndex((x) => x.id === color.id)
    if (index > -1) {
      colors[index].colorNameEn = color.colorNameEn
      colors[index].colorNameGr = color.colorNameGr
      colors[index].colorValue = color.colorValue
      colors[index].lastUpdated = color.lastUpdated
      setColors(colors)
    } else {
      setColors([...colors, color])
    }
  }

  useEffect(() => {
    async function fetchLookup() {
      setLoading(true)

      const response = await service.getLookupView<Color[]>('colors')
      if (response) setColors(response)
      setLoading(false)
    }
    if (service) fetchLookup()
  }, [service])

  return {
    colorLoader,
    updateColors,
    colors
  }
}
