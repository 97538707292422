import { useCallback, useEffect, useState } from 'react'
import { useValidator } from '../../../../utils/useValidator'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { AdminOrderDetailed, DeliveryMethod, OrderStatus } from '../../../common/models/Order'
import { enqueueSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'

export interface IUseAdminOrderProps {
  orderLoader: boolean
  order: AdminOrderDetailed | undefined
  changeOrderStatus: any
}

export interface IAdminOrderUpdate {
  id: string
  email: string
  firstName: string
  lastName: string
  mobileNumber: string
  address: string
  city: string
  postalCode: string
  comments: string
  orderStatus: OrderStatus
  deliveryMethod: DeliveryMethod
}

export function useAdminOrder(id: string): IUseAdminOrderProps {
  const [orderLoader, setLoading] = useState(false)
  const { t } = useTranslation()
  const [order, setOrder] = useState<AdminOrderDetailed | undefined>(undefined)
  const { isGuid } = useValidator()
  const service = useBaseService('adminorder')

  const changeOrderStatus = useCallback(
    async (order: IAdminOrderUpdate) => {
      setLoading(true)
      const response = await service.post<any>(order, 'api/adminorder/updateOrder')
      if (response) {
        setOrder((prev: AdminOrderDetailed | undefined) => {
          return prev ? { ...prev, orderStatus: order.orderStatus } : undefined
        })
        enqueueSnackbar(t('messages.successSave'), {
          variant: 'success'
        })
      }
      setLoading(false)
    },
    [service, t]
  )

  useEffect(() => {
    if (!isGuid(id as string)) {
      return
    }
    const getById = async (id: string) => {
      setLoading(true)
      const response = await service.getById<AdminOrderDetailed>(id, `api/adminorder/getOrder/${id}`)
      if (response) setOrder(response)
      setLoading(false)
    }
    getById(id)
  }, [id, isGuid, service, setLoading])

  return {
    orderLoader,
    order,
    changeOrderStatus
  }
}
