import { BorderColor } from '@mui/icons-material'
import { ButtonGroup, Chip, IconButton, Paper } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid'
import { t } from 'i18next'
import { useState } from 'react'
import { ColorModal } from './ColorModal'
import { ColorTable } from './ColorTable'
import { Color } from '../../../common/models/Color'
import { useColors } from '../../../common/hooks/useColors'
import moment from 'moment'

export const ColorContainer = () => {
  const [selectedEntity, setSelectedEntity] = useState<Color | null>(null)
  const { colors, colorLoader, updateColors } = useColors()

  const onConfirm = (result: Color) => {
    setSelectedEntity(null)
    updateColors(result)
  }

  const columns: GridColDef[] = [
    {
      headerName: '#',
      field: 'id',
      width: 20,
      sortable: false,
      valueGetter: (params: GridRenderCellParams) =>
        params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
      headerName: t('labels.lastUpdate') as string,
      field: 'lastUpdated',
      flex: 1,
      renderCell: (params: GridRenderCellParams) =>
        moment(params.row?.lastUpdated).format('DD/MM/YYYY , HH:mm A')
    },
    {
      headerName: t('labels.productColorNameEn') as string,
      field: 'colorNameEn',
      flex: 1
    },
    {
      headerName: t('labels.productColorNameGr') as string,
      field: 'colorNameGr',
      flex: 1
    },
    {
      headerName: t('labels.color') as string,
      field: 'colorValue',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        <Chip style={{ background: params.row.colorValue, width: '60px' }} variant='outlined' />
      )
    },
    {
      headerName: t('labels.actions') as string,
      field: '',
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      width: 120,
      renderCell: (params: GridRenderCellParams) => (
        <ButtonGroup variant='outlined' size='small' aria-label='outlined button group'>
          <IconButton color='default' onClick={() => setSelectedEntity(params.row)}>
            <BorderColor fontSize={'small'} />
          </IconButton>
        </ButtonGroup>
      )
    }
  ]
  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      {selectedEntity && (
        <ColorModal
          entity={selectedEntity as Color}
          onConfirm={onConfirm}
          onCancel={() => setSelectedEntity(null)}
        />
      )}
      <ColorTable
        loader={colorLoader}
        columns={columns}
        data={colors}
        setSelectedEntity={setSelectedEntity}
      />
    </Paper>
  )
}
