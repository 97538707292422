import { Button } from '@mui/material'
import { MiDatagrid } from '../../../common/components/MiDatagrid'
import { useTranslation } from 'react-i18next'
import { GridColDef } from '@mui/x-data-grid'
import { DiscountCouponDto } from '../../../common/models/DiscountCoupon'

export interface ICouponTableProps {
  columns: GridColDef[]
  setSelectedEntity: any
  loader: boolean
  data: DiscountCouponDto[]
}

export const CouponTable = ({ data, loader, columns, setSelectedEntity }: ICouponTableProps) => {
  const { t } = useTranslation()

  return (
    <MiDatagrid
      title={t('labels.coupons')}
      actions={
        <Button
          color={'primary'}
          size='small'
          variant='outlined'
          onClick={() => setSelectedEntity(new DiscountCouponDto())}
        >
          {t('buttons.add')}
        </Button>
      }
      data={data}
      columns={columns}
      loading={loader}
    />
  )
}
