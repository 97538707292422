import { BaseEntity } from './BaseEntity'
import { ProductSize } from '../../common/models/Sizes'
import { Color } from '../../common/models/Color'

export class Product extends BaseEntity {
  titleGr: string
  titleEn: string
  sku: string
  descriptionEn: string
  descriptionGr: string
  specificationsEn: string
  specificationsGr: string
  outOfStockMessageEn: string
  outOfStockMessageGr: string
  productTypeId: string
  visibleToEshop: boolean
  colors: ProductColor[] = []
}

export class ProductTableView extends BaseEntity {
  titleGr: string
  titleEn: string
  specificationsEn: string
  specificationsGr: string
  productTypeId: string
  productTypeNameEn: string
  productTypeNameGr: string
  visibleToEshop: boolean
  sku: string
}

export class ProductType extends BaseEntity {
  title: string
}

export class ProductColor {
  id: string
  colorId: string
  color: Color
  price: number
  discount: number = 0
  sizes: ProductSize[]
  productImages: ProductImage[]
}

export class ProductImage {
  id: string
  url: string
  isMain: boolean
  isEnabled: boolean
  fileName: string
}
