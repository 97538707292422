// useCounter.js
import { useState, useEffect } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'

interface IMetric {
  counter: number
}

export function useCounterItem(type: string) {
  const [loading, setLoading] = useState(false)
  const [metric, setMetric] = useState<IMetric>(null as any as IMetric)
  const service = useBaseService('AdminDashboard')

  useEffect(() => {
    const get = async (type: string) => {
      setLoading(true)
      const response = await service.getById<IMetric>('', `AdminDashboard/metric/${type}`)
      if (response) setMetric(response)
      setLoading(false)
    }
    get(type)
  }, [service, type])

  return { metric, loading }
}
