import { Button, Stack, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

export default function NotFound() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <Grid2 container spacing={2} marginBottom={'10rem'}>
      <Grid2 xs={12} sm={10} md={10} lg={7}>
        <Typography variant='h4' fontWeight={600} color={'primary'}>
          {t('messages.notFoundPage1')}
        </Typography>
        <Typography variant='h5' color={'primary'}>
          {t('messages.notFoundPage2')}
        </Typography>
      </Grid2>
      <Grid2 xs={12} sm={10} md={10} lg={7}>
        <Button onClick={() => navigate('/')} variant='contained'>
          {t('buttons.backToHome')}
        </Button>
      </Grid2>
      <Grid2 xs={12} sm={10} md={10} lg={7}>
        <Button onClick={() => navigate('/products')} variant='contained'>
          {t('buttons.backToProducts')}
        </Button>
      </Grid2>
    </Grid2>
  )
}
