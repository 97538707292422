import { Stack, Typography } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { makeStyles } from 'tss-react/mui'
import { Loader } from './Loader'
import { EmptyTable } from './EmptyTable'

const useStyles = makeStyles()((theme) => {
  return {
    root: {
      border: 'none',
      '& .MuiDataGrid-overlayWrapper': { height: '200px' }
    }
  }
})

export interface IMiDatagridProps {
  data: any[]
  columns: GridColDef[]
  loading: boolean
  title: string
  actions?: any
}

export const MiDatagrid = ({ data, columns, loading, title, actions }: IMiDatagridProps) => {
  const { classes } = useStyles()
  return (
    <Stack>
      <Stack
        padding={1}
        direction='row'
        spacing={2}
        alignItems={'center'}
        alignContent={'space-between'}
      >
        <Stack flex={1}>
          <Typography variant='h5' component='div'>
            {title}
          </Typography>
        </Stack>
        <Stack>{actions}</Stack>
      </Stack>

      <DataGrid
        className={classes.root}
        rows={loading ? [] : data ?? []}
        loading={loading}
        slots={{
          loadingOverlay: () => <Loader type='circles' noText />,
          noRowsOverlay: () => <EmptyTable />
        }}
        getRowId={(row) => row.id}
        columns={columns}
        disableRowSelectionOnClick
        pageSizeOptions={[10, 30, 100]}
        disableColumnMenu
      />
    </Stack>
  )
}
