import { HomeProducts } from './HomeProducts'
import { HomeProposals } from './HomeProposals'
import { HomeCategories } from './HomeCategories'
import { AppBar, Toolbar, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const Home = () => {
  const { t } = useTranslation()
  return (
    <div>
      <HomeProducts />
      <AppBar position='static' style={{ margin: '1em 0' }}>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            {t('home.ourProducts')}
          </Typography>
        </Toolbar>
      </AppBar>
      <HomeProposals />
      <AppBar position='static' style={{ margin: '1em 0' }}>
        <Toolbar>
          <Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
            {t('home.ourCategories')}
          </Typography>
        </Toolbar>
      </AppBar>
      <HomeCategories />
    </div>
  )
}
