import { useCallback, useEffect, useState } from 'react'
import { useBaseService } from '../../../common/hooks/useBaseService'
import { useNavigate } from 'react-router-dom'
import { useToken } from '../../services/useToken'

export interface IManagerLoginProps {
  login: any
  loading: boolean
}

export interface ILoginModel {
  email: string
  password: string
  rememberMe: boolean
}

export function useManagerLogin(): IManagerLoginProps {
  const [loading, setLoading] = useState(false)
  const tokenService = useToken()
  const service = useBaseService('auth')
  const navigate = useNavigate()

  const login = useCallback(
    async (login: ILoginModel) => {
      setLoading(true)
      const response = await service.create<any>(login, '/login')
      setLoading(false)
      if (tokenService.saveToken(response?.token, login.rememberMe)) {
        navigate('/manager')
      }
    },
    [navigate, service, tokenService]
  )

  useEffect(() => {
    const checkForValidToken = () => {
      if (!tokenService.isTokenExpired()) {
        navigate('/manager')
      }
    }
    checkForValidToken()
  }, [navigate, tokenService])

  return {
    loading,
    login
  }
}
