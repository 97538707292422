import { TextareaAutosize } from '@mui/material'
import { Control, Controller } from 'react-hook-form'
import { Product } from '../../manager/models/Product'

export interface IRichEditorProps {
  control: Control<Product, any>
  name: keyof Product
  placeholder: string
  rows?: number
}

export const RichEditor = ({ control, name, placeholder, rows = 20 }: IRichEditorProps) => (
  <Controller
    control={control}
    defaultValue={''}
    name={name}
    render={({ field: { ref, value, ...field } }) => (
      <TextareaAutosize
        ref={ref}
        placeholder={placeholder}
        value={value as string}
        {...field}
        minRows={rows}
        maxRows={rows}
        style={{
          width: 'calc(100% - 10px)',
          resize: 'none',
          borderRadius: '4px',
          borderColor: '#c4c4c4',
          padding: '5px',
          fontSize: '14px',
          marginBottom: '10px'
        }}
      />
    )}
  />
)
