import { useEffect, useState } from 'react'
import { useBaseService } from './useBaseService'
import { Size } from '../models/Sizes'

export interface IUseSizesProps {
  sizeLoader: boolean
  updateSizes: any
  sizes: Size[]
}

export function useSizes(): IUseSizesProps {
  const [sizeLoader, setLoading] = useState(true)
  const [sizes, setSizes] = useState<Size[]>([])
  const service = useBaseService('lookup')

  const updateSizes = (size: Size) => {
    const index = sizes.findIndex((x) => x.id === size.id)
    if (index > -1) {
      sizes[index].sizeType = size.sizeType
      sizes[index].sizeValue = size.sizeValue
      sizes[index].unit = size.unit
      sizes[index].unitSystem = size.unitSystem
      sizes[index].lastUpdated = size.lastUpdated
      setSizes(sizes)
    } else {
      setSizes([...sizes, size])
    }
  }

  useEffect(() => {
    async function fetchLookup() {
      setLoading(true)

      const response = await service.getLookupView<Size[]>('sizes')
      if (response) setSizes(response)
      setLoading(false)
    }
    if (service) fetchLookup()
  }, [service])

  return {
    sizeLoader,
    updateSizes,
    sizes
  }
}
